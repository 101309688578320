// -----------------------------------------------------------------------------
// This file contains styles that are specific to the account
// -----------------------------------------------------------------------------

%account-link-background {
    background-color: $account-link-background-color;
    border: none;
    color: $indigo;
    font-weight: 500;
}

.account-navigation {
    &__wrapper {
        max-width: 910px;
        margin-top: 29px;

        .loader-container {
            min-height: unset;
        }
    }

    &__device-content {
        display: none;
        height: 70px;
        padding: 0 19px;
        border-radius: 9px;
        margin-bottom: 18px;
        width: 100%;
        @extend %account-link-background;

        select {
            -webkit-appearance: none;
            height: 100%;
            cursor: pointer;
            font-size: calc-rem(18);
            outline: none;
            flex-grow: 1;
            padding: 0 15px;
            @extend %account-link-background;
        }

        span {
            max-width: 25px;
            max-height: 25px;

            >img {
                max-width: 25px;
                max-height: 25px;
            }
        }
    }

    &__content {
        background: #ffffff;
        $border-radius: 20px;
        border-radius: $border-radius;
        border: 1px solid $gainsboro;

        &__link {
            text-decoration: none;

            .content {
                height: 70px;
                font-size: calc-rem(18);
                border-bottom: 1px solid $gainsboro;
                padding-left: 40px;
                display: flex;
                align-items: center;

                span {
                    &:first-child {
                        max-width: 25px;
                        max-height: 25px;

                        >img {
                            max-width: 25px;
                            max-height: 25px;
                        }
                    }

                    &:last-child {
                        color: $nav-link-color;
                        padding-left: 20px;
                    }
                }
            }

            &:first-child {
                .content {
                    border-radius: $border-radius $border-radius 0 0;
                }
            }

            &:last-child {
                border-bottom: none;

                .content {
                    border-radius: 0 0 $border-radius $border-radius;
                }
            }

            &.active {
                .content {
                    background-color: $account-link-background-color;
                }
            }

        }
    }

    @include media('<=medium') {
        &__device-content {
            display: flex;
            align-items: center;
            margin-top: 29px;
        }

        &__content {
            display: none;
        }
    }
}

.edit-password,
.account-overview,
.edit-profile {
    position: relative;

    &__edit {
        position: absolute;
        right: -15px;
        top: -15px;
        max-width: 39px;
        cursor: pointer;

        @include media('<=medium') {
            right: 0;
        }
    }

    &__row {
        display: flex;
        padding: 5px 0;

        @include media('<=medium') {
            flex-direction: column;
        }

        .cell {
            &:first-child {
                color: $medium-gray;
                flex-basis: 50%;
            }

            &:last-child {
                width: 100%;
            }
        }
    }
}

.edit-password,
.edit-profile {
    &__wrapper {

        input[type=password],
        input[type=text],
        input[type=email],
        input[type=tel],
        select {
            border-radius: 5px;
            border: 1px solid $gainsboro;
            background-color: $white;
            color: $black;
        }

        .PhoneInput {
            .PhoneInputInput {
                border-radius: 5px;
                border: 1px solid $gainsboro;

                &:focus {
                    box-shadow: 0 0 5px $brand-color;
                    border: 1px solid $brand-color;
                }
            }
        }
    }

    &__mobile-country {
        display: flex;

        #mobile {
            margin: 0;
        }

        #mobile_country+i {
            color: $black;
        }

    }

    .icon-info-circled:before {
        color: $melrose;
    }

    &__button-container {
        text-align: center;

        .cancel {
            margin-left: 20px;
            background: inherit;
            color: #979797;
        }

        @include media('<=medium') {
            button {
                width: 100%;

                &:disabled {
                    opacity: 0.5;
                }
            }

            .cancel {
                margin-left: 0;
            }
        }
    }

    @include media('<=medium') {
        &__mobile-country {
            display: block;

            .form__col2 {
                margin: 0;

                .checkout-form__mobile-country {
                    width: 100%;
                }
            }
        }
    }
}

.subscription__wrapper {
    color: #7b7b7b;

    a {
        text-decoration: none;
    }
}

.account-menu {
    .active {
        color: $secondary-text-color;
    }
}

.account-form {
    &__title {
        margin: 20px 0;
    }

    &__success {
        color: $secondary-text-color;
        text-align: center;
    }
}

.account-cancel-link {
    margin-left: 29px;
}

.account-billing {
    &__title {
        margin-bottom: 10px;
    }

    &__card-container {
        background-color: $background-alternative-colour;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
    }

    &__cancel {
        margin-top: 40px;
    }

}

.ni-warning {
    font-size: 12px;
    margin-top: 10px;
}