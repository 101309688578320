.order-history {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    padding: 21px 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 7.5px rgba(0,0,0,0.1);
    padding: 30px;
    font-size: 16px;
    line-height: 18px;

    &__item {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 3fr 6fr 1fr 1fr;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &-date {
            &--short {
                display: none;
                @include media("<=small") {
                    display: initial;
                }
            }

            &--long {
                display: none;
                @include media(">small") {
                    display: initial;
                }
            }
        }
    }

    &__view-link {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            margin-right: 6px;
        }
    }

    &__hide-on-small {
        @include media("<=small") {
            display: none;
        }
    }
}
