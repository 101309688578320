// -----------------------------------------------------------------------------
// This file contains styles that are specific to the futures page
// -----------------------------------------------------------------------------


%oval-shape {
    width: 135px;
    height: 135px;
    max-width: 135px;
    max-height: 135px;
}

%shadow-container {
    background-color: $white;
    box-shadow: 0 5px 34px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
}

%arrow {
    border: 2px solid $text-color;
    background-color: $text-color;
    position: relative;
    width: 100%;

    &:before {
        left: calc(100% - 6px);
        top: calc(50% - 12px);
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-style: solid;
        border-color: rgba(194, 225, 245, 0);
        border-left-color: $text-color;
        border-width: 12px;
    }
}

.icon-bolt--green {
    color: #459B64;
}

.icon-bolt--normal {
    color: #D5D5D5;
}
.icon-bolt--good {
    color: $new-good-color;
}
.icon-bolt--bad {
    color: $error-red-color;
}
.icon-award--gold {
    color: #FFD700;
}

.futures {
    &__hero-section {
        max-width: 910px;
        margin: 0 auto;
        padding: 0 $container-inside-padding $container-inside-padding;

        .result-hero__title {
          text-align: center;
        }

        .result-hero__title-information {
            max-width: 550px;
            background-color: white;
        }

        @include media ("<=medium") {
            padding: $container-inside-padding 0;

            .result-hero__title-information {
                padding: 0;
            }

            .result-hero__information-section {
                padding: 0;
            }
        }
    }

    &__container {
        padding: 0 0 $container-inside-padding;

        .section-title {
            font-size: calc-rem(22);
            text-align: left;
            color: $text-color;
            font-weight: normal;
        }

        .section-description {
            font-size: calc-rem(20);
            text-align: left;
            color: $text-color;
            margin: 10px 0 0 0;
        }

        @include media ("<=small") {
            padding: 0 0 $container-inside-padding;

            .section-title {
                text-align: center;
            }
        }
    }

    &__age-section {
        @extend %shadow-container;
        padding: 50px;
        margin: $container-inside-padding 0 0;

        .section-title {
            margin: 0;
        }

        @include media ("<=small") {
            padding: $container-inside-padding $mobile-container-padding;
        }
    }

    &__ovals-container {
        max-width: 600px;
        margin: $container-inside-padding auto;
        padding: $container-inside-padding 0 0;
    }

    &__oval-section {
        display: inline-block;
        width: 40%;
        vertical-align: middle;
        padding: 0 $mobile-container-padding;
        position: relative;

        &--arrow {
            top: -16px;
        }

        .label {
            font-size: calc-rem(18);
        }

        &:first-child, &:last-child {
            width: 25%;
            margin: 0;
        }

        @include media ("<=medium") {
            padding: 0;
            width: 35%;
            margin: 0 $mobile-container-padding;

            &:first-child, &:last-child {
                margin: 0;
            }
        }

        .arrow {
            @extend %arrow;
        }

        .arrow-text {
            font-size: calc-rem(24);
            margin: 0;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            line-height: 1;

            @include media ("<=medium") {
                font-size: calc-rem(18);
                font-weight: 500;
            }
        }

        .future-tooltip {
            position: absolute;
            top: -10px;
            right: -10px;
            color: #b5b5b5;
            font-size: calc-rem(25);

            @include media ("<=tinysmall") {
                font-size: calc-rem(15);
                top: 0;
            }
        }
    }

    &__oval {
        @extend %oval-shape;
        margin: $mobile-container-padding 0;
        display: flex;

        .circle {
            fill: transparent;
            stroke-width: 6px;

            &.dashed {
                stroke-dasharray: 7, 7;
            }
        }

        &.lost {
            .circle {
                stroke: #459B64;
            }
        }


        &.gained {
            .circle {
                stroke: #FFA800;
            }
        }

        &.maintained {
            .circle {
                stroke: #A3A3A3;
            }
        }

        .future-count {
            .content-container {
                display: table;
                width: 100%;
                height: 100%;

                div {
                    display: table-cell;
                    vertical-align: middle;

                    p {
                        font-size: calc-rem(45);
                        margin: 0;
                        line-height: 1;

                        &:last-child {
                            font-size: calc-rem(13);
                        }
                    }
                }
            }
        }

        @include media ("<=medium") {
            width: 20vw;
            height: 20vw;
            margin: $mobile-container-padding auto;
        }
    }

    &__intervenables-section {
        width: 100%;
        margin: $container-padding 0 0 0;

        .image-section {
            margin: $container-inside-padding 0 0;
            padding: 0 $container-inside-padding;

            img {
                max-width: 500px;
                width: 100%;
            }
        }
    }

    &__science-section {
        margin: $container-padding auto 0;
        max-width: 900px;

        .down-arrow {
            text-align: center;
            max-height: 60px;

            svg {
                max-height: inherit;
            }
        }

        .section-title {
            text-align: center;
        }

        &.first-section {
            margin: 50px auto 0;
        }
    }

    &__science-faqs {
        .faq__container {
            margin: $container-padding auto;
        }
    }

    &__science-image-section {
        max-width: 500px;
        margin: 0 auto;
        padding: 20px 0;

        .image-section {
            padding-bottom: $container-inside-padding;
        }

        img {
            max-width: 100%;
        }

        .information-section {
            font-size: calc-rem(18);
            color: #232323;
            letter-spacing: 0;
            text-align: center;
            line-height: 26px;
            padding: 0 $container-inside-padding $container-inside-padding;
        }

        &:last-child {
            padding: 50px 0 0;
        }

        @include media ("<=medium") {
            padding: 20px $mobile-container-padding;

            &:last-child {
                padding: 20px $mobile-container-padding 0;
            }
        }
    }

    &__intervenable-title {
        font-size: calc-rem(20);
        line-height: 25px;
        padding: $container-inside-padding 0 0 0;
        color: #FFA800;
        margin: 0;
        text-transform: uppercase;

        @include media ("<=medium") {
            max-width: 300px;
            margin: auto;
        }

        &.high_impact {
            color: #FFA800;
        }

        &.medium_impact {
            color: #A3A3A3;
        }

        &.low_impact {
            color: #459B64;
        }
    }
}

.intervenable-card {
    &__container {
        @extend %shadow-container;
        padding: $container-inside-padding 50px;
        margin: $container-inside-padding 0;
        position: relative;

        @include media ("<=medium") {
            padding: $container-inside-padding;
        }

        @include media ("<=small") {
            padding: $container-inside-padding $mobile-container-padding;
        }

        .rating {
            &.good {
                color: $new-good-color;
            }

            &.bad {
                color: $new-bad-color;
            }

            &.normal {
                color: $new-normal-color;
            }
        }

        .go-premium-button {
            position: absolute;
            top: -13px;
            left: 50%;
            transform: translate(-50%, 0);
            background: #FB914F;
            color: $white;
            border-radius: 40px;
            padding: 0 20px;
            text-transform: uppercase;
            font-size: calc-rem(14);
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
        }
    }

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $mobile-container-padding 0;
        cursor: pointer;

        .score-section {
            font-size: calc-rem(30);

            .rating {
                font-size: calc-rem(25);
            }

            .score-item {
                margin: 0 50px 0 0;
                display: inline-block;
                position: relative;
            }

            .intervenable-card-tooltip {
                position: absolute;
                top: -10px;
                font-size: calc-rem(20);
                color: #b5b5b5;

                @include media ("<=small") {
                    top: 0;
                }

                .icon-info-circled {
                    cursor: pointer;
                }
            }
        }

        .icon {
            font-size: calc-rem(30);
            position: absolute;
            top: 10%;
            right: -30px;
        }

        @include media ("<=small") {
            display: block;
            padding: $mobile-container-padding;

            .score-section {
                font-size: calc-rem(22);
                margin: 0;
                text-align: left;
            }

            .icon {
                top: 0;
                right: -10px;
            }
        }
    }

    &__impact-title {
        font-size: calc-rem(20);
        color: #FFA800;
        margin: 0 20px 0 0;

        &.high_impact {
            color: #FFA800;
        }

        &.medium_impact {
            color: #A3A3A3;
        }

        &.low_impact {
            color: #459B64;
        }

        @include media ("<=small") {
            margin: 20px 0 0;
        }
    }

    &__title {
        font-size: calc-rem(20);
        margin: 0;
        line-height: 25px;
        text-align: left;
        font-weight: 500;

        .good {
            color: $new-good-color;
        }

        .bad {
            color: $new-bad-color;
        }

        .normal {
            color: $new-normal-color;
        }

        @include media ("<=small") {
            span {
                display: inline-block;
                width: 100%;

                &.hyphen {
                    display: none;
                }
            }
        }
    }

    &__recommendation-section {
        padding: $container-inside-padding $mobile-container-padding;
        color: #232323;
        background-color: #F8F8F8;
        border-radius: 10px;
        margin: $container-inside-padding 0;

        .intervenable-image-section {
            text-align: center;
            display: inline-block;
            padding: 0 $container-inside-padding 0 0;
            max-width: 20%;
            vertical-align: top;

            @include media ("<=small") {
                width: 100%;
                max-width: unset;
            }

            .image-container {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin: 0 auto;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .coach-name {
                font-size: calc-rem(18);
                letter-spacing: 0;
                margin: 0;
            }
        }

        .coach-text {
            font-size: calc-rem(20);
            letter-spacing: 0;
            line-height: 26px;
            max-width: 650px;
            display: inline-block;
            width: 80%;
            vertical-align: top;

            @include media ("<=small") {
                width: 100%;
                max-width: unset;
                margin: $mobile-container-padding 0 0;
            }
        }
    }

    &__description-section {
        padding: 0;
        width: 100%;
        max-height: 0;
        transition: max-height 0.3s ease-out;
        overflow: hidden;

        &.active {
            max-height: 1600px;
            transition: max-height 0.5s ease-in;
        }

        &.button-container {
            text-align: right;

            .read-more-link {
                background-color: #F8F8F8;
                margin: $container-inside-padding 5px $container-inside-padding 0;
                box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
                display: inline-block;
                border-radius: 5px;

                .link-image-container {
                    padding: $mobile-container-padding $mobile-container-padding 6px;
                    background-color: $white;
                    display: inline-block;
                    vertical-align: middle;

                    img {
                        width: 100%;
                        max-width: 46px;
                        max-height: 38px;
                    }
                }

                .link-title {
                    background-color: #F8F8F8;
                    font-size: calc-rem(20);
                    margin: 0;
                    padding: 0 $mobile-container-padding;
                    display: inline-block;
                    color: $text-color;
                }
            }
        }

        .reset-button-container {
            text-align: right;
            padding: $mobile-container-padding 10px;

            .reset-button {
                font-size: calc-rem(18);
                color: $dodger-blue;
                cursor: pointer;
            }
        }
    }

    &__current-section {
        font-size: calc-rem(18);
        padding: $container-inside-padding 0;
        text-align: left;

        i {
            cursor: pointer;
        }

        .buttons-container {
            text-align: left;
        }

        @include media ("<=small") {
            padding: $container-inside-padding $mobile-container-padding;
        }
    }

    &__slider-section {
        padding: 40px 0 50px;
        width: calc(100% - 40px);
        margin: 0 auto;

        .rc-slider-dot {
            width: 2px;
            border: 1px solid $text-color;
            border-radius: 0;

            &:first-child, &:last-child {
                display: none;
            }
        }

        &.add-extra-mark {
            .rc-slider-dot {
                &:first-child, &:nth-child(2) {
                    display: none;
                }

                &:last-child {
                    display: inline;
                }
            }
        }

        .rc-slider-handle {
            background-color: $button-colour;
            //box-shadow: 0 8px 20px 0 rgba(0,0,0,0.25);
            border: none;
            width: 30px;
            height: 30px;
            margin-left: -15px;
            margin-top: -15px;

            &:focus {
                border: none;
                //box-shadow: 0 8px 20px 0 rgba(0,0,0,0.25);
            }
        }

        .rc-slider-step {
            background-color: #e9e9e9;
            height: 6px;
        }

        .rc-slider-mark-text {
            top: $mobile-container-padding;
        }

        @include media ("<=small") {
            padding: 40px $mobile-container-padding;
        }
    }

    &__saving-section {
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
        padding: $container-inside-padding 0;
        font-size: calc-rem(60);

        .rating {
            font-size: calc-rem(45);
        }

        p {
            margin: 0;
            line-height: 1.4;
            display: inline-block;
        }

        .saving-text {
            font-size: calc-rem(18);
        }
    }

    &__update-section {
        padding: $container-inside-padding;

        @include media ("<=small") {
            padding: $container-inside-padding $mobile-container-padding;
        }

        .update-title {
            font-size: calc-rem(30);
            line-height: calc-rem(37);
            text-align: center;
            margin: 0;
        }

        .input-field-container {
            width: 100%;
            margin: $container-inside-padding 0 0;

            label {
                display: inline-block;
                width: 100%;
                font-size: calc-rem(14);
            }

            .input-container {
                position: relative;
                width: 100%;

                span {
                    position: absolute;
                    font-size: calc-rem(16);
                    letter-spacing: 1.26px;
                    line-height: calc-rem(45);
                    right: 13px;
                }
            }

            .input-field {
                border: none;
                border-bottom: 2px solid $dodger-blue;
                width: 100%;
                padding: 0 95px 0 $mobile-container-padding;
                font-size: calc-rem(16);
                letter-spacing: 1.26px;
                line-height: calc-rem(45);
                color: $text-color;

                &:active, &:focus {
                    outline: none;
                }

                &:disabled {
                    border-bottom: 2px solid $text-color;
                }
            }
        }

        .button-container {
            width: 100%;
            margin: $container-inside-padding 0 0;

            .btn {
                width: 100%;
            }
        }
    }

    &__oval-section {
        display: inline-block;
        vertical-align: middle;
        padding: 0 $mobile-container-padding;
        width: 25%;

        .label {
            font-size: calc-rem(18);
            font-weight: 500;
        }

        @include media ("<=medium") {
            padding: 0;
        }
    }

    &__oval {
        width: 135px;
        height: 135px;
        max-width: 170px;
        max-height: 170px;
        margin: $mobile-container-padding auto;
        display: flex;

        .circle {
            fill: transparent;
            stroke-width: 6px;

            &.dashed {
                stroke-dasharray: 7, 7;
            }

            &.lost {
                stroke: #FFA800;
            }


            &.gained {
                stroke: #459B64;
            }

            &.maintained {
                stroke: #A3A3A3;
            }
        }

        .future-count {
            .content-container {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;

                div {
                    width: 40%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    p {
                        font-size: calc-rem(22);
                        margin: 0;
                        line-height: 1;
                        font-weight: 500;

                        &:last-child {
                            font-size: calc-rem(10);
                            font-weight: normal;
                        }
                    }

                    &:last-child {
                        left: 50%;
                    }
                }
            }
        }

        @include media("<=medium") {
            width: 20vw;
            height: 20vw;
        }
    }
}

.update-modal {
    border-radius: 6px;
    max-width: 450px;
}


.futures__science-process {
    max-width: 800px;
    margin: auto;

}

.process-card {
    &__wrapper {
        padding: $container-inside-padding;

        &:last-child {
            padding-bottom: 0;
        }

        @include media ("<=medium") {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &__container {
        width: 85%;
        margin: 0 auto 0 0;
        background: #F8F8F8;
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;

        &.right {
            margin: 0 0 0 auto;
        }

        .information-section {
            font-size: calc-rem(18);
            padding: $container-inside-padding;
            display: flex;
            align-items: center;
            width: calc(100% - 138px);
        }

        @include media ("<=medium") {
            width: 100%;
            display: block;

            .information-section {
                width: 100%;
            }
        }
    }

    &__children-section {
        background-color: $white;
        border: 5px solid #F8F8F8;
        display: flex;
        align-items: center;
        height: 138px;
        min-width: 138px;
        justify-content: center;

        img {
            max-width: 80%;
            max-height: 80%;
        }

        .bolt-text {
            font-size: calc-rem(50);

            i {
                font-size: calc-rem(35);
                color: #459B64;
            }
        }
    }

    &__number {
        position: absolute;
        left: -19px;
        top: -19px;
        background-color: $dodger-blue;
        border: 3px solid #FCFCFC;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        color: $white;
        font-size: calc-rem(18);
        line-height: 32px;
        text-align: center;

        &.right {
            left: calc(100% - 19px);
        }
    }
}

.slider-tooltip.rc-slider-tooltip {
    .rc-slider-tooltip-inner {
        font-size: calc-rem(18);
        background-color: $white;
        height: auto;
        color: $text-color;
        letter-spacing: 0.29px;
        text-align: center;
        padding: 10px 20px;
        box-shadow: 0 8px 15px 0 rgba(203,209,223,0.50);
    }

    .rc-slider-tooltip-arrow {
        display: none;
    }

    &.active {
        .rc-slider-tooltip-inner {
            font-size: calc-rem(20);
        }
    }
}
