.page-tour {
    &__container {
        padding: 22px $container-inside-padding;
    }
    &__description-container {
        width: 100%;
        font-size: calc-rem(16);

        &.last-step{
            padding: 20px 20px 0;
            font-size: calc-rem(18);
            text-align: center;
        }

        .introduction {
            text-align: center;
            color: $dodger-blue;
            font-size: calc-rem(28);
            margin: 0 0 $container-inside-padding;
        }
    }

    &__footer {
        padding: $mobile-container-padding;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid $border-color;
        color: $dim-grey;
        font-size: calc-rem(16);
        position: relative;
        min-height: 65px;

        &.center {
            justify-content: center;
        }

        .step-number {
            text-align: center;
            letter-spacing: 1.2px;
            width: 100%;
            position: absolute;
            left: 0;
        }
    }


    &__description-image {
        width: 100%;
        margin-top: 20px;
    }

    &__header {
        width: 100%;
        padding: 0 0 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $dim-grey;

        .image-name-container {
            display: flex;
            align-items: center;

            .icon-container {
                height: 40px;
                width: 40px;
                min-width: 40px;
                min-height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;

                .icon {
                    height: 40px;
                    width: 40px;
                }
            }

            span {
                font-size: calc-rem(16);
                font-weight: 300;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    .last-step-button-container{
        display: flex;
        justify-content: center;
        padding: 20px;
    }
}

.mask{
    color: #656565;
    opacity: 0.5;
}

.tour-container{
    width: 550px;
    padding: 0 !important;
    margin: auto;
}
