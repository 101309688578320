.report-section{
    &__container{
         padding: 50px 29px;
         max-width: 910px;
         margin: auto;

        @include media ("<medium") {
            padding: 29px 0;
        }

         .page-title{
            font-size: calc-rem(36);
            color: $text-color;
            line-height: calc-rem(40);
            margin: 0;
            font-weight: 500;

            @include media ("<medium") {
                text-align:center;
            }
         }

         .nav{
             width: 100%;
             margin: 40px 0 0 0;

            @include media ("<medium") {
                margin: 20px 29px 0 29px;
                width: auto;
            }
            
            .nav-bar{
                list-style-type: none;
                margin: 0;
                padding: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
            
                @include media ("<medium") {
                    flex-direction: column;
                }
            }

            .nav-bar-options{
                display: inline-block;
                width: calc(100% / 4);
                text-align: center;
                .inactive{
                    display: inline-block;
                    text-decoration: none;
                    width: 100%;
                    font-size: calc-rem(20);
                    color: #6f6f6f;
                    padding: 5px 0;
                    border-bottom: 1px solid #6f6f6f;
                }

                .inactive:hover{
                    color: #4f4f4f;
                    font-weight: 400;
                    //border-bottom: 2px solid #4f4f4f;
                    border-bottom: 2px solid #5B7BFF;
                }
                
                .active{
                    display: inline-block;
                    text-decoration: none;
                    width: 100%;
                    padding: 5px 0;
                    font-size: calc-rem(20);
                    color: #3d3d3d;
                    border-bottom: 2px solid #5B7BFF;
                }

                @include media ("<medium") {
                    width: 100%;
                }    
            }
        }
    }
}