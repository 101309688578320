// -----------------------------------------------------------------------------
// This file contains styles that are specific to the dashboard
// -----------------------------------------------------------------------------

%dashboard-container {
  margin: 15px 0 0 0;
  padding: 15px;
  display: flex;
  align-items: flex-start;
}

%dashboard-card-title {
  font-size: calc-rem(18);
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
  color: $black;
}

%dashboard-card-description {
  font-size: calc-rem(16);
  color: $text-color;
  margin: 5px 0;
  line-height: 1.4;
}

%new-dashboard-card {
  background: $white;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: $text-color;

  img {
    transition: transform 0.3s ease;
  }

  &:hover:not(.no-hover) {
    background: #f9f8f8;

    img {
      transform: scale(1.1);
      transition: transform 0.3s ease;
    }
  }
}

.order-test-section {
  margin: 0;
  font-size: calc-rem(18);
  line-height: 1.2;
  text-align: right;
  cursor: pointer;
  font-weight: 500;
  color: $white;
  text-decoration: underline;

  @include media('<=small') {
    width: 100%;
    text-align: center;
    margin: $mobile-container-padding 0 0;
  }
}

.dashboard-section {
  min-height: $document-height;

  &__container {
    padding: 50px $container-inside-padding;
    //max-width: 1206px;
    max-width: 910px;
    margin: auto;

    @include media('<=medium') {
      padding: $container-padding/2 $mobile-container-padding;
    }
  }

  &__title {
    font-size: calc-rem(36);
    color: $text-color;
    line-height: calc-rem(40);
    margin: 0;
    margin-bottom: $container-inside-padding;
    font-weight: 500;

    @include media('<=small') {
      font-size: calc-rem(28);
      line-height: calc-rem(35);
    }
  }

  &__button-container {
    text-align: center;

    @include media('<=small') {
      margin: $container-inside-padding 0 0;
    }
  }

  &__results-ready-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px $container-inside-padding;
    background-color: $dodger-blue;
    border-radius: 10px;
    box-shadow: 0 5px 34px 0 rgba(0, 0, 0, 0.1);
    margin: $container-inside-padding auto;
    color: $white;
    text-decoration: none;

    &.change-background {
      background-color: #a577ff;
    }

    img {
      transition: transform 0.3s ease;
    }

    &:hover {
      img {
        transform: scale(1.1);
        transition: transform 0.3s ease;
      }
    }

    @include media('<=small') {
      padding: 22px;
      display: block;
      text-align: center;
    }
  }

  &__results {
    width: 100%;
  }

  &__title-section {
    display: flex;
    align-items: center;

    img {
      max-width: 62px;
      max-height: 62px;
      margin: 0 20px 0 0;
    }

    &.show-margin {
      @include media('<=small') {
        margin: 0 0 $mobile-container-padding;
      }
    }
  }

  &__title-information {
    font-size: calc-rem(20);
    color: $white;
    margin: 0 $mobile-container-padding 0 0;
    text-align: left;

    a {
      color: $white;
      text-decoration: underline;
    }

    @include media('<=small') {
      margin: 0;
    }
  }

  .layout-2col__main {
    @include media('<=medium') {
      order: 3;
    }
  }

  &__sub-title {
    font-size: calc-rem(28);
    font-weight: 500;
    color: $text-color;
    line-height: 1.4;
    padding: 0 0 $mobile-container-padding;
    margin: 0;
  }

  &__section-container {
    margin: $container-inside-padding 0;
  }

  &__suggestions-section {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 10px 10px 5px;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__single-suggestion {
    @extend %new-dashboard-card;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 180px;
    max-width: 140px;
    padding: $mobile-container-padding 20px 20px;
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }

    .suggestion-title {
      margin: 0 0 $mobile-container-padding;
      font-size: calc-rem(18);
      line-height: calc-rem(24);
      color: $text-color;
      font-weight: 500;
    }

    .suggestion-image {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background-color: rgba(91, 123, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 30px;
        max-height: 30px;
      }
    }

    @include media('<=small') {
      min-width: 140px;
      max-width: 140px;
    }
  }

  &__orders-link {
    text-decoration: none;
  }

  &__suggestion-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 7.5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;

    @include media('<=medium') {
      flex-direction: column;
    }

    .suggestion-image {
      width: 40%;
      filter: contrast(0.9);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      background-color: #ffffff;
      min-height: 230px;

      @include media('<=medium') {
        width: 100%;
        min-height: 200px;
      }
    }

    .suggestion-description {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 230px;
      padding: 15px 20px;

      @include media('<=medium') {
        width: 100%;
        min-height: 200px;
      }

      .title {
        font-size: calc-rem(20);
        line-height: calc-rem(28);
        color: #444444;
        font-weight: 500;
        margin-bottom: 13px;

        @include media('<=medium') {
          text-align: center;
        }
      }

      .content {
        font-size: calc-rem(16);
        line-height: calc-rem(28);
        color: #444444;

        @include media('<=medium') {
          text-align: center;
        }
      }

      .button-container {
        display: flex;
        justify-content: flex-start;

        @include media('<=medium') {
          justify-content: center;
        }
      }

      .btn {
        @include media('<=medium') {
          width: 100%;
        }
      }
    }

    &:hover {
      background-color: #f9f8f8;

      .btn {
        transform: scale(1.05);
      }
    }
  }

  &__results-section {
    background: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 10px 0;
    height: 100%;

    &.biomarker-list {
      margin: 60px 0;
    }

    .result-button {
      padding: 0 20px 20px;
      text-align: center;

      a {
        text-decoration: none;
      }

      .btn {
        width: 100%;
      }
    }
  }

  &__single-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    padding: 15px 20px;
    color: $text-color;
    text-decoration: none;

    &__link {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .column-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .row-container {
      display: flex;
      width: 100%;
    }

    .result-header {
      display: flex;
      width: 65%;
      justify-content: space-between;
    }

    .result-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      @include media('<=small') {
        display: block;

        &.blood-test {
          position: relative;
        }
      }
    }

    .no-underline {
      text-decoration: none;
    }

    .link {
      text-decoration: underline;
    }

    &.more-padding {
      padding: 31.5px 20px;
    }

    &.clickable {
      cursor: pointer;
    }

    &.no-link {
      cursor: default;
    }

    img {
      transition: transform 0.3s ease;
    }

    &:hover {
      background: #f9f8f8;

      img {
        transform: scale(1.1);
        transition: transform 0.3s ease;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .result-title {
      margin: 0 10px 0 0;
      font-size: calc-rem(18);
      line-height: calc-rem(24);
      color: $text-color;
      font-weight: 500;

      &.blood-test {
        min-width: 24%;
        margin: 0;

        @include media('<=large') {
          min-width: 20%;
        }
        @include media('<=small') {
          min-width: 24%;
        }
      }

      @include media('<=small') {
        margin: 0 0 8px;
      }
    }

    .result-text {
      font-size: calc-rem(18);
      line-height: calc-rem(24);
      margin: 0;
      color: #8d8d8d;
      text-transform: capitalize;
      font-weight: 500;

      &.good {
        color: $good-text-color;
      }

      &.normal {
        color: $avg-text-color;
      }

      &.bad {
        color: $bad-text-color;
      }

      //for covid
      &.positive {
        color: #F0975B;
      }

      &.negative {
        color: #459B64;
      }

      &.inconclusive {
        color: #8d8d8d;
      }

      &.blood-test {
        min-width: 13%;
        text-transform: none;

        @include media('<=small') {
          text-align: left;
        }
      }

      &.blood-test-result {
        min-width: 19%;
        text-transform: none;

        @include media('<=large') {
          min-width: 23%;
        }
        @include media('<=small') {
          min-width: 19%;
          text-align: left;
        }
      }

      &.blood-test-interpretation {
        min-width: 13%;
        text-transform: none;

        @include media('<=small') {
          text-align: left;
        }
      }

      &.bio-arrow {
        min-width: 5%;
        text-align: right;
        line-height: 1px;

        @include media('<=small') {
          position: absolute;
          top: 50%;
          right: 0;
        }
      }

      &.bio-bar {
        min-width: 39%;
        text-transform: none;

        @include media('<=small') {
          margin-top: 44px;
        }
      }
    }

    .recommendation-image {
      background-color: rgba(91, 123, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 62px;
      border-radius: 50%;

      img {
        max-width: 30px;
        max-height: 30px;
      }
    }

    .recommendation-header {
      width: calc(100% - 82px);
      margin: 0 0 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media('<=small') {
        display: block;
      }
    }

    .recommendation-description {
      font-size: calc-rem(14);
      line-height: calc-rem(18);
      color: $text-color;
      margin: 0;
    }

    p {
      margin: 0;
      @include media('<=small') {
        span {
          display: none;
        }
      }
    }
  }

  &__next-test {
    @extend %new-dashboard-card;
    padding: $mobile-container-padding 20px;
    margin: 10px 0;
    color: $text-color;

    .next-test-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include media('<=small') {
        flex-wrap: wrap;
      }
    }

    .gauge-title-container {
      display: flex;
      align-items: center;
      margin: 0 10px 0 0;

      @include media('<=small') {
        margin: 0;
      }
    }

    .recharts-radial-bar-background .recharts-radial-bar-background-sector {
      fill: #51beff;
      opacity: 0.2;
    }

    &.orange-background {
      color: $white;

      .recharts-radial-bar-background .recharts-radial-bar-background-sector {
        fill: $white;
      }
    }

    .test-section {
      width: calc(100% - 112px);
      margin: 0 0 0 20px;
    }

    .test-title {
      font-size: calc-rem(18);
      line-height: calc-rem(23);
      font-weight: 500;
      margin: 0 5px 0 0;
    }
    .test-subtitle {
      font-size: calc-rem(18);
      line-height: calc-rem(23);
      margin: 0 5px 0 0;
    }

    .gauge-chart-section {
      width: 92px;
      height: 92px;
      position: relative;
    }

    .gauge-placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        font-size: calc-rem(22);
        margin: 0;
        text-align: center;
        font-weight: 500;
        line-height: 1;

        &:last-child {
          font-size: calc-rem(14);
          font-weight: normal;
        }
      }
    }

    .button-container {
      @include media('<=small') {
        width: 100%;
        margin: $mobile-container-padding 0 0;

        .btn {
          width: 100%;
        }
      }
    }

    &--small {
      width: calc(50% - 15px);

      @include media('<=small') {
        width: 100%;
      }
    }
  }

  &__more-section,
  &__orders {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;

    @include media('<=small') {
      flex-direction: column;
      margin: 0;
    }

    &-link {
      flex-basis: 50%;
    }
  }

  &__bundle-parent {
    width: 100%;
    height: 38px;
    background: #404e80;
    border-radius: 2px;
    margin: 10px;
    font-size: calc-rem(20);
    color: #ffffff;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: #ffffff;
    }
  }

  &__single-more {
    @extend %new-dashboard-card;
    padding: 20px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    flex-basis: calc((100% / 2) - 20px);
    text-decoration: none;

    .more-header {
      display: flex;
      flex-direction: column;
      margin: 0 10px 0 0;
      width: calc(100% - 46px);
    }

    .more-title {
      font-size: calc-rem(20);
      line-height: calc-rem(28);
      font-weight: 500;
      margin: 0 0 10px;
    }

    .more-image {
      width: 36px;
      height: 36px;
      background-color: $dodger-blue;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 17px;
        max-height: 21px;
      }
    }

    .more-description {
      font-size: calc-rem(16);
      line-height: calc-rem(24);
      margin: 0;
    }

    @include media('<=small') {
      flex-basis: 100%;
      margin: 10px 0;

      &:first-child {
        margin: 0 0 10px;
      }
    }
  }
}

.dashboard-hero {
  height: 300px; //calc(100vh/2);

  /* vertical align text */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__text-container {
    max-width: 740px;
    text-align: center;

    @include media('<=medium') {
      padding: 0 $mobile-container-padding;
    }
  }

  &__title {
    font-size: calc-rem(65);
    line-height: calc-rem(65);
    //font-weight: 300;

    @include media('<=medium') {
      font-size: calc-rem(35);
      line-height: calc-rem(35);
    }
  }

  &__subtitle {
    font-size: calc-rem(20);
    margin-bottom: 0;
    font-weight: 300;
  }

  &__menu {
    position: absolute;
    bottom: 12px;

    li {
      padding: 0 20px;
    }

    li > a {
      text-transform: uppercase;
    }
  }
}

.price-box {
  padding: 8px;
  font-size: calc-rem(12);
  border-radius: 5px;
  text-transform: uppercase;
  color: #fff;
  display: inline;
  letter-spacing: 1px;

  &--free {
    background-color: $brand-pink-colour;
  }

  &--cash {
    background-color: $brand-color;
  }

  &--credits {
    background-color: $secondary-text-color;
  }

  &--off {
    background-color: $border-color;
    opacity: 0.9;
    cursor: pointer;
  }
}

.register-kit-form {
  input[type='text'] {
    width: 100%;
  }

  &__time {
    width: 70px;
  }
  &__time-divider {
    margin: 0 6px;
  }
  &__ampm-wrapper {
    display: inline-block;
    margin-left: 12px;
  }
  &__ampm {
    width: 80px;
  }

  .form__row {
    &.privacy-row {
      display: inline-block;
    }
  }

  .form__error {
    text-align: center;
    //margin: 20px 0;
  }
}

.timeline-icons {
  text-align: center;
  padding: $container-inside-padding;

  .card-title-container {
    margin-bottom: 29px;
  }

  .card-title {
    font-size: calc-rem(20);
    line-height: calc-rem(30);
    margin: 0 0 9px 0;
    text-align: left;
    font-weight: 500;
  }

  .card-date {
    font-size: calc-rem(16);
    line-height: calc-rem(20);
    margin: 0;
    text-align: left;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 $mobile-container-padding $mobile-container-padding 0;

    i {
      display: block;
      line-height: 29px;
    }

    &:last-child {
      margin: 0 0 $mobile-container-padding;
    }

    @media (max-width: 900px) {
      margin: 0 0 $mobile-container-padding;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__icon {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    font-size: calc-rem(20);
    background-color: #f8f8f8;
    color: #d5d5d5;
    margin: 0 7px 0 0;
    display: inline-block;

    .complete & {
      background-color: #75cd1b;
      color: $white;
    }

    @include media('<=small') {
      margin: 0 20px 0 0;
    }
  }

  &__loading-icon {
    width: 30px;
    height: 30px;
    margin: 0 20px 0 0;
  }

  &__title {
    font-size: calc-rem(16);
    font-weight: 500;
    text-align: center;
    line-height: 22px;
    letter-spacing: 0;
    margin: 0;
    display: inline-block;

    a {
      color: $text-color;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.header-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 22px;
  border: 4px solid $feeds-border-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  > p {
    margin: 5px 0;
  }

  img.badges {
    max-width: 170px;
  }
}

.feed {
  &__container {
    @extend %dashboard-container;
    background-color: $white-smoke;

    @include media('<=tinysmall') {
      display: block;
      position: relative;
    }

    &.dashboard-feed {
      background-color: #fff0df;
    }
  }

  &__title {
    @extend %dashboard-card-title;
  }

  &__link {
    font-size: calc-rem(16);
    > a {
      color: $link-colour;
      font-weight: 500;
    }
  }

  &__description {
    @extend %dashboard-card-description;
  }

  &__image {
    max-width: 100px;
    @include media('<=tinysmall') {
      margin: 0;
      max-width: 170px;
    }
  }

  &__information {
    width: 100%;
    display: inline-block;
    text-align: left;
    @include media('<=tinysmall') {
      width: calc(100% - 20px);
      text-align: center;
    }
  }

  &__with-image {
    margin-left: 20px;
    @include media('<=tinysmall') {
      width: 100%;
      margin-left: 10px;
    }
  }
}
.dashboard__main {
  margin: 0 0 0 40px;
  @include media('<=medium') {
    margin: 40px 0;
  }
}

.dashboard-item {
  @extend %dashboard-container;

  &__result-sections {
    @extend .you__epigenetic-sections;
    margin: $mobile-container-padding 0 0;
    
    .loader-container {
        width: 100%;
    }
  }

  &__icons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 900px) {
      display: block;
    }
  }

  &__result-item {
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0px 3px 7.5px rgba(0, 0, 0, 0.1);
    flex-basis: 0;
    flex-grow: 1;
    max-width: calc(100% / 2 - 20px);
    min-width: calc(100% / 2 - 20px);
    min-height: 200px;

    &:hover:not(.no-hover) {
      background-color: #f9f8f8;

      .you-image-section {
        img {
          transform: scale(1.1);
          @include transition(transform 0.3s ease);
        }

        i {
          transition: font-size 0.3s ease;
          font-size: calc-rem(30);
        }
      }
    }

    &.timeline-icons {
      margin: 10px 0;
      max-width: unset;
      text-align: left;
      padding: $container-inside-padding 22px;
      min-height: unset;
      flex-basis: 100%;
      background-color: $white;

      @include media('<=small') {
        padding: $container-inside-padding;
      }
    }

    .row-section {
      display: block;
      padding: $container-inside-padding;
      width: 100%;
      color: $text-color;
      height: 100%;
      position: relative;
    }

    .title {
      font-size: calc-rem(20);
      margin: 0 0 20px;
      font-weight: 500;
    }

    .description {
      font-size: calc-rem(18);
      letter-spacing: 1px;
      margin: 0;
      line-height: 1.4;
    }

    .you-image-section {
      max-height: 35px;
      max-width: 35px;
      display: inline-block;
      vertical-align: top;

      > img {
        width: 100%;
        margin: 0;
      }

      i {
        font-size: calc-rem(25);
        color: #ff5bf2;
      }
    }

    .title-section {
      display: inline-block;
      width: calc(100% - 50px);
      margin: 0 15px 0 0;

      &--fullwidth {
        width: 100%;
        margin: 0;
      }

      &.bottom-margin {
        margin-bottom: 40px;
      }

      @include media('<=small') {
        &.bottom-margin {
          margin-bottom: $container-inside-padding;
        }

        &--fullwidth {
          margin: 0;
        }
      }
    }

    .rating-text {
      font-size: calc-rem(20);
      line-height: 1.4;
      letter-spacing: 1.43px;
      text-decoration: underline;
      position: absolute;
      bottom: $container-inside-padding;

      @include media('<=small') {
        position: static;
      }
    }

    @include media('<=small') {
      flex-basis: 100%;
      max-width: unset;
      margin: 10px 0;
    }
  }

  &__image-container {
    max-width: 80px;
    min-width: 80px;
    text-align: center;

    > img {
      max-width: 80px;
    }

    i {
      font-size: calc-rem(55);
      color: #ff5bf2;
    }
  }

  .information-container & {
    border-bottom: 1px solid $gainsboro;
    padding: 5px 0;
  }

  &:last-child {
    border-bottom: none;
  }

  .item {
    &-image {
      max-width: 100px;
    }

    &-image-placeholder {
      background: darkgrey;
      border-radius: 50%;

      .what-you-can-do & {
        width: 100px;
        height: 100px;
      }

      .information-container & {
        width: 50px;
        height: 50px;
      }
    }

    &-with-image {
      margin-left: 20px;
    }

    &-title {
      @extend %dashboard-card-title;
    }

    &-description {
      @extend %dashboard-card-description;
    }
  }
}

.item-with-image {
  @include media('<=medium') {
    text-align: left;
  }
}

.col1_box {
  .information-icon {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;

    &:hover {
      background-color: $white-smoke;
      @include media('<=medium') {
        background-color: $white;
      }
      .item-image {
        transform: scale(1.1);
        @include transition(transform 0.3s ease);
      }
    }

    .item-image {
      transform: scale(1);
      @include transition(transform 0.3s ease);

      @include media('<=medium') {
        max-width: 15vw;
      }
    }

    .item-with-image {
      @include media('<=medium') {
        margin-left: 0;
        .item-description {
          display: none;
        }
      }
    }
  }

  @include media('<=medium') {
    display: flex;
    justify-content: space-evenly;

    .information-icon {
      flex-direction: column;
    }

    .dashboard-item {
      border-bottom: none;
      display: flex;
      align-items: center;
    }

    .item-image-container {
      min-height: 80px;
      max-height: 90px;
    }

    .information-icon {
      padding: 0 10px;
    }
  }
}

.story {
  &__title {
    @extend .feed__title;
    color: $indigo;
    margin-bottom: 5px;
    line-height: 22px;

    @include media('<=medium') {
      text-align: center;
    }
  }

  &__link {
    @extend .feed__link;
    > div {
      color: $link-colour;

      span {
        float: left;
        @include media('<=medium') {
          float: none;
        }
      }

      i {
        display: inline-block;
        margin: 1px 0 0 5px;
      }
    }
  }
}

.test-user-tests-container {
  background-color: $timeline-icon-complete-border-colour;

  .feed__title {
    color: $indigo;
  }

  .feed__description {
    color: $indigo;
  }

  .feed__link {
    > a {
      color: $dodger-blue;
    }
  }
}

.load-more-link-container {
  text-align: center;
  margin: 20px 0 0 0;

  .load-more-link {
    font-size: 16px;
    text-decoration: underline;
    color: $form-header-text-color;
    cursor: pointer;
  }
}

.start-questionnaires {
  text-align: center;
  > p {
    text-align: left;
  }
}

.welcome-title {
  font-size: calc-rem(24);
  margin: 0 0 $mobile-container-padding;
}
