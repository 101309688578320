.pricing__payment-period {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    
    // @include media('<=large') {
    // }
    
    @include media('<=medium') {
        justify-content: center;
        margin-bottom: 20px;
    }
}

.pricing__payment-period-text {
    margin-right: 7px;
}

.pricing__payment-period-toggles {
    align-items: center;
    display: flex;
    justify-content: center;
}

.pricing__payment-period-toggle {
    background-color: $payment;
    color: $white;
    padding: 8px;
    opacity: 0.7;

    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &:hover {
        cursor: pointer;
    }

    @include media('<=large') {
        font-size: calc-rem(14);
        padding: 4px;
    }
    
    @include media('<=medium') {
        font-size: calc-rem(16);
        padding: 8px 12px;
    }
}

.pricing__payment-period-toggle--active {
    background-color: $payment-active;
    opacity: 1;
}
