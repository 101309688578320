// -----------------------------------------------------------------------------
// This file contains styles that are specific to the you page version 2
// -----------------------------------------------------------------------------

%border-section {
    border-radius: 20px;
    background-color: white;
}

.you {
    &__container {
        width: 100%;
        max-width: 910px;
        margin: auto;
        background-color: white;
        position: relative;
        overflow: hidden;
        min-height: calc(100vh - 85px);
        padding: 40px $container-inside-padding 0;

        .you-section__take-a-look-container {
            padding: 0 0 $mobile-container-padding;
            display: flex;
            justify-content: space-between;
        }

        .you-section__take-a-look-text-container {
            padding: 0;
            display: inline-block;
            max-width: unset;
            width: 100%;

            h2 {
                font-size: calc-rem(36);
                color: $text-color;
                line-height: calc-rem(40);
                margin: 0;
                font-weight: 500;
            }
        }

        .hero-buttons-container {
            display: inline-block;
        }

        @include media ("<=small") {
            padding: $container-inside-padding $mobile-container-padding 0;
            .you-section__take-a-look-container {
                display: block;
            }

            .you-section__take-a-look-text-container {
                width: 100%;
                padding: 0;

                h2 {
                    font-size: calc-rem(28);
                    line-height: calc-rem(35);
                }
            }
        }

        .loader-container {
            position: relative;
            min-height: unset;
        }
    }

    &__hero-image {
        opacity: 0.6;
        background: url(../../img/you/you-hero-image.png) center center no-repeat;
        background-size: cover;
        min-height: 750px;
        margin: 0 auto;
        position: absolute;
        top: -10%;
        left: -15%;
        width: 150%;

        @include media ("<=medium") {
            top: -7%;
        }
    }

    &__section-container {
        display: flex;
        position: relative;
        margin: $container-inside-padding 0;

        &:first-child {
            margin: 0;
        }

        @include media ("<=medium") {
            flex-wrap: wrap;
        }

        &.last-section {
            margin: 0 0 50px;
        }
    }

    &__information-section {
        @extend %border-section;
        width: calc(50% - 10px);
        margin: 10px 10px 10px 0;
        box-shadow: 0 5px 34px 0 rgba(0, 0, 0, 0.1);

        &:last-child {
            margin: 10px 0 10px 10px;
        }

        @include media ("<=medium") {
            width: 100%;
            margin: 10px 0;

            &:last-child {
                margin: 10px 0;
            }

            &.hide-for-mobile {
                display: none;
            }
        }

        &.hide-border {
            border: none;
        }
    }

    &__row-container {
        padding: 0 $container-inside-padding;
        //border-bottom: 1px solid $gainsboro;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: $white-smoke;
            img {
                transform: scale(1.1);
                @include transition(transform .3s ease);
            }
        }

        &:last-child {
            border-bottom: none;
            &:hover {
                border-radius: 0 0 20px 20px;
            }
        }

        &.hide-border {
            border: none;
            padding: 0;
        }

        &.you__intervenable-card {
            border: 1px solid $gainsboro;
            border-radius: 20px;
            margin: 20px 0;

            &:last-child {
                border-bottom: 1px solid $gainsboro;
            }
        }

        @include media ("<=tinysmall") {
            padding: 0 $mobile-container-padding;
        }

        .row-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            color: $text-color;
        }

        .you-item-link {
            font-weight: 500;
            text-align: center;
            text-transform: uppercase;
            font-size: calc-rem(20);
            display: block;
            width: 100%;
            cursor: pointer;
        }

        &.information-title {
            font-weight: 500;
            font-size: calc-rem(28);
            height: 80px;
            cursor: default;
            color: #232323;
            padding: 0;

            &.pink {
                color: $intervenable-title-color;
            }

            &.blue {
                color: $dodger-blue;
            }

            &.skyblue {
                padding: 0 20px;
            }

            &.hide-mobile {
                @include media ("<=medium") {
                    display: none;
                }
            }

            &:hover {
                background-color: $white;
                border-radius: 20px 20px 0 0;
            }
        }

        &--link {
            height: 60px;
        }

        .you-image-section {
            display: flex;
            align-items: center;
        }

        img {
            max-width: 50px;
            min-width: 50px;
            max-height: 50px;
            margin-right: 15px;
        }

        .title {
            font-weight: 500;
            font-size: calc-rem(22);
            color: #232323;
        }

        .rating-text {
            border: 1px solid $gainsboro;
            padding: 8px 0;
            font-size: calc-rem(16);
            font-weight: 500;
            width: 100px;
            line-height: 1;
            text-align: center;

            &.good {
                color: $good-text-color;
            }

            &.bad {
                color: $bad-text-color;
            }

            &.normal {
                color: $avg-text-color;
            }
        }
    }

    &__health-opportunity {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 70%;
        padding: $container-inside-padding;
        text-align: center;

        @include media ("<=medium") {
            min-height: 350px;
        }

        p {
            margin: 0 0 40px 0;
            font-weight: 500;
            font-size: calc-rem(15);
        }
    }

    &__section-title {
        font-size: calc-rem(28);
        font-weight: 500;
        padding-bottom: 20px;
        margin: 0 47px 50px;
        border-bottom: 1px solid $gainsboro;
        text-align: left;
        color: $intervenable-title-color;

        &--second {
            margin-top: $container-padding;
            color: $skyblue-java-color;
        }

        @include media ("<=medium") {
            text-align:center;
            margin: 0 $mobile-container-padding 29px $mobile-container-padding;

            &--second {
                margin-top: $container-padding;
            }
        }
    }

    &__overlay {
        position: relative;

        &.v3-design {
            &.epigenetic-section {
                display: flex;
            }

            .row-section {
                height: unset;
            }
        }

        .overlay-text-container {
            display: none;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 90%;
            align-items: center;
            justify-content: center;
            top: 0;
            text-align: center;

            .overlay-text {
                max-width: 500px;
                margin: 0 auto;
                padding-top: 20px;

                a {
                    text-decoration: none;
                }

                i {
                    font-size: calc-rem(34);
                    color: #a0a0a0;
                }

                .overlay-title {
                    font-size: calc-rem(30);
                    //color: $overlay-title-colour;
                    color: $black;
                    margin: 10px 0;
                    line-height: 1;
                }

                .overlay-information {
                    font-size: calc-rem(18);
                }
            }
        }

        &.show {
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 90%;
                width: 100%;
                content: "";
                background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.86) 30%, rgba(255,255,255,1) 85%);
                background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.86) 30%,rgba(255,255,255,1) 85%);
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.86) 30%,rgba(255,255,255,1) 85%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
            }


            .overlay-text-container {
                display: flex;
            }

            &.change-disabled-state:after {
                background: $white;
                opacity: 0.7;
                border-radius: 10px;
            }
        }

        &.epigenetic-section {
            margin: 20px 10px;
            text-align: center;
            flex-basis: 0;
            flex-grow: 1;
            max-width: calc(100%/3 - 20px);
            min-width: calc(100%/3 - 20px);
            padding: 0;
            border-radius: 20px;

            &--wide {
                max-width: calc(100%/1.5 - 20px);
                min-width: calc(100%/1.5 - 20px);
            }

            &.centered-section {
                margin: 20px 10px 20px 0;
                max-width: calc(100%/2 - 10px);
                min-width: calc(100%/2 - 10px);

                &:nth-child(even) {
                    margin: 20px 0 20px 10px;
                }

                .row-section {
                    height: auto;
                }

                .you__epigenetic-indicator {
                    height: 100%;

                    a {
                        text-decoration: none;
                    }
                }

                .rating-text {
                    padding: 10px 0 0;
                    height: auto;
                    line-height: 1.4;
                    position: static;

                    @include media("<=small") {
                        justify-content: center;
                        font-size: calc-rem(15);
                    }
                }
            }

            &.compare-section {
                width: 100%;
                max-width: 100%;

                .compare-icons-container {
                    display: flex;
                    justify-content: space-around;
                    margin-top: 10px;

                    .icon-image {
                        width: 64px;
                        height: 64px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;

                        img {
                            max-width: 38px;
                            max-height: 38px;
                        }

                        &.smoking {
                            background-color: rgba(91,123,255,0.20);
                        }

                        &.diet {
                            background-color: rgba(117,205,27,0.20);
                        }

                        &.alcohol {
                            background-color: rgba(184,150,254,0.20);
                        }
                    }

                    .icon-name {
                        font-size: calc-rem(16);
                        color: $text-color;
                        margin: 10px 0 0;
                        text-align: center;
                        font-weight: 500;

                        @include media ("<=small") {
                            font-size: calc-rem(15);
                        }
                    }
                }
            }

            @include media ("<=small") {
                flex-basis: 100%;
                max-width: unset;
                margin: 10px 0;

                &.future-health {
                    width: 100%;
                }

                &.centered-section {
                    .row-section {
                        height: auto;
                        padding: $mobile-container-padding 22px;
                    }
                }

                &.compare-section {
                    width: 100%;
                    max-width: 100%;
                    margin: 20px 10px;
                    padding-right: $mobile-container-padding; //fix for going to edge of screen

                    .row-section {
                        padding: $mobile-container-padding 22px;
                    }
                }
            }

            .overlay-text-container {
                height: 100%;
                padding: 0 $mobile-container-padding;
            }

            &.show {
                &:after {
                    border-radius: 20px;
                    /*
                    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(65%, rgba(234, 234, 234, 0.86)), color-stop(85%, #eaeaea));
                    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(234, 234, 234, 0.86) 65%, #eaeaea 85%);
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(234, 234, 234, 0.86) 65%, #eaeaea 85%);
                    */
                    background: #eaeaea;
                    opacity: 0.3;
                }

                &.centered-section:after {
                    background: $white;
                    opacity: 0.7;
                    border-radius: 10px;
                    height: 100%;
                }
            }
        }
    }

    &__epigenetic-indicators {
        width: 100%;
        background-color: $white;

        .you__row-container {
            border-bottom: none;

            @include media ("<=medium") {
                padding: 0;
            }

            @include media ("<=small") {
                padding: 0;
                font-size: calc-rem(26);
            }
        }

        &.future-health {
            margin: $container-inside-padding 0 0;
        }
    }

    &__epigenetic-sections {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        &.add-margin {
            margin: 0 -10px;
        }

        @include media ("<=small") {
            margin: 0;

            &.add-margin {
                margin: 0;
            }
        }
    }

    &__epigenetic-indicator {
        border-radius: 20px;
        box-shadow: 0 5px 34px 0 rgba(0,0,0,0.10);
        width: 100%;

        &:hover:not(.no-hover) {
            background-color: #f9f8f8;
            img {
                transform: scale(1.1);
                @include transition(transform .3s ease);
            }
        }

        .row-section {
            display: block;
            padding: $container-inside-padding;
            width: 100%;
            min-height: 220px;
            position: relative;

            @include media ("<=small") {
                min-height: 150px;
                padding: $mobile-container-padding 22px;
            }

            &.compare-row {
                height: auto;
                text-decoration: none;
            }
        }

        .title {
            font-size: calc-rem(22);
            font-weight: 500;
            color: #232323;
            text-align: left;
            height: 30%;
        }

        .compare-description {
            font-size: calc-rem(18);
            color: $text-color;
            letter-spacing: 1px;
            line-height: 26px;
            text-align: left;
            margin: 0 0 40px;
        }

        .you-image-section {
            min-height: 50px;
            max-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
                max-width: 35px;
                max-height: 35px;
                margin: 0;
            }

            &.small-image {
                > img {
                    max-width: $container-inside-padding;
                    max-height: $container-inside-padding;
                    margin: 0;
                }
            }
        }

        .title-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 10px 0;

            .title {
                line-height: 1;
            }
        }

        .rating-text {
            font-size: calc-rem(20);
            font-weight: 500;
            line-height: 1;
            text-align: left;
            position: absolute;
            bottom: 20px;
            display: flex;
            align-items: flex-end;
            padding: 0 0 20px 0;
            color: #444444;

            &.good {
                color: $good-text-color;
            }

            &.bad {
                color: $bad-text-color;
            }

            &.normal {
                color: $avg-text-color;
            }

            &.blue {
                height: unset;
                color: $dodger-blue;
                padding: 0;

            }

            @include media ("<=small") {
                bottom: 10px;

                &.blue {
                    bottom: 20px;
                }
            }
        }

        .biological-age-circle {
            width: 105px;
            height: 105px;
            border: 6px solid #5B7BFF;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            span {
                font-size: calc-rem(13);
                color: $text-color;
                line-height: 1;

                &:first-child {
                    font-size: calc-rem(46);
                    font-weight: 500;
                }
            }
        }

        .gauge-chart-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .rating-text-container {
                margin: 0 0 0 10px;
            }

            @include media ("<=small") {
                flex-direction: column;

                .rating-text-container {
                    margin: 0;
                }
            }
        }
    }
}
