// -----------------------------------------------------------------------------
// This file contains all styles related to the chromosome graph component.
// -----------------------------------------------------------------------------

.chromosome-graph {
    &__container {
        width: 100%;
    }

    &__section-container {
        display: inline-block;
        width: calc(100%/24);

        @include media ("<=medium") {
            width: auto;
        }
    }

    &__section {
        margin: 0 3px;
        border-bottom: 1px solid #8493a8;
        display: inline-block;
        width: calc(100% - 6px);
        text-align: center;
        @include media ("<=medium") {
            width: auto;
        }
    }

    &__bar-container {
        display: inline-block;
        width: 50%;
        @include media ("<=medium") {
            width: auto;
        }
    }

    &__bar {
        background-color: #2a364e;
        display: inline-block;
        color: white;
        overflow: hidden;
        position: relative;
        border-radius: 3px;
        margin: 0 2px;
        width: calc(100% - 4px);
        max-width: 10px;
        min-width: 5px;

        @include media ("<=medium") {
            width: 5px;
        }
    }

    &__bar-content {
        width: 100%;
    }

    &__notch {
        width: 0;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        position: absolute;

        &.left {
            border-left: 2px solid #aab0bb;
            left: 0;
        }

        &.right {
            border-right: 2px solid #aab0bb;
            right: 0;
        }
    }

    &__pink-section {
        position: absolute;
        background-color: $brand-pink-colour;
        width: 100%;
    }

    &__number {
        font-size: 12px;
        text-align: center;
    }
}

// -----------------------------------------------------------------------------
// Styles are for horizontal chromosome graph
// -----------------------------------------------------------------------------

.chromosome-graph {
    &__horizontal-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__horizontal-section-container {
        @extend .chromosome-graph__section-container;
        display: block;
        text-align: left;
        width: 90%;
        height: 20px;
    }

    &__horizontal-section {
        @extend .chromosome-graph__section;
        border-bottom: none;
        border-left: 1px solid #8493a8;;
        margin: 3px 0;
        width: 90%;
        padding-left: 5px;
    }

    &__horizontal-bar-container {
        @extend .chromosome-graph__bar-container;
        display: block;
        width: 100%;
        float: left;
    }

    &__horizontal-bar {
        @extend .chromosome-graph__bar;
        height: 5px;
        margin: 1px 0;
        float: left;
        max-width: unset;
    }

    &__horizontal-notch {
        width: 0;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        position: absolute;

        &.left {
            border-top: 2px solid #3c4a67;
            top: 0;
        }

        &.right {
            border-bottom: 2px solid #3c4a67;
            bottom: 0;
        }
    }

    &__horizontal-pink-section {
        @extend .chromosome-graph__pink-section;
        height: 5px;
    }

    &__horizontal-number {
        @extend .chromosome-graph__number;
        display: inline-block;
        float: left;
        margin: 1px 10px 0 0;
        width: 20px;
        text-align: right;
    }
}
