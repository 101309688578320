
.form {
    &__required {
        color: $brand-pink-colour;
    }

    &__row {
        margin-bottom: 5px;
        position:relative;
    }

    &__row--no-error {
        margin-bottom: $container-inside-padding;
    }

    &__row--2col {
        display: flex;
        flex-direction: column;

        @media(min-width: 767px) {
            flex-direction: row;
        }
    }

    &__row--group {
        display: flex;
    }

    &__col {
        &:first-child {
            margin-right: 20px;
        }

        &--narrow {
            flex: 0 0 120px;
        }
    }

    &__col2 {
        flex: 50%;

        &:first-child {
            margin-right: 20px;
        }

        &--narrow {
            flex: 0 0 120px;
        }
    }

    &__col3 {
        flex: 33%;
        margin-right: 20px;

        &:nth-child(3) {
            margin-right: 0;
        }

        &--narrow {
            flex: 0 0 120px;
        }
    }

    &__col60 {
        align-items: center;
        display: flex;
        flex: 60%;
        margin-right: 20px;

        &:nth-child(2) {
            margin-right: 0;
        }

        div.input-label-container {
            margin-right: 20px;

            &:nth-child(3) {
                margin-right: 0;
            }
        }

        &--narrow {
            flex: 0 0 120px;
        }
    }

    &__col40 {
        align-items: center;
        display: flex;
        flex: 40%;

        div.input-label-container {
            margin-right: 20px;
            input {
                width: 70px;
            }

            &:nth-child(3) {
                margin-right: 0;
            }
        }

        &--narrow {
            flex: 0 0 120px;
        }
    }

    &__label {
        display: block;
        margin-bottom: 5px;
        font-weight: 300;
        position:relative;

        &--radio {
            margin-top: 30px;
        }

        &--checkbox {
            display: inline;
            margin-left: 10px;
        }

        &--terms {
            font-size: calc-rem(16);
        }
    }

    &__error {
        color: #B3261E;
        font-size: calc-rem(15);
        font-weight: 300;
        min-height: 24px; //add so form doesnt jump around
        text-align:end;

        @include media("<=medium") {
            text-align: start;
        }

        &__general {
            margin-top: 20px;
        }
    }

    .card-details-row {
        margin-bottom: 20px;
    }
    .form-space {
        padding-top: 20px;
    }
    .form-label-helper {
        color: #909090;
        font-size: 14px;
        line-height: 18px;
        height: 20px;
        padding-top: 3px;
        padding-left: 13px;
    }

    input[type=checkbox], input[type=radio] {
        font-size: 25px;
        vertical-align: middle;
        position: relative;
        bottom: 1px;
    }  
    input[type=radio] {
        margin-top: 0;
        bottom: 2px;
    }

    input[type=text], 
    input[type=date],
    input[type=datetime],
    input[type=number],
    input[type=search],
    input[type=time],
    input[type=url],
    input[type=email],
    input[type=password],
    input[type=tel],
    textarea, 
    select, 
    .StripeElement, .InputElement {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        //border:1px solid $border-grey-color;
        padding: 10px;
        margin:0px;
        -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out;
        outline: none;  
        font-family: $text-font-stack;
        font-size: calc-rem(16);
        color: $text-color;
        background-color: $background-alternative-colour;
        border-radius: 5px;
        border: 1px solid $border-color;

        &:disabled {
            background-color: #dddddd !important;
        }
    }
    input[type=text]:focus, 
    input[type=date]:focus,
    input[type=datetime]:focus,
    input[type=number]:focus,
    input[type=search]:focus,
    input[type=time]:focus,
    input[type=url]:focus,
    input[type=email]:focus,
    input[type=password]:focus,
    input[type=tel]:focus,
    textarea:focus, 
    select:focus,
    .StripeElement--focus, .InputElement--focus {
        -moz-box-shadow: 0 0 5px $brand-color;
        -webkit-box-shadow: 0 0 5px $brand-color;
        box-shadow: 0 0 5px $brand-color;
        border: 1px solid $brand-color;
    }


    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $brand-color;
        opacity: 0.3;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $brand-color;
        opacity: 0.3;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $brand-color;
        opacity: 0.3;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $brand-color;
        opacity: 0.3;
    }

    .StripeElement--webkit-autofill {
        background-color: #FFFFFF !important;
    }

    &__select-wrapper {
        position: relative;

        select {
            appearance: none;
            position: relative;
            //z-index: 1;
        }

        i {
            position: absolute;
            top: calc(50% - 16px);
            right: 15px;
            //z-index: 1;
            font-size: 20px;
            pointer-events: none;
        }
    }


    //https://danklammer.com/articles/simple-css-toggle-switch/
    &__toggle {
        -webkit-appearance: none;
        appearance: none;
        width: 42px;
        height: 22px;
        display: inline-block;
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        outline: none;
        border: none;
        cursor: pointer;
        //background-color: $border-grey-color;
        transition: background-color ease 0.3s;

        vertical-align: middle;
        position: relative;
        bottom: 1px;

        &:before {
            content: " ";
            display: block;
            position: absolute;
            z-index: 2;
            width: 18px;
            height: 18px;
            background: #fff;
            left: 2px;
            top: 2px;
            border-radius: 50%;
            font: 10px/28px Helvetica;
            box-shadow: 0 1px 2px rgba(0,0,0,0.2);
            transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
        }

        &:checked {
            background-color: $brand-color;
        }

        &:checked:before {
            left: 22px;
        }
    }

    &__help-icon {
        margin-left: 10px;
    }

    &__autosuggest-wrapper {
        max-width: 350px;
        margin: $mobile-container-padding;

        .autosuggest-container .react-autosuggest__container {
            margin: 0;

            input[type=text] {
                display: inline-block;
                border: 1px solid #D5D5D5;
                border-radius: 5px;
                background-color: $background-alternative-colour;
                font-size: calc-rem(16);
                color: $text-color;
                letter-spacing: 1.26px;
                padding: 10px 20px;
                line-height: 22px;
                width: 100%;

                &:focus {
                    border: 1px solid #D5D5D5;
                }
            }
        }

        .selected-tag {
            display: inline-block;
            background-color: $background-alternative-colour;
            border-radius: 5px;
            border: 1px solid #d5d5d5;
            font-size: 1rem;
            color: $text-color;
            letter-spacing: 1.26px;
            position: relative;
            overflow: hidden;

            span {
                display: inline-block;
                padding: 5px 10px 5px 15px;
            }

            i {
                position: static;
                background-color: #e3e3e3;
                padding: 6px 2px;
                vertical-align: middle;
                cursor: pointer;
                pointer-events: all;
            }
        }
    }

}


