// -----------------------------------------------------------------------------
// This file contains styles that are specific to the dashboard
// -----------------------------------------------------------------------------

%dashboard-container {
  margin: 15px 0 0 0;
  padding: 15px;
  display: flex;
  align-items: flex-start;
}

%dashboard-card-title {
  font-size: calc-rem(18);
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
  color: $black;
}

%dashboard-card-description {
  font-size: calc-rem(16);
  color: $text-color;
  margin: 5px 0;
  line-height: 1.4;
}

%new-dashboard-card {
  background: $white;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: $text-color;

  img {
    transition: transform 0.3s ease;
  }

  &:hover:not(.no-hover) {
    background: #f9f8f8;

    img {
      transform: scale(1.1);
      transition: transform 0.3s ease;
    }
  }
}

.test-dashboard-section {
  &.default-theme-background {
    background-color: $page-background;
  }

  min-height: $document-height;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  .loader-container {
    min-height: 68px;
    img {
      width: 80px;
      height: 60px;
      margin: 0;
    }
  }

  &__section_content {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    max-width: 1100px;
    width: 100%;

    > :nth-child(1) {
      order: 1;
    }

    @include media('>=xlarge') {
      align-items: flex-start;
      flex-direction: row;

      > :nth-child(1) {
        order: 0;
      }
    }

    &.standard-flow {
      flex-direction: column;
      max-width: 770px;

      > :nth-child(1) {
        order: inherit;
      }

      @include media('>=xlarge') {
        align-items: flex-start;
        flex-direction: column;

        > :nth-child(1) {
          order: 0;
        }
      }
    }

    &.centered {
      flex-direction: column;
      justify-content: center;
      padding: 0 $mobile-container-padding;

      > :nth-child(1) {
        order: 0;
      }

      .section-sub-title {
        border-bottom: none;
        font-size: calc-rem(18);
        line-height: calc-rem(22);
        font-weight: 400;
        color: $text-color;
        padding: 3px 0;

        @include media('>=medium') {
          width: 500px;
        }
      }

      img {
        align-self: flex-start;
        cursor: pointer;
        padding-bottom: $mobile-container-padding;
        height: 33px;
        width: 28px;
      }

      ul {
        list-style-image: url('../../img/check.svg');
        padding: 0;
        margin: 0;
        padding-left: 22px;
      }

      input[type='number'] {
        border: none;
        border-bottom: 1px solid $darker-grey;
        width: 80px !important;

        &:focus {
          border: none;
          border-bottom: 1px solid $darker-grey;
          outline: none;
        }

        @include media('>=medium') {
          width: 187px !important;
        }
      }

      .form__row--2col,
      .lab-results {
        align-self: center;
        margin-top: 20px;

        .input-label-container {
          display: flex;
          flex-direction: column;

          label {
            padding-top: 11px;
          }
        }
      }

      .button-link {
        cursor: pointer;
        font-size: calc-rem(14);
        line-height: calc-rem(20);
        padding: 10px 0;
        text-align: center;
      }

      .item-note {
        font-size: calc-rem(16);
        line-height: calc-rem(20);
        color: $darker-grey;
        padding-top: 10px;

        a {
          font-size: calc-rem(16);
          line-height: calc-rem(20);
        }
      }

      @include media('>=medium') {
        align-items: center;
        .back-button {
          display: none;
        }
      }
    }
  }

  &__container {
    padding-bottom: $mobile-container-padding;
    min-height: 150px;
    width: 100%;

    &:first-child {
      padding-left: 0;
    }

    .content-container {
      background-color: $white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: $mobile-container-padding;
      height: 100%;

      &.no-height {
        height: inherit;
      }

      .section-content {
        &.centered {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &.marketing {
          display: flex;
          flex-direction: column;
        }

        p {
          font-size: calc-rem(18);
          line-height: calc-rem(25);
        }

        ul {
          li {
            font-size: calc-rem(18);
            line-height: calc-rem(25);
            padding-bottom: 15px;
          }
        }

        ul.ticks {
          li {
            font-size: calc-rem(18);
            line-height: calc-rem(25);
            list-style-image: url(../../img/icons/tick.png);
          }
        }
      }
    }

    .section-title {
      font-size: calc-rem(20);
      line-height: calc-rem(25);
      font-weight: 500;
      color: $text-color;
      text-align: left;
      margin: 0;
      margin-bottom: 8px;
    }

    .section-sub-title {
      border-bottom: 1px solid $text-color;
      font-size: calc-rem(18);
      line-height: calc-rem(23);
      font-weight: 400;
      color: $text-color;
      padding: 8px 0;

      &:last-child {
        border-bottom: none;
      }
    }

    .section-empty {
      font-size: calc-rem(28);
      line-height: calc-rem(25);
      font-weight: 400;
      color: $dark-blue;
      text-align: left;
    }

    &.small {
      cursor: pointer;
      padding-left: $mobile-container-padding;
      width: 50%;

      @include media('<medium') {
        padding-left: 0;
      }

      &:first-child {
        padding-left: 0;
      }

      .section-title {
        font-size: calc-rem(18);
        line-height: calc-rem(24);
        font-weight: 400;
        color: $dark-blue;
        text-align: left;
      }

      .section-sub-title {
        font-size: calc-rem(14);
        line-height: calc-rem(20);
        font-weight: 400;
        color: lighten($dark-blue, 50%);
      }
    }

    &.side {
      padding: 0 $mobile-container-padding $mobile-container-padding;

      .section-sub-title {
        border-bottom: none;
        font-size: calc-rem(18);
        line-height: calc-rem(20);
        font-weight: 400;
        color: $text-color;
        padding: 3px 0;
      }

      @include media('>=medium', '<=xlarge') {
        width: 66%;
        margin: 0;
        padding: 0 0 13px 0;
      }

      @include media('>xlarge') {
        height: 310px;
        margin: 0;
        margin-left: 10px;
        width: 33%;

        .content-container {
          justify-content: flex-start;
        }
      }
    }

    img {
      align-self: flex-end;
      height: 12px;
      width: 12px;
    }

    .item-selection {
      cursor: pointer;
      display: flex;
      margin-top: 10px;

      input {
        cursor: pointer;
        margin-top: 7px;
        margin-right: 10px;
      }

      .item-tag {
        font-size: calc-rem(13);
        line-height: calc-rem(16);
        font-weight: 400;
        color: $darker-grey;
      }
    }

    @include media('>=medium') {
      padding-left: $mobile-container-padding;
      width: 650px;
    }

    &.full-width {
      padding-left: 0;
      width: 100%;
    }
  }

  .test-card {
    cursor: pointer;
    border-radius: 10px;
    color: $white;
    font-size: calc-rem(22);
    font-weight: 300;
    padding: 10px;
    margin: 10px 0 0;

    .lighten-text {
      color: lighten($white, 40%);
      font-size: calc-rem(22);
      line-height: calc-rem(24);
    }

    .lighten-text-small {
      color: lighten($white, 40%);
      font-size: calc-rem(16);
      line-height: calc-rem(20);
      text-align: right;
    }
    .section-date {
      font-size: calc-rem(20);
      line-height: calc-rem(24);
      font-weight: 500;
      text-align: right;
      margin-bottom: 10px;
    }

    .section-large-title {
      color: $white;
      font-weight: 500;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    img {
      margin-bottom: 10px;
      width: 110px;
    }

    .result-detail-panel {
      .left-etch {
        background-color: white;
        border-radius: 5px;
        margin-left: -15px;
        margin-top: -10px;
        height: 10px;
        width: 10px;
      }
      .right-etch {
        background-color: white;
        border-radius: 5px;
        margin-right: -15px;
        margin-top: -10px;
        height: 10px;
        width: 10px;
      }

      > div {
        margin-top: 15px;
      }

      .field-text {
        font-size: calc-rem(16);
        line-height: calc-rem(20);
        font-weight: 400;
      }
      .field-value {
        font-size: calc-rem(18);
        line-height: calc-rem(23);
        font-weight: 500;
      }
    }

    &.ttr-card {
      border: 1px solid $dark-blue;
      color: $dark-blue;

      .lighten-text,
      .lighten-text-small,
      .section-large-title {
        color: $dark-blue;
      }
      .bar-width {
        border-top: 1px solid $dark-blue;
        margin-left: 10px;
        width: calc(100% - 20px);
      }
      .left-etch {
        border-right: 1px solid $dark-blue;
        top: -10px;
        position: relative;
      }
      .right-etch {
        border-left: 1px solid $dark-blue;
        top: -10px;
        position: relative;
      }
    }
  }

  .switch-menu {
    display: flex;
    align-items: center;
    justify-content: center;

    .switch-option {
      align-items: center;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      width: 150px;
      margin: 0 20px 20px;
      user-select: none;
      text-decoration: underline;

      &.active {
        background-color: $white;
        padding: 5px;
      }
    }
  }

  .test-results {
    div {
      border-top: 3px solid #eaeaea;
      margin-top: 14px;
      width: 10px;
    }
    img {
      height: 30px;
      width: 30px;
    }
  }

  .test-results-basic {
    max-width: 360px;
    justify-content: center;
    div {
      font-size: calc-rem(14);
      font-weight: 500;
      line-height: calc-rem(16);
    }
    > div:not(.split) {
      width: 99px;
      &:last-child {
        padding-left: 20px;
      }
    }
    div.split {
      border-top: 3px solid #eaeaea;
      margin-top: 18px;
      width: 100px;

      &.active {
        border-top: 3px solid $flower-yellow;
      }
    }
    label {
      font-size: calc-rem(16);
      line-height: calc-rem(20);
    }
    img {
      height: 40px;
      width: 40px;

      &:nth-child(1) {
        margin-left: 40px;
      }
      &:last-child {
        margin-right: 40px;
      }
    }
  }

  .row-group {
    display: flex;
  }

  .split-row-group {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    img {
      align-self: center;
    }

    &.split-center {
      align-items: center;
    }
    &.split-end {
      align-items: flex-end;
    }
    .test-icon {
      display: flex;
      flex-direction: column;
      img {
        height: 24px;
        width: 29px;
      }
      label {
        font-size: calc-rem(16);
        line-height: calc-rem(20);
        margin-top: -8px;
      }
    }
  }

  .run-text {
    padding-top: 10px;
  }

  .row-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 10px;
    width: 100%;

    > div {
      width: 100%;
    }

    @include media('>=medium') {
      flex-wrap: nowrap;
      padding: 0;
      width: 50%;

      &.single {
        justify-content: flex-start;
        > div {
          width: 100%;
        }
      }

      &:nth-child(3) {
        padding-left: 10px;
      }
      &:nth-child(1),
      &:nth-child(4) {
        width: 100%;
      }
    }

    &.full-width {
      width: 100%;
    }
  }

  .main-container {
    width: 100%;

    &.mobile {
      display: flex;
      width: 100%;
    }
    .desktop {
      display: none;
    }
    @include media('>=medium') {
      display: flex;
      flex-wrap: wrap;
      width: 66%;
      .desktop {
        display: inherit;
      }
      &.mobile {
        display: none;
        width: 66%;
      }
    }
    @include media('<=xlarge') {
      .desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }
    }

    &.full-width {
      align-self: center;
      max-width: 770px;
      width: 100%;
    }
    &.dbl-full-width {
      align-self: center;
      width: 100%;
    }
  }

  .small-back {
    align-items: center;
    display: flex;
    cursor: pointer;
    color: $dark_blue;
    font-size: calc-rem(14);
    line-height: calc-rem(22);
    margin-left: -8px;
    img {
      height: 26px;
      margin-top: 16px;
      width: 24px;
    }
  }

  .checkbox {
    align-items: center;
    display: flex;
    font-size: calc-rem(14);
    line-height: calc-rem(22);
    margin-top: 10px;
    margin-bottom: 50px;
    input {
      margin-right: 10px;
    }
  }

  .button-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
  }

  .panel-container {
    @include media('>=medium') {
      display: flex;
      justify-content: 'space-evenly';
      > div {
        justify-content: flex-end;
        width: 50%;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .sub-panel {
    background-color: $page-background;
    border-radius: 10px;
    display: flex;
    font-size: calc-rem(16);
    line-height: calc-rem(20);
    flex-direction: column;
    padding: $mobile-container-padding;
    margin: 10px 0;

    > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-top: 15px;

      > div {
        border-left: 1px solid black;
        padding-left: 20px;
        height: 100%;
        width: 50%;

        span {
          font-size: calc-rem(28);
          line-height: calc-rem(45);
        }

        &:first-child {
          border-left: none;
          padding-left: 0;
          padding-right: 20px;
        }
      }
    }
  }

  table {
    width: 100%;

    td {
      &:nth-child(1) {
        width: 55px;
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 60px;
      }
    }
  }

  .form__row {
    label {
      font-size: calc-rem(16);
      line-height: calc-rem(18);
    }

    .input-label-space {
      height: 29px;
    }
  }

  .split-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .icon-row {
    cursor: pointer;
    display: flex;
    font-size: calc-rem(16);
    line-height: calc-rem(18);
    img {
      align-self: center;
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }
    &.link {
      font-size: calc-rem(14);
      line-height: calc-rem(16);
      justify-content: flex-end;
      img {
        height: 12px;
        width: 12px;
        margin-left: 5px;
      }
    }
  }

  .error-container {
    display: flex;
    justify-content: flex-end;
    height: 20px;
    label {
      color: $error-red-color;
      font-size: calc-rem(14);
      line-height: calc-rem(16);
    }
  }

  .container-comment {
    padding: 25px;

    label {
      font-size: calc-rem(28);
      line-height: calc-rem(32);
    }
  }

  .dual-results-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    table {
      width: 50%;

      &:nth-child(1) {
        padding-right: 10px;
      }
      &:nth-child(2) {
        border-left: 1px solid black;
        padding-left: 10px;
      }
    }
  }

  .notify-container {
    padding: 20px 0;
  }

  .section-result-cap {
    font-size: calc-rem(16);
    line-height: calc-rem(18);
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .section-pre-title {
    color: $dark_blue;
    font-size: calc-rem(14);
    font-weight: 500;
    line-height: calc-rem(16);
    margin-bottom: 10px;
  }
  .section-large-title {
    font-size: calc-rem(28);
    line-height: calc-rem(25);
    font-weight: 400;
    color: $dark-blue;
    margin-bottom: 12px;
  }
  .section-comment {
    color: $dark_blue;
    font-size: calc-rem(14);
    line-height: calc-rem(20);
  }

  &__title {
    font-size: calc-rem(28);
    color: $dark-blue;
    line-height: calc-rem(25);
    margin: 0;
    margin-bottom: $container-inside-padding;
    font-weight: 400;

    @include media('<=small') {
      margin-bottom: $mobile-container-padding;
    }
  }
}
