// -----------------------------------------------------------------------------
// This file contains styles that are specific to the checkout
// -----------------------------------------------------------------------------

.login-form {
    &__links {
        display: flex;
        justify-content: space-between;

        a {
            text-decoration: underline;
        }

        &.single-link {
            justify-content: flex-end;
        }

        @include media ("<=small") {
            flex-direction: column;

            a {
                text-align: right;
                margin: 10px 0 0;
            }
        }
    }

    &__reset-instruction {
        margin: 0 0 $mobile-container-padding;
    }
}