// -----------------------------------------------------------------------------
// This file contains styles that are specific to the compare page
// -----------------------------------------------------------------------------

.compare {
    &__container {
        width: 100%;
        max-width: 910px;
        margin: 0 auto;
        padding: 0 $container-inside-padding;

        @include media ("<=small") {
            padding: 0 $mobile-container-padding;
        }
    }

    &__hero-section {
        padding: 50px 0 $container-inside-padding;
        color: $text-color;
        margin: 0 10px;

        .title {
            font-size: calc-rem(36);
            font-weight: 500;
            margin: 0 0 $mobile-container-padding;
        }

        .description {
            font-size: calc-rem(24);
            line-height: 40px;
            margin: 0;
        }

        @include media ("<=medium") {
            margin: 0 0;

            .title {
                font-size: calc-rem(30);
            }

            .description {
                font-size: calc-rem(18);
                line-height: 30px;
            }
        }
    }

    &__sections {
        display: flex;
        padding: 0 0 $container-inside-padding;

        @include media ("<=small") {
            flex-wrap: wrap;
        }

        &.comparison-cards {
            flex-wrap: wrap;
        }
    }

    &__single-section {
        flex-basis: calc(100%/3 - 50px);
        margin: 0 25px;
        flex-grow: 1;
        max-width: calc(100%/2 - 50px);
        color: $text-color;

        @include media ("<=small") {
            flex-basis: 100%;
            max-width: 100%;
            margin: 0;
        }
    }

    &__single-section-title {
        font-size: calc-rem(24);
        line-height: 63px;
        margin: 0 0 $mobile-container-padding;
    }

    &__disease-risk-section {
        width: 100%;

        .comparison-risk-information {
            margin: 0 10px;
        }

        .comparison-risk-wrapper {
            max-width: unset;
        }

        .comparison-risk-container {
            max-width: unset;
            padding: 0 0 $container-inside-padding;
        }

        .comparison-risk-card {
            width: calc((100%/3) - 20px);
            margin: $container-inside-padding 10px 0;

            @include media ("<=medium") {
                width: 100%;
                margin: $container-inside-padding 0 0;
            }
        }
    }
}

.compare-card {
    &__container {
        height: 80px;
        width: 100%;
        margin: $mobile-container-padding 0;
        padding: 0 20px;
        background-color: $white;
        box-shadow: 0 5px 24px 0 rgba(0,0,0,0.10);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: calc-rem(20);
        cursor: pointer;
        color: $text-color;
        text-decoration: none;

        &:hover {
            background-color: #f9f8f8;
            i {
                transform: scale(1.2);
                @include transition(transform .3s ease);
            }
        }

        &--blue {
            background-color: $dodger-blue;
            color: $white;

            &:hover {
                background-color: $dodger-blue;
                i {
                    transform: none;
                }
            }
        }

        &--no-results {
            cursor: default;

            &:hover {
                background-color: $white;
                i {
                    transform: none;
                }
            }
        }
    }

    &__title {
        font-size: calc-rem(20);
    }
}

.comparison-card {
    &__container {
        width: 100%;
        margin: $mobile-container-padding 10px;
        box-shadow: 0 5px 34px 0 rgba(0,0,0,0.10);
        border-radius: 10px;
        background-color: $white;
        flex-basis: calc(100%/3 - 20px);
        flex-grow: 1;
        max-width: calc(100%/3 - 20px);

        @include media ("<=medium") {
            flex-basis: 100%;
            margin: $mobile-container-padding 0;
            max-width: 100%;
        }
    }

    &__information-section {
        background-color: #F2F2F2;
        padding: 20px $mobile-container-padding;
        color: $text-color;
        border-radius: 10px 10px 0 0;
        min-height: 125px;

        .card-title {
            font-size: calc-rem(18);
            margin: 0 0 13px;
            font-weight: 500;
        }

        .card-information {
            font-size: calc-rem(16);
            margin: 0;
            line-height: 1.4;
        }

        @include media ("<=medium") {
            min-height: unset;
        }
    }

    &__circles-container {
        text-align: center;
        padding: 20px $mobile-container-padding;
    }

    &__oval-section {
        display: inline-block;
        vertical-align: top;
        padding: 0 $mobile-container-padding;
        width: 50%;

        &.description-circle {
            width: auto;
            padding: 0;
        }

        .label {
            font-size: calc-rem(20);
            line-height: 1.2;
            text-transform: capitalize;
        }

        @include media ("<=medium") {
            padding: 0;
        }
    }

    &__oval {
        width: 70px;
        height: 70px;
        display: flex;
        margin: 0 auto 10px;
        border: 4px solid #A3A3A3;
        border-radius: 50%;

        &.description-circle {
            width: 44px;
            height: 44px;
            border: 3px solid $dodger-blue;
            margin: 0 auto;

            .content-container {
                p {
                    font-size: calc-rem(20);

                    &:last-child {
                        font-size: calc-rem(10);
                    }
                }
            }
        }

        &.default {
            border-color: #5B7BFF;
        }

        &.lost {
            border-color: #FFA800;
        }

        &.gained {
            border-color: #75CD1B;
        }

        &.maintained {
            border-color: #A3A3A3;
        }

        .content-container {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            flex-direction: column;

            p {
                font-size: calc-rem(24);
                margin: 0;
                line-height: 1;
                font-weight: 500;

                &:last-child {
                    font-size: calc-rem(15);
                    font-weight: normal;
                }
            }
        }
    }

    &__descriptions-container {
        padding: 0 $mobile-container-padding 20px;
    }

    &__description {
        margin: $mobile-container-padding 0 0;
        display: flex;

        .image-container {
            width: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;

            img {
                width: auto;
                max-width: 30px;
                max-height: 30px;
            }
        }

        .item-description {
            display: inline-block;
            width: calc(100% - 65px);
            margin: 0 0 0 20px;
            line-height: 1.3;
            font-size: calc-rem(16);
        }
    }
}
