// -----------------------------------------------------------------------------
// This file contains styles that are specific to the multiple choice component
// -----------------------------------------------------------------------------

%item {
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    background-color: #F8F8F8;
    font-size: calc-rem(16);
    color: $text-color;
    letter-spacing: 1.26px;
    display: inline-block;
    margin: $mobile-container-padding;
}

.custom {
    &__radio-container {
        position: relative;
        display: flex;
        flex-basis: calc(100% / 3);
        margin: 45px 0 0 0;

        @include media ("<=medium") {
            flex-basis: auto;
        }

        &--full-width {
            flex-basis: 100%;
            margin-top: 0;
        }

        .radio-button[type=radio] {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        label {
            @extend %item;
            padding: 8px 10px;
            cursor: pointer;
            text-align: center;
            min-width: 150px;
            width: 100%;
        }

        .radio-button:checked + label {
            background-color: $dodger-blue;
            color: $white;
            font-weight: 500;
        }
    }

    &__multiple-choice {
        display: flex;
        flex-wrap: wrap;
    }

    &__date-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .input-field__container {
            width: 100%;
        }

        .field-section {
            display: flex;
            flex-basis: calc(100%/3);

            input[type=text] {
                @extend %item;
                padding: 10px 20px;
                width: calc(100% - 26px);
                line-height: 22px;

                @include media ("<=medium") {
                    margin: $mobile-container-padding 5px;
                    width: calc(100% - 10px);
                    padding: 10px;
                }
            }

            label {
                left: $mobile-container-padding + 20px;
                top: $mobile-container-padding + 10px;
                font-size: calc-rem(16);
                color: $text-color;
                opacity: 1;

                @include media ("<=medium") {
                    left: 15px;
                }
            }

            input:focus ~ .input-field__label, .input-field__label.valid {
                top: -15px;
                left: $mobile-container-padding + 5px;
                font-size: calc-rem(16);
                font-weight: 300;

                @include media ("<=medium") {
                    left: 10px;
                }
            }
        }
    }

    &__select-wrapper {
        &.form__select-wrapper {
            select {
                @extend %item;
                width: calc(100% - 26px);
                padding: 10px 20px;
                line-height: 22px;
            }
        }
    }

    &__time-field {
        margin: $mobile-container-padding;
    }

    &__checkbox-container {
        margin: $mobile-container-padding;
    }

    &__slider-container {
        padding: 50px $mobile-container-padding $container-inside-padding;

        .rc-slider-step {
            background-color: #EAEAEA;
            height: 6px;
            border-radius: 5px;
        }

        .rc-slider-dot {
            display: none;
        }

        .rc-slider-handle {
            height: 28px;
            width: 28px;
            top: -7px;
            margin-top: 0;
            background-color: #444444;
            box-shadow: 0 8px 20px 0 rgba(0,0,0,0.25);
            border: none;
        }

        .rc-slider-mark {
            top: $container-inside-padding;
        }
    }

    &__slider-radio {
        border: 0 solid #EAEAEA;
        border-radius: 5px;
        display: inline-block;
        vertical-align: top;
        height: 29px;

        input {
            display: none;
        }

        &.active {
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
        }

        label {
            margin: 0;
            padding: 0 10px;
            display: inline-block;
            cursor: pointer;
            font-size: calc-rem(16);
            color: $text-color;
            letter-spacing: 0.17px;
            text-align: center;
            vertical-align: top;
            height: 100%;
        }
    }
}

.form {
    input[type=text].custom__text-field {
        @extend %item;
        width: calc(60% - 26px);
        padding: 10px 20px;
        line-height: 22px;
    }
}