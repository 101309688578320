// -----------------------------------------------------------------------------
// This file contains styles that are specific to the actionable you component
// -----------------------------------------------------------------------------

.actionable-you {
    &__container {
        max-width: 1168px;
        margin: 0 auto;
        padding: $container-inside-padding;
        color: #444444;
        text-align: center;

        @include media ("<=medium") {
            padding: 5px $mobile-container-padding;
        }
    }

    &__information-section {
        max-width: 600px;
        margin: 40px auto;

        .title {
            font-size: calc-rem(40);
            color: $intervenable-title-color;
            text-align: center;
            line-height: 43px;
            margin: 0;
            font-weight: 500;
        }

        .description {
            font-size: calc-rem(18);
            text-align: center;
            line-height: 26px;
            margin: $container-inside-padding 0 0 0;
        }
    }

    &__section-container {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 20px;
    }

    &__icons-container {
        border-bottom: 1px solid #DDDDDD;
        padding: $mobile-container-padding 0;
        text-align: center;

        .icon-wrapper {
            display: inline-block;
            margin: 0 $container-inside-padding;
            opacity: 0.5;
            cursor: pointer;
            @extend %hover-up-animation;

            @include media ("<=small") {
                width: calc(100%/3);
                margin: $mobile-container-padding 0;
            }

            > img {
                width: 69px;
            }

            > p {
                font-size: calc-rem(18);
                color: #000000;
                text-align: center;
                line-height: 26px;
                margin: 0;
            }

            &.active, &.active:hover, &.not-selected {
                opacity: 1;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        &.has-active {
            .icon-wrapper {
                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    &__image-section {
        background: #FFF8F9;
        border-radius: 0 0 20px 20px;

        .background-image {
            height: 460px;
            width: 100%;
            background: url("../../img/you/actionable-you/actionable-you-desktop-background.svg") center center no-repeat;
            position: relative;
            border-radius: 0 0 20px 20px;

            @include media ("<=small") {
                background: url("../../img/you/actionable-you/actionable-you-mobile-background.svg") center center no-repeat;
                background-size: cover;
            }

            @extend %link-item;
            a {
                &:before {
                    background-color: unset;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .indicator-link {
                position: absolute;
                font-size: calc-rem(18);
                color: #445CBF;
                line-height: 26px;
                left: 70%;
                top: 9%;
                padding: 0 0 10px 0;

                &:hover, &:active {
                    &:before {
                        @media (max-width: 1199px) {
                            visibility: visible;
                        }
                    }
                }

                @include media ("<=small") {
                    left: 70%;
                    top: 45%;
                }

                &:first-child {
                    left: 15%;
                    top: 20%;

                    @include media ("<=small") {
                        left: 5%;
                        top: 17%;
                    }
                }

                &:last-child {
                    left: 81%;
                    top: 83%;

                    @include media ("<=small") {
                        left: 5%;
                        top: 84%;
                    }
                }

                p {
                    font-size: calc-rem(16);
                    color: #445CBF;
                    line-height: 16px;
                    margin: 10px 0 0 0;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    &__analysis-container {
        display: none;

        &.show {
            display: block;
            transform-origin: left;
            @include animation(show-animation 0.5s ease-in forwards);
        }
    }

    &__analysis-section {
        &.show-border {
            border-bottom: 1px solid #DDDDDD;
        }
    }

    &__analysis-background {
        background: url("../../img/you/actionable-you/analysis-background.png") center center/cover no-repeat;
        padding: 180px 0 0 0;
        height: 320px;
        margin: 20px 0 100px 0;

        > p {
            margin: 0;
            font-size: calc-rem(22);
            color: #000000;
            text-align: center;
            line-height: 35px;
        }

        > h3 {
            margin: 0;
            font-size: calc-rem(26);
            font-weight: 500;

            &.good {
                color: $good-text-color;
            }

            &.normal {
                color: $avg-text-color;
            }

            &.bad {
                color: $bad-text-color;
            }
        }

        .section-information {
            font-size: calc-rem(16);
            color: #000000;
            text-align: center;
            line-height: 20px;
            max-width: 600px;
            margin: $container-inside-padding auto 20px;
        }

        @include media ("<=small") {
            background-image: none !important;
            padding: $container-inside-padding $mobile-container-padding;
            height: auto;
            margin: 0;

            > p {
                line-height: calc-rem(22);
            }

            > h3 {
                margin: 5px 0 0 0;
                line-height: calc-rem(26);
            }
        }
    }

    &__affecting-factors {
        padding: $container-inside-padding;

        @include media ("<=small") {
            padding: $container-inside-padding $mobile-container-padding;
        }

        > p {
            font-size: calc-rem(18);
            color: #000000;
            letter-spacing: 0;
            text-align: center;
            line-height: 22px;
            margin: 0;
        }
    }

    &__indicators-container {
        .indicator-container {
            display: inline-block;
            padding: $container-inside-padding 5vw 0;

            > div {
                cursor: pointer;
            }

            @include media ("<=small") {
                display: block;
            }

            div {
                display: inline-block;
            }

            p {
                font-size: calc-rem(18);
                color: #000000;
                text-align: center;
                line-height: 26px;
                margin: 5px 0 10px;
            }

            .btn {
                font-size: calc-rem(14);
                line-height: 11px;
            }
        }
    }
}
