// -----------------------------------------------------------------------------
// This file contains styles that are specific to the registration page
// -----------------------------------------------------------------------------

.registration-page {
    &__wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.registration {
    &__wrapper {
        width: 100%;
        padding: $container-inside-padding $container-inside-padding 85px;
        display: flex;
        align-items: center;
    }

    &__container {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
        background-color: $white;
        padding: $container-inside-padding;
    }
}

.register-form {
    &__container {
        text-align: center;
        color: $text-color;

        .icon-cancel {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 10px;
            z-index: 1;
        }
    }

    &__title {
        font-size: calc-rem(30);
        letter-spacing: 1.07px;
        margin: $mobile-container-padding 0;
    }

    &__sub-title {
        font-size: calc-rem(18);
        letter-spacing: 1.42px;
        margin: 0 0 $mobile-container-padding;
    }

    &__description {
        font-size: calc-rem(16);
        letter-spacing: 1.26px;
        line-height: 25px;
        margin: 0 auto $container-inside-padding;
        //max-width: 260px;

        &--important {
            color: red;
        }
    }

    &__fields-container {
        position: relative;
    }

    &__test-selection-cotainer {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;

        .link-hover {
            cursor: pointer;

            &:hover {
                .image-container {
                    background: #f9f8f8;
                }

                img {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                }
            }
        }

        .image-container {
            border: 2px solid #F2F2F2;
            width: 100px;
            height: 100px;
            text-align: center;
            border-radius: 10px;
            img {
                max-width: 80px;
                padding-top: 21px;
            }
        }

        .testTitle {
            margin-top: 5px;
            font-size: calc-rem(17);
        }

        a {
            color: $text-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__image-container {
        background: #D8D8D8;
        border: 1px solid #979797;
        //min-height: 120px;
        margin: 0 0 $container-inside-padding;

        &.image-section {
            border: none;
            background-color: $white;
        }

        img {
            max-width: 100%;
            margin: 0 auto;
            width: 100%;

            &.alarm-image {
                width: 105px;
                height: 105px;
            }

            &.step-image {
                max-width: 300px;
                max-height: 250px;
            }
        }

        &.register-images {
            min-height: 200px;
        }
    }

    &__links-section {
        min-height: 230px;
        margin: 0 0 $container-inside-padding;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .base-link {
            display: flex;
            justify-content: space-between;
            padding: $mobile-container-padding $container-inside-padding;
            border-radius: 5px;
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.2);
            color: $text-color;
            font-weight: 500;
            margin: 20px 0 0;
            font-size: calc-rem(16);
        }
    }

    &__input-fields {
        margin: 0 0 $mobile-container-padding;
    }

    &__multiple-fields {
        max-height: 250px;
        overflow: auto;
        text-align: left;

        &__main-label {
            font-weight: 500;
            font-size: calc-rem(20);
            padding-bottom: 20px;
            display: block;
        }
    }

    &__single-field {
        padding: $mobile-container-padding 0 0;
        text-align: left;
        position: relative;

        &.button-container {
            padding: $container-inside-padding 0 0;
        }

        &.link-container {
            padding: 0;
        }

        &.checkbox-field {
            padding-bottom: 20px;
        }

        .form__label {
            position: absolute;
            pointer-events: none;
            transition: 0.2s all;

            &.mobile-label {
                left: 70px;
                top: 18px;
            }
        }

        .phone-input__container:focus-within ~ label.mobile-label, label.form__label.mobile-label.valid {
            top: -15px;
            left: 0;
            font-size: calc-rem(16);
            opacity: 1;
        }

        .phone-input__container:focus-within {
            .PhoneInputInput {
                border: none;
                border-bottom: 2px solid $dodger-blue;
            }
        }

        .PhoneInput {
            input, select {
                margin-top: 0;
                height: 100%;
                color: $text-color;
                font-size: calc-rem(16);
            }
        }

        .email-field {
            color: $dodger-blue;
        }

        .not-you-link {
            display: inline-block;
            margin: 0 0 0 15px;
            text-decoration: underline;
            cursor: pointer;
        }

        .label-text {
            display: inline-block;
            width: calc(100% - 50px);
            vertical-align: top;
            margin: 0;
        }

        .date-field {
            position: relative;
            width: 80%;
            margin: 20px auto;

            &--wide {
                width: 100%;
            }

            .react-datepicker-wrapper, .react-datepicker__input-container {
                width: 100%;
            }

            .react-datepicker-popper {
                z-index: 10;
            }

            input {
                padding: 10px 15px;
                line-height: 17px;
                font-size: calc-rem(16);
                color: $text-color;
                border-radius: 4px;
                border: 1px solid #cccccc;
                margin: 10px 0 0 0;
                width: 100%;

                &:active, &:focus {
                    outline: none;
                }
            }

            i {
                position: absolute;
                font-size: calc-rem(20);
                right: 5px;
                top: 14px;
                color: $text-color;
                cursor: pointer;
            }

            @include media ("<=tinysmall") {
                width: 100%;
            }
        }

        .time-field {
            width: 80%;
            margin: 20px 10px;

            @include media ("<=tinysmall") {
                width: 100%;
            }
        }

        .gender-field {
            width: 80%;
            margin: 20px 10px;

            @include media ("<=tinysmall") {
                width: 100%;
            }
        }

        &.phone-input-container {
            margin: 20px 0 0 0;
        }

        .custom__radio-container {
            margin-top: 25px;
        }
    }

    &__date-and-gender {
        display: flex;
        flex-direction: column;

        .date-single-field {
            margin: 0 10px;
            margin-top: 20px;
        }

        .date-label {
            position: relative;
            text-align: start;
        }

        .date-container {
            display: flex;
            flex-direction: row;
            margin-top: -30px;
        }

        .flex-field {
            flex: 1;
        }

        .register-form__single-field {
            width: 50%;
            display: inline-block;

            &.checkbox-field {
                padding-bottom: 20px;
            }

            .date-field {
                width: 100%;
            }

            .register-form__time-field {
                width: 100%;

                select {
                    border-radius: 4px;
                    margin: 9px 0 0;
                    line-height: 17px;
                }

                i {
                    top: 13px;
                }
            }
            &:last-child {
                width: 50%;
            }
        }
    }

    &__link {
        font-size: calc-rem(14);
        color: #222222;
        letter-spacing: 1.11px;
        cursor: pointer;
        text-align: left;
        text-decoration: underline;

        &.another-test {
            margin: 20px 0 $container-inside-padding;
        }
    }

    &__disclaimer-text {
        font-size: calc-rem(14);
        line-height: calc-rem(17);
        color: #888888;
        letter-spacing: 1.26px;
    }

    &__custom-checkbox {
        position: absolute;
        opacity: 0;

        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            font-size: calc-rem(14);
            line-height: calc-rem(17);
            color: #888888;
            letter-spacing: 1.26px;
            display: inline-block;

            a {
                color: #222222;
                text-decoration: underline;
            }
        }

        & + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 24px;
            height: 24px;
            background: white;
            border: 1px solid #cccccc;
        }

        &:checked + label:before {
            background: #5B7BFF;
            border: none;
        }

        &:checked + label:after {
            content: '';
            position: absolute;
            left: 6px;
            top: 11px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow:
                2px 0 0 white,
                4px 0 0 white,
                4px -2px 0 white,
                4px -4px 0 white,
                4px -6px 0 white,
                4px -8px 0 white;
            transform: rotate(45deg);
        }
    }

    &__image-description {
        margin: $container-inside-padding 0 $mobile-container-padding;
    }

    &__complete-container {
        padding: $container-inside-padding 0;
        text-align: center;

        .complete-title {
            font-size: calc-rem(26);
            color: #444444;
            letter-spacing: 1.3px;
            text-align: center;
            line-height: calc-rem(36);
            margin: 0 0 $mobile-container-padding;
        }

        .complete-description {
            font-size: calc-rem(16);
            color: #888888;
            letter-spacing: 1.26px;
            text-align: center;
            line-height: calc-rem(20);
            margin: 0;
        }

        .button-container {
            padding: $container-inside-padding $container-inside-padding 0;
            text-align: center;
        }
    }

    &__complete-icon {
        border: 2px solid #D7D9DB;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        font-size: 30px;
        line-height: 75px;
        color: #D7D9DB;
        margin: 0 auto;
    }

    &__country-field {
        display: inline-block;
        position: relative;
        color: $text-color;
        margin: 10px 0 0 5px;
        width: 100%;

        select {
            width: 100%;
            padding: 10px 15px;
            font-size: calc-rem(16);
            border: 1px solid #cccccc;
            color: $text-color;
            appearance: none;
            background-color: $white;
            cursor: pointer;

            &:active, &:focus {
                outline: none;
            }
        }

        &:first-child {
            margin: 0;
        }

        i {
            position: absolute;
            right: 5px;
            top: 6px;
            font-size: calc-rem(20);
            pointer-events: none;
        }
    }

    &__usertype-field {
        display: inline-block;
        position: relative;
        color: $text-color;
        margin-bottom: 20px;
        width: 100%;

        select {
            width: 100%;
            padding: 10px 15px;
            font-size: calc-rem(16);
            border: 1px solid #cccccc;
            color: $text-color;
            appearance: none;
            background-color: $white;
            cursor: pointer;

            &:active, &:focus {
                outline: none;
            }
        }

        i {
            position: absolute;
            right: 5px;
            top: 6px;
            font-size: calc-rem(20);
            pointer-events: none;
        }
    }

    &__time-field {
        display: inline-block;
        position: relative;
        color: $text-color;
        margin: 10px 0 0 5px;
        width: calc(50% - 5px);

        select {
            width: 100%;
            padding: 10px 15px;
            font-size: calc-rem(16);
            border: 1px solid #cccccc;
            color: $text-color;
            appearance: none;
            background-color: $white;
            cursor: pointer;

            &:active, &:focus {
                outline: none;
            }
        }

        &:first-child {
            margin: 0;
        }

        i {
            position: absolute;
            right: 5px;
            top: 6px;
            font-size: calc-rem(20);
            pointer-events: none;
        }
    }

    &__percentage-bar {
        width: 100%;
        height: 8px;
        border: none;
        background-color: #EAEAEA;
        margin: $container-inside-padding 0;

        .progress-bar__bar {
            height: 8px;
            background-color: $dodger-blue;
            top: 0;
        }
    }

    &__links-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .back-link {
            text-transform: lowercase;
            cursor: pointer;
            color: $dodger-blue;
        }

        .btn {
            min-width: 100px;
        }
    }
}
