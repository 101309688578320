// -----------------------------------------------------------------------------
// This file contains styles that are specific to the phone input component
// -----------------------------------------------------------------------------

.phone-input {
    &__container {
        width: 100%;

        .PhoneInput {
            width: 100%;
            height: 40px;

            .PhoneInputInput {
                outline: none;
                border-radius: 0;
                padding: 10px 15px;
                border: none;
                border-bottom: 2px solid #C5D2E0;
                transition: border 0.1s;
            }

            input, select {
                margin-top: 0;
                height: 100%;
            }

            .PhoneInputCountry {
                height: 100%;

                .PhoneInputCountryIcon {
                    display: flex;
                    align-items: center;
                    width: 35px;
                    height: 100%;
                    border: none;
                    box-shadow: none;
                    background-color: $white;
                }
            }
        }

        &:focus, &:active, &:hover {
            outline: none;
        }
    }
}
