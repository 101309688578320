// -----------------------------------------------------------------------------
// This file contains styles that are specific to the genetic trait component
// -----------------------------------------------------------------------------


$title-color: #1FC7DF;
$description-color: #444444;

%border-item {
    border: 1px solid #DDDDDD;
    border-radius: 20px;
}

.genetic-trait {
    &__container {
        max-width: $max-width;
        margin: 0 auto;
        padding: 10px $container-inside-padding 0;
        color: $description-color;
        text-align: center;
    }

    &__title {
        font-size: 40px;
        color: $title-color;
        text-align: center;
        line-height: 1.4;
        margin: 11px 0;
        font-weight: 500;
    }

    &__information {
        font-size: 18px;
        max-width: 520px;
        text-align: center;
        line-height: 26px;
        margin: 0 auto;
    }

    &__trait-section {
        @extend %border-item;
        padding: $container-inside-padding;
        margin: 50px 0 0 0;

        .trait-sub-title {
            font-size: 24px;
            color: $black;
            text-align: center;
            line-height: 22px;
            margin: 0 0 25px 0;
            font-weight: normal;
        }

        .trait-description {
            font-size: 22px;
            color: #6A6A6A;
            text-align: center;
            line-height: 43px;
            margin: 0;
        }

        .trait-effect {
            font-size: 40px;
            color: #adbbf5;
            text-align: center;
            line-height: 46px;
            margin: 0;
        }
    }

    &__icon-section {
        margin: $container-inside-padding auto 40px;
        max-width: 150px;

        @include media("<=tinysmall") {
            margin: $container-inside-padding auto;
            max-width: 120px;
        }
    }

    &__tip-content {
        font-size: 14px;
        color: #C5C5C5;
        text-align: right;
        line-height: 1.4;
        margin: 10px 0 0 0;
    }

    &__accordion {
        .accordion-title {
            color: $title-color;
            line-height: 22px;
            font-weight: normal;
        }

        .graph-section {
            .description {
                width: 100%;
            }
        }

        .genetic-variants .position-section-content {
            margin: 20px 0;
            text-align: left;

            .position-item {
                height: 140px;
                width: 160px;
                margin: 9px;
                display: inline-block;

                @include media("<=tinysmall") {
                    margin: 5px;
                    width: calc(50% - 10px);
                }

                .position-information {
                    @extend %border-item;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    h4 {
                        margin: 0;
                        font-size: 40px;
                        text-align: center;
                        line-height: 46px;
                    }

                    p {
                        margin: 0;
                        font-size: 20px;
                        color: #444444;
                        text-align: center;
                        line-height: 46px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .section-graph {
            margin: 20px 0;
        }
    }

    &__section-container {
        margin: $container-padding 0 0 0;
    }

    &__information-section {
        max-width: 600px;
        margin: 0 auto;

        .section-title {
            font-size: 24px;
            color: $title-color;
            text-align: center;
            line-height: 22px;
            margin: 0 0 25px 0;
            font-weight: normal;
        }

        .section-information {
            font-size: 18px;
            text-align: center;
            line-height: 26px;
            margin: 0;
        }
    }

    &__graph-section {
        @extend %border-item;
        width: 70%;
        border-radius: 6px;
        margin: 50px auto;

        @include media ("<=medium") {
            width: 100%;
        }

        > div {
            padding-right: 90%;
            margin: 0 auto;
        }
    }

    &__fun-facts-container {
        background: #F1FBFA;
        border-radius: 20px;
        padding: $container-inside-padding 50px;
        margin: $container-padding 0 0 0;
        display: flex;

        @include media ("<=medium") {
            padding: $container-inside-padding 0;
        }
    }
}
