// -----------------------------------------------------------------------------
// This file contains styles that are specific to the search page
// -----------------------------------------------------------------------------

.title {
    letter-spacing: 0;
    line-height: 1.4;
    margin: 0;
    font-size: calc-rem(40);
}

.show-circle {
    border: 4px solid #FFFFFF;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
    font-size: calc-rem(74);
    color: #FFFFFF;
    text-align: center;
    line-height: 150px;
}

.search-page {
    &__header-section {
        background: $melrose;
        box-shadow: 0 2px 8px 0 $indigo;
        font-size: calc-rem(40);
        color: $white;

        .title {
            padding-right: 20px;
            display: inline-block;
            text-align: right;
            font-weight: 500;

            @include media ("<=medium") {
                width: 100%;
                text-align: center;
                padding-right: 0;
            }
        }

        > div {
            max-width: $max-width;
            padding: 50px 36px;
            margin: 0 auto;
            text-align: center;

            @include media ("<=medium") {
                display: block;
                text-align: center;
                padding: 50px $mobile-container-padding;
            }
        }

        .input-container {
            display: inline-block;
            vertical-align: top;
            line-height: 1.4;
            position: relative;
            font-weight: 500;

            > div {
                display: inline-block;
                position: relative;
            }

            input {
                background: #FFFFFF;
                box-shadow: 0 2px 8px 0 #445CBF;
                height: 45px;
                min-width: 240px;
                border: none;
                padding: 0 45px 0 20px;
                font-size: calc-rem(22);
                color: $dodger-blue;

                &:active, &:hover, &:focus {
                    outline: none;
                }
            }

            button {
                position: absolute;
                right: 35px;
                top: 12px;
                border: none;
                height: 45px;
                background-color: white;

                img {
                    cursor: pointer;
                }

                &:active, &:hover, &:focus {
                    outline: none;
                }

                @media (max-width: 343px) {
                    top: 11px;
                    right: 10px;
                }
            }

            span {
                font-family: sans-serif;
            }
        }
    }

    &__container {
        max-width: 910px;
        margin: 0 auto;
        padding: 50px $container-inside-padding;
        text-align: center;
        color: $text-color;

        @include media ("<=medium") {
            padding: 50px $mobile-container-padding;
        }
    }

    &__top-section {
        max-width: 750px;
        margin: 0 auto;

        .title {
            color: $dodger-blue;
            font-weight: 500;
            font-size: calc-rem(40);
            line-height: calc-rem(40);
            margin-bottom: 20px;
        }

        .information {
            font-size: calc-rem(18);
            text-align: center;
            line-height: 26px;
            margin: 10px 0 0;

            span {
                color: $melrose;
            }
        }
    }

    &__section-container {
        margin: $container-padding 0 0 0;

        .health-opportunity-section {
            opacity: 0.4;
        }
    }

    &__information-container {
        position: relative;

        .loader-container {
            position: absolute;
            top: 10%;
            width: 100%;
            z-index: 1;
        }
    }

    &__box-container {
        opacity: 1;
        position: relative;
        &.nextResult {
            opacity: 0.2;
        }
    }

    &__science-section {
        padding: $container-inside-padding 0;
        @extend %border-item;

        .other-result {
            padding: $mobile-container-padding $container-inside-padding $mobile-container-padding $mobile-container-padding;
            text-align: left;

            > p {
                text-align: right;
                width: 100%;
                margin: 0;
                font-size: calc-rem(18);
                color: $dodger-blue;
                line-height: 1.4;
                display: inline-block;

                > span {
                    cursor: pointer;
                }

                &:first-child {
                    text-align: left;
                    font-size: calc-rem(14);
                    color: $dim-grey;
                    letter-spacing: 0.17px;
                }
            }
        }

        .image-section {
            width: 100%;
            background: url(../../img/search/dna-background.png) center center no-repeat;
            position: relative;
            padding: $mobile-container-padding 0;
            min-height: 200px; //in cause of no strand

            @include media ("<=medium") {
                background-size: cover;
            }

            .variant-section {
                color: #8a8989;
                font-size: calc-rem(20);
                text-align: center;
                line-height: 19px;
                word-break: break-all;
            }

            .single-region {
                position: relative;
                display: inline-block;

                span {
                    .highlight-region {
                        color: white;
                        position: relative;
                        display: inline-block;
                    }
                }
            }

            .oval-shape {
                border: 4px solid white;
                height: 25px;
                width: 40px;
                border-radius: 14px;
                position: absolute;
                z-index: 1;
                transform: translate(-50%,-50%);
                top: 50%;
                left: 50%;
            }
        }

        .specifications-section {
            padding: $container-inside-padding $mobile-container-padding;
            text-align: left;

            .container {
                margin: 0 0 10px 0;

                &:last-child {
                    margin: 0;
                }
            }

            .specification-section-title {
                font-size: calc-rem(16);
                color: #7B7B7B;
                letter-spacing: 0;
                line-height: 18px;

                > span {
                    cursor: pointer;
                    color: $melrose;
                }
            }

            .genome-section {
                width: 25%;
                display: inline-block;
                padding: 0 $mobile-container-padding;
                vertical-align: top;
                font-size: calc-rem(16);
                letter-spacing: 0;
                line-height: 18px;

                .question {
                    @extend .specification-section-title;
                    display: inline-block;
                    width: 40%;
                    margin-right: 10%;
                    text-align: right;
                }

                .answer {
                    color: $melrose;
                    display: inline-block;
                    width: 50%;
                }
            }

            .gene-section {
                width: 45%;
                display: inline-block;
                padding: 0 $mobile-container-padding;
                vertical-align: top;

                .gene-title {
                    font-size: calc-rem(16);
                    color: #7B7B7B;
                    letter-spacing: 0;
                    line-height: 18px;
                }

                .gene-description {
                    font-size: calc-rem(14);
                    letter-spacing: 0;
                    line-height: 16px;
                    margin: 5px 0 0 0;

                    &.diseases {
                        color: $melrose;
                        cursor: pointer;
                    }
                }

                a {
                    font-size: calc-rem(14);
                    color: $dodger-blue;
                    letter-spacing: 0;
                    line-height: 18px;
                    font-weight: 500;
                }
            }

            .publications-section {
                width: 30%;
                display: inline-block;
                padding: 0 $mobile-container-padding;
                vertical-align: top;

                .specification-section-title {
                    margin: 0 0 5px 0;
                }

                .publication-name {
                    @extend .container;
                    font-size: calc-rem(14);
                    color: $melrose;
                    letter-spacing: 0;
                    line-height: 16px;
                    display: inline-block;
                }
            }

            @media screen and (max-width: 900px) and (min-width: 479px) {
                .genome-section {
                    width: 50%;
                }

                .gene-section {
                    width: 50%;
                }

                .publications-section {
                    width: 100%;
                }
            }

            @include media ("<=tinysmall") {
                .genome-section {
                    width: 100%;
                    padding: 0;
                }

                .gene-section {
                    width: 100%;
                    margin: $container-inside-padding 0 0 0;
                    padding: 0;
                }

                .publications-section {
                    width: 100%;
                    margin: $container-inside-padding 0 0 0;
                    padding: 0;
                }
            }
        }
    }

    &__information-section {
        max-width: 950px;
        margin: 0 auto;
        padding: 0 $container-inside-padding;

        @include media ("<=medium") {
            padding: 0 $mobile-container-padding;
        }

        .search-section-title {
            font-size: calc-rem(24);
            color: $dodger-blue;
            margin: 0;
            line-height: 1.4;
            font-weight: 500;
        }

        .search-section-information {
            font-size: calc-rem(18);
            line-height: 26px;
            margin: 10px 0 0 0;
        }
    }

    &__graph-section {
        @extend %border-item;
        width: 80%;
        margin: 50px auto 0;

        > div {
            margin: 0 auto;
            padding-right: 80%;
        }
    }

    .related-container {
        &__title {
            color: $dodger-blue;
        }
    }

    &__related-articles-container {
        margin: 50px 0 0;

        .related-article-section {
            margin: $container-inside-padding 0 0;
            text-align: left;
            display: flex;
            flex-flow: wrap;
        }

        .related-articles {
            padding: 0 $mobile-container-padding 0;
            margin-bottom: $mobile-container-padding;
            flex-basis: 50%;

            @include media ("<=small") {
                flex-basis: 100%;
                padding: 0;
            }
        }

        .article {
            font-size: calc-rem(16);
            color: $melrose;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: 10px;
            display: block;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
