// -----------------------------------------------------------------------------
// This file contains styles that are specific to the HeroCTAButtons component
// -----------------------------------------------------------------------------

.hero-buttons {
    &__container {
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
        text-align: right;
        padding: $container-inside-padding $mobile-container-padding 0;

        @include media ("<=small") {
            .btn {
                display: none;
            }
        }
    }
}
