.back-button {
  button {
    text-transform: none;
    font-size: calc-rem(20);
    font-family: $text-font-stack;
    width: 39px;
    height: 24px;
    text-decoration: none;
    padding-bottom: 2em;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }
}