// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------





/// Regular font family
/// @type List
$heading-font-stack: 'futura-pt', 'Helvetica', Arial, Helvetica, sans-serif !default;
$text-font-stack: $heading-font-stack;

/// Code (monospace) font family
/// @type List

$base-font-size: 16 !default;

$white: #ffffff;
$black: #000000;

// Color palette
$brand-color: #1B1D26;
$page-background: #FFFFFF;
$brand-color-light: #2887bf;
$brand-pink-colour: #ff0096;
$medium-red-violet: #bc2c81;
$turquoise-color: #08c0c9;
$dark-turquoise: #1c847c;
$medium-turquoise: #31cdc1; //
$light-green-1: #18c6b4;
$green: #1dc06e;
$orange: #FFAF4B;
$dim-grey: #656565;
$light-sea-green: #3ebab2;
$scandal: #afdedb;
$monte-carlo: #7bbab5;
$fountain-blue: #67bbb6;
$foam: #CBF1EF;
$gainsboro: #DDDDDD;
$darker-grey: #BBBBBB;
$dark-gray: #a4a4a4;
$dark-blue: #1B1D26;
$white-smoke: #F6F6F6;
$light-orange: #FFA800;
$floral-white: #FFF9EE;
$flower-yellow: #FDD21D;

$background-color: #ffffff;
$background-alternative-colour: #f8f8f8;
$dashboard-link-colour: #7087aa;
$header-color: #667284;
$text-color: #1B1D26;
$secondary-text-color: #ffa800;
$tertiary-text-colour: #3d4d65;
$form-header-text-color: #1B1D26;
$border-color: #cad3df;
$error-red-color: #e10d1e;
$feeds-border-color: #ff7C00;
$button-colour: #1B1D26;
$yellow-button-colour: #FBD4A8;
$link-colour: #F3BF84;
$pale-orange: #fda880;

$payment: #d0d8e2;
$payment-active: darken($payment, 30%);

$good-color: #459B64;
$avg-color: #69ACE9;
$bad-color: #F0975B;


$good-text-color: #459B64;
$avg-text-color: #FFCF00;
$bad-text-color: #F0975B;


$skyblue-java-color: #1fc7df;
$aqua-spring-light: #f1fbfa;
$silver-dark: #b9b9b9;
$melrose: #9db0ff;
$dodger-blue: #5B7BFF;
$indigo: #445CBF;
$medium-gray: #7B7B7B;
$overlay-title-colour: #7d96ff;

/// Container's maximum width
/// @type Length
$container-padding: 80px;
$container-inside-padding: 29px;
$mobile-container-padding: 16px;
$max-width: 1180px !default;
$block-max-width: 1028px !default;
$block-text-max-width: 700px !default;

$intervenable-affect-indicator-know-more-background: #FF9EAF;
$intervenable-fun-fact-background: #FEF4F6;
$intervenable-generic-text-color: #444444;
$intervenable-title-color: #ff5d79;

$timeline-icon-complete-border-colour: #D8DFFF;
$account-link-background-color: #EEEEEE;
$nav-link-color: #1B1D26;

$genetic-you-color: #5CD0C5;
$you-take-a-look-overlay-color: #f6f7ff;

$step-number-colour: #fda880;

$new-good-color: #9374FF;
$new-normal-color: #5B7BFF;
$new-bad-color: #2CB5FF;

$modal-padding: 30px;

/// Breakpoints map
$breakpoints: (
    'tiny': 319px,
    'tinysmall': 479px,
    'small': 639px,
    'medium': 767px,
    'large': 1023px,
    'xlarge': 1199px) !default;

// 325px is computed height for footer (240px) and header (85px) combined
$document-height: calc(100vh - 325px);

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
