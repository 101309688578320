.order-cancel-modal {
    font-size: 16px;
    text-align: center;
    max-width: 430px;

    &__title {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
    }

    &__text {
        white-space: pre-wrap;
        text-align: left;

        a {
            color: #5B7BFF;
            cursor: pointer;
        }
    }

    &__buttons {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        button {
            width: 100%;

            &:not(:first-child) {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

    .loader-container {
        min-height: unset;
        min-width: 300px;
    }
}
