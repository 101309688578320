.order-status-small {
    font-size: 16px;
    background: $white;
    box-shadow: 0 3px 15px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
    color: $text-color;
    padding: 20px;
    margin: 10px;
    display: flex;
    justify-content: space-between;

    &__title {
        font-size: 16px;
        font-weight: 500;
        color: #1B1D26;
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .arrow {
            margin-left: 10px;
        }
    }

    .progress-step-text__title {
        font-size: 28px;
    }

    .progress-steps {

        &__container {
            width: 120px;
        }

        &__step {
            &::before {
                top: 14px;
            }

            .progress-steps__step-icon {
                font-size: 30px;
            }
        }
    }
}
