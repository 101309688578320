// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  height: 100%;
}

body, #loggedout-react-root {
  height: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a, span.linkSpan {
  color: $brand-color;
  text-decoration: underline;

  @include on-event {
    color: #000;
  }

  &.brand-pink {
      color: $brand-pink-colour;
  }
}

img {
    max-width: 100%;
}

/*
  Webkit doesnt auto show scrolbars on overflow auto so include this to always show them..
  Side effect is it styles the browser chrome bar as well.. but this is the default style anyway
*/
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
