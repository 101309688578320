
.progress-card {
    &__container {
        display: flex;
        overflow-x: auto;
        padding: 10px;
    }

    &__card {
        flex-shrink: 0;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 3px 7.5px rgba(0,0,0,0.1);
        width: 370px;
        padding: 15px 25px 25px;
        font-size: 16px;
        line-height: 18px;
        border-radius: 10px;
        margin-right: 20px;

        hr {
            margin: 20px 0;
        }

        label {
            display: block;
            margin-bottom: 2px;
        }
    }

    &__extra-text {
        margin-top: 20px;

        label {
            font-weight: 500;
        }
    }

    &__next-actions {
        margin-top: 30px;
        font-size: 18px;
        line-height: 23px;

        label {
            font-weight: 500;
            line-height: 25px;
        }

        ul {
            margin: 0;
            padding-left: 15px;
        }
    }
}
