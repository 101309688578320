// -----------------------------------------------------------------------------
// This file contains styles that are specific to the checkout
// -----------------------------------------------------------------------------

.green-highlight {
  color: green;
  font-size: calc-rem(18);
  margin-bottom: 10px;
}

%checkout-header {
  content: '';
  border-bottom: 1px solid $border-color;
  position: absolute;
  width: calc(100% + 58px);
  left: -$container-inside-padding;
  bottom: 0;
}

.checkout-section {
  &.default-theme-background {
    background-color: $background-alternative-colour;
  }

  &__container {
    padding: $container-padding 0;
    max-width: $block-max-width;
    margin: auto;

    @include media('<=medium') {
      padding: $container-padding/2 $mobile-container-padding;
    }

    .form__error {
      text-align: right;

      &--left,
      &--start {
        text-align: left;
      }
    }
  }
}

.checkout-menu {
  margin: 0 auto $container-padding auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 720px;

  @include media('<=medium') {
    margin-bottom: $container-padding/2;
  }

  &__divider {
    color: #c5c5c5;
    font-size: calc-rem(25);

    @include media('<=medium') {
      font-size: calc-rem(15);
    }
  }

  li {
    //flex: 1;
    text-align: center;
    //padding-left: 10px;

    a {
      font-size: calc-rem(25);
      color: #c5c5c5;
      display: block;
      text-decoration: none;

      @include media('<=medium') {
        font-size: calc-rem(15);
      }

      &.active {
        color: $form-header-text-color;
      }
      @include on-event {
        color: $form-header-text-color;
      }
    }
  }
}

.checkout-options {
  max-width: 875px;
  margin: auto;
  padding: $container-inside-padding;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  margin-bottom: $container-padding/2;
  align-items: center;

  @include media('<=medium') {
    min-height: auto;
    padding: 0;
  }

  @include media('<=medium') {
    flex-direction: column;
  }

  &__col {
    text-align: center;
    padding: 0 $container-inside-padding;
    position: relative;
    min-height: 112px;

    @include media('<=medium') {
      min-height: auto;
      padding: $container-inside-padding;
    }

    &--how-pay {
      text-align: left;
    }

    &--quantity {
      padding-top: 30px;
    }

    &:first-child {
      border-right: 1px solid $border-color;
      @include media('<=medium') {
        border-bottom: 1px solid $border-color;
        border-right: none;
      }
    }

    &:last-child {
      border-left: 1px solid $border-color;
      @include media('<=medium') {
        border-top: 1px solid $border-color;
        border-left: none;
      }
    }
  }

  &__quanity-label {
    margin-right: 10px;
  }

  &__quantity-select {
    width: 40px;
    padding: 5px;
    font-size: small;
  }

  &__option-label {
    margin-left: 10px;
  }

  &__image {
    max-width: 100px;
    display: block;
    margin: 0 auto 10px auto;
  }

  &__pay-title {
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__payment-length {
    font-size: calc-rem(13);
    margin-left: 10px;

    @include media('<=medium') {
      display: block;
      margin-left: 26px;
    }
  }

  &__payment-save {
    color: $brand-pink-colour;
    margin-left: 29px;
    font-size: calc-rem(13);

    @include media('<=medium') {
      display: block;
      margin-left: 26px;
    }
  }
}

.checkout {
  &:not(.new-checkout-flow) {
    display: flex;
    align-items: flex-start;
    max-width: 875px;
    margin: auto;

    @include media('<=medium') {
      flex-direction: column;
      align-items: center;
    }
  }
  &.new-checkout-flow {
    margin-top: 10px;
  }

  &__title {
    color: $form-header-text-color;
    font-size: calc-rem(25);
    margin: 0 0 $container-inside-padding 0;
    position: relative;
    padding-bottom: $container-inside-padding;

    &:after {
      @extend %checkout-header;
    }

    &--orderstep {
      float: right;
      font-size: calc-rem(18);
      cursor: pointer;
      @include media('<=medium') {
        margin-top: 9px;
      }
    }
  }

  &__already-account {
    text-align: right;
  }

  &__form-col {
    background-color: #fff;

    &.order-summary {
      max-width: 95%;
      margin: auto;
      @include media('<=medium') {
        max-width: 100%;
      }

      .loader-container {
        min-height: auto;
      }
    }

    &:not(.order-summary) {
      flex: calc(60% - #{$container-padding/2});
      margin-right: $container-padding/2;
    }

    @include media('<=medium') {
      &:not(.order-summary) {
        flex: auto;
        width: 100%;
        margin-right: 0;
        margin-bottom: $container-padding/2;
      }
    }
  }

  &__order-col {
    flex: 40%;
    max-width: 370px;
    background-color: #fff;

    @include media('<=medium') {
      max-width: 100%;
      flex: 100%;
    }
  }

  &__form-col,
  &__order-col {
    padding: $container-inside-padding;
    border-radius: 5px;
  }

  &__order-title {
  }

  &__share-container {
    margin: $container-inside-padding 0;
  }

  &__btn {
    @include media('<=medium') {
      width: 100%;
    }
  }
}

.checkout-form {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  select {
    width: 100%;
  }

  input[type='tel'] {
    margin-top: $container-inside-padding;
  }

  &__mobile-country {
    width: 200px;

    select {
      font-size: 13px;
      line-height: 17px;
    }
  }

  &__cards {
    max-width: 130px;
    vertical-align: middle;
    margin-left: 20px;

    @include media('<=tinysmall') {
      max-width: 90px;
      margin-top: 5px;
      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

.coupon-form {
  &__link {
    font-size: calc-rem(17);
  }

  label {
    display: inline;
    margin-right: 10px;
  }

  input[type='text'] {
    width: 50%;
    margin-right: 10px;

    @include media('<=tinysmall') {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  button {
    @include media('<=tinysmall') {
      width: 100%;
    }
  }
}

.yearly-toggle {
  text-align: center;

  &__title {
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__monthly {
    margin-right: 10px;
  }

  &__months-free {
    font-size: calc-rem(12);
    min-height: 20px;
    margin-bottom: 10px;
  }
}

.cards--mobile {
  text-align: center;
  margin-top: 10px;
  @include media('>tinysmall') {
    display: none;
  }
}

.order-container {
  display: grid;
  align-content: start;
  grid-template-columns: 1fr 1fr 1fr;
  //justify-items: center;
  //align-items: center;
  width: 100%;
  position: relative;

  @include media('<=tinysmall') {
    grid-template-columns: 0 1fr 1fr;
  }

  &__cell {
    padding: 10px;

    &--cards {
      text-align: center;
      margin-top: 20px;

      @include media('<=tinysmall') {
        img {
          display: none;
        }
      }
    }
  }

  &__product-image {
    max-width: 80px;
    @include media('<=tinysmall') {
      display: none;
    }
  }

  &__border-bottom {
    border-bottom: 1px solid $border-color;
    width: 100%;
  }

  &__cell-2 {
    //grid-column: 2;
    grid-column: 1 / span 2;
  }

  .right {
    justify-self: end;
    text-align: right;
    @include media('<=tinysmall') {
      vertical-align: top;
      float: right;
    }
  }

  .left {
    justify-self: start;
    text-align: left;
  }

  &__price {
    color: $text-color;
  }
  &__price-next {
    font-size: calc-rem(14);
  }
  &__big {
    align-self: start;
    font-size: calc-rem(25);
    @include media('<=tinysmall') {
      font-size: 1.125rem;
    }
  }
  &__frequency-text {
    font-size: calc-rem(14);
  }

  &__quantity {
    display: inline-block;
    //margin-left: 10px;

    i {
      right: 0;
      font-size: small;
      top: 6px;
    }
    @include media('<=tinysmall') {
      //margin-left:10px;
    }
  }

  &__change-link {
    font-size: calc-rem(13);
  }

  /*
    @include media("<=tinysmall") {
        &.latest-order-container {
            display:inline-block;
            position:relative;
            padding-bottom:15px;
        }
    }
    */
  &__sales-tax {
    font-style: italic;
    font-weight: 500;
    font-size: calc-rem(15);
  }
}

.checkout.new-checkout-flow {
  .checkout {
    &__title {
      margin: 0;
      padding: 0;
      &:after {
        display: none;
      }
    }
  }
  .form__label--no-transition {
    font-size: calc-rem(15);
    font-weight: 300;
  }
  .card-wrapper {
    width: 100%;
    position: relative;
    margin: 25px 0 0 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;

    &.slide {
      max-height: 1600px;
      transition: max-height 2s;
    }

    &:before {
      @extend %checkout-header;
      border-bottom: none;
      border-top: 1px solid $border-color;
      bottom: unset;
      top: 0;
    }

    .card-container {
      border-bottom: 1px solid $border-color;
      padding: 10px 0;
      display: flex;

      &:last-child {
        border-bottom: none;
      }

      &:before {
        @extend %checkout-header;
        border-bottom: none;
        border-top: 1px solid $border-color;
        bottom: unset;
        top: 0;
      }

      &__field-information {
        font-size: calc-rem(16);
        @include media('<=tinysmall') {
          margin-bottom: 20px;
        }
      }
      &__card-images {
        img {
          display: block;
          margin-bottom: 20px;
          @include media('<=tinysmall') {
            display: inline;
          }
        }
      }
      &__card-display {
        width: 40%;
        padding-right: 20px;
        padding-left: 2px; //Seems to be cutting checkboxes off
        div:first-child {
          font-weight: 500;
          margin-bottom: 20px;
        }
      }
      &__card-info {
        width: 60%;
        //padding-left: 20px;
        padding-top: 43px;

        @include media('<=tinysmall') {
          padding-top: 10px;
        }

        .address-search-container {
          background-color: #f5f5f5;
          padding: 10px;
          margin-bottom: 20px;

          .address-caption {
            padding-bottom: 20px;
          }
        }

        .input-label-container {
          position: relative;

          .link-container {
            a {
              cursor: pointer;
              padding-left: 20px;
              text-decoration-line: underline;
            }
          }

          .primary-text-input-button {
            background-color: $brand-color;
            color: #fff;
            position: absolute;
            right: 0;
            top: 10px;
            height: 40px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            display: flex;
            width: 65px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &.mobile-input {
            margin-top: $mobile-container-padding;

            .PhoneInputInput {
              background-color: $background-alternative-colour;
              border-radius: 5px;
              border: 1px solid #cad3df;
            }
          }
        }

        .react-tel-input input[type='tel'] {
          width: 100%;
        }

        &:not(.card-details-section) {
          input:focus ~ .form__label,
          .form__label.valid {
            top: -17px;
            left: 0;
            font-size: calc-rem(15);
            opacity: 1;
            font-weight: 300;
          }

          select,
          input:not([type='radio']) {
            margin-top: 10px;
          }

          .form__select-wrapper {
            i {
              top: calc(50% - 10px);
            }
          }

          .form__label {
            position: absolute;
            pointer-events: none;
            left: 15px;
            top: 16px;
            transition: 0.2s all;

            &.mobile-label {
              left: 70px;
              top: 5px;
            }
          }
        }

        .phone-input__container:focus-within ~ label.mobile-label,
        label.form__label.mobile-label.valid {
          top: -28px;
          left: 0;
          font-size: calc-rem(15);
          opacity: 1;
          font-weight: 300;
        }

        .PhoneInput {
          input,
          select {
            margin-top: 0;
            height: 100%;
          }
        }
        .travel-info-text {
          @include media('<=medium') {
            margin-bottom: 20px;
          }
        }
      }

      &.billing-address-container {
        border-bottom: none;
      }

      .billing-address-card {
        //border: 1px solid $border-color;
        //padding: 20px;
      }

      @include media('<=tinysmall') {
        display: block;

        &__card-display,
        &__card-info {
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }

  .button-container {
    display: flex;

    &__card-display {
      width: 40%;
      padding-right: 20px;
      padding-left: 2px; //Seems to be cutting checkboxes off
      div:first-child {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    &__card-info {
      width: 60%;

      @include media('<=tinysmall') {
        padding-top: 10px;
      }
    }
  }
}

.tube-barcode-tooltip {
  color: #fff;

  img {
    display: block;
    max-width: 200px;
    margin: auto;
    margin-top: 20px;
  }
}

.password-container {
  width: 60%;
  @include media('<=tinysmall') {
    width: 100%;
  }

  &__account-create {
    margin-top: 5px;
  }
}
.eye-open {
  position: absolute;
  right: 10px;
  bottom: 31px;
}

.price-container {
  margin-bottom: 8px;
}
.form__row > input.password-field {
  padding-right: 40px;
  @include media('<=tinysmall') {
    width: 100%;
  }
}
.create-account {
  @include media('<=tinysmall') {
    width: 100%;
  }
}

.message-container {
  grid-column: 2 / span 2;
}
