.order-status {

    &__card {
        flex-shrink: 0;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 3px 7.5px rgba(0,0,0,0.1);
        padding: 30px;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 56px;
        grid-row-gap: 20px;

        @include media("<=small") {
            grid-template-columns: 1fr;
        }

        .text-bold {
            font-weight: 500;
            line-height: 20px;
        }

        .text-large {
            font-size: 18px;
        }

        .mt-10 {
            margin-top: 10px;
        }

        &.cancelled {
            background: #F4F4F4;
        }

        .cancellation-text {
            margin-top: 50px;
            font-size: calc-rem(16);;
            color: #1B1D26;
        }

        .refund {
            line-height: 23px;

            a {
              cursor: pointer;
            }
        }
    }

    &__bundle-parent {
        width: 100%;
        height: 38px;
        background: #404E80;
        border-radius: 2px;
        margin: 10px 0;
        font-size: calc-rem(20);
        color: #FFFFFF;
        font-weight: 500;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            color: #FFFFFF;
            text-transform: initial;
        }
    }

    &__caption {
        color: #777D95;
    }

    &__next-actions {
        font-size: 18px;
        line-height: 23px;

        label {
            font-weight: 500;
            line-height: 25px;
        }

        ul {
            margin: 0;
            padding-left: 15px;
        }
    }
}
