// -----------------------------------------------------------------------------
// This file contains styles that are specific to the biological age section
// -----------------------------------------------------------------------------

.indicator-section {
  background-color: white;
}

.result-hero {
  @extend .dashboard-hero;
  height: auto;
  max-width: 910px;
  margin: auto;

  &__current-test-date {
    font-size: calc-rem(16);
    color: $text-color;
    text-align: right;
    margin: 0 auto;
    max-width: 1180px;
    padding: 18px $mobile-container-padding 0;
  }

  &__text-container {
    @extend .dashboard-hero__text-container;
    max-width: unset;
    margin: 50px 0 0 0;
    width: 100%;
    padding: 0;

    @include media('<=tinysmall') {
      margin: 40px 0 0 0;
    }

    .hero-image {
      text-align: center;
      margin: 0 0 $mobile-container-padding;

      img {
        max-width: 50px;
        max-height: 40px;
      }
    }
  }

  &__information-section {
    padding: 0 29px;

    @include media('<=small') {
      padding: 0 $mobile-container-padding;
    }

    .result-hero__title {
      @extend .dashboard-hero__title;
      font-size: calc-rem(36);
      color: $text-color;
      line-height: calc-rem(40);
      margin: 0;
      font-weight: 500;
      text-align: left;

      @include media('<=tinysmall') {
        font-size: calc-rem(28);
        line-height: calc-rem(35);
      }
    }
  }

  &__title {
    @extend .dashboard-hero__title;
    font-size: calc-rem(36);
    color: $text-color;
    line-height: calc-rem(40);
    margin: 0;
    font-weight: 500;

    @include media('<=tinysmall') {
      font-size: calc-rem(28);
      line-height: calc-rem(35);
    }
  }

  &__title-information {
    line-height: calc-rem(40);
    max-width: 800px;
    margin: 10px auto 0;
    width: 100%;
    font-size: calc-rem(24);
    background: $background-alternative-colour;
    padding: $mobile-container-padding 20px;
    border-radius: 10px;

    &.indicator-information {
      font-size: calc-rem(18);
      line-height: calc-rem(30);
    }

    @include media('<=tinysmall') {
      font-size: calc-rem(18);
      line-height: calc-rem(30);
    }
  }

  &__biological-description {
    line-height: calc-rem(28);
    width: 100%;
    font-size: calc-rem(20);
    background: $background-alternative-colour;
    padding: 15px 37px;
    border-radius: 10px;
    margin: 0 0 22px;
    position: relative;

    @include media('<=tinysmall') {
      font-size: calc-rem(18);
      line-height: calc-rem(30);
    }

    &:before {
      content: ' ';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid $background-alternative-colour;
      position: absolute;
      bottom: -15px;
      left: calc(50% - 10px);
    }
  }

  &__description {
    max-width: 710px;
    margin: 10px auto 0;
    width: 100%;
    font-size: calc-rem(20);
    text-align: center;
    line-height: calc-rem(28);

    &.biological-description {
      max-width: unset;
      margin: $container-padding/2 0 0;
    }

    @include media('<=tinysmall') {
      font-size: calc-rem(18);
      line-height: calc-rem(30);
      text-align: left;
    }
  }

  &__menu {
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #d8dfff;
    box-shadow: 0 2px 8px 0 rgba(184, 184, 184, 0.5);

    > li {
      padding: 12px 35px;
      margin: 0 10px;

      @include media('<=small') {
        border-radius: 5px;
        padding: 10px 15px;
        margin: 5px;
      }

      a {
        opacity: 0.5;
        font-size: calc-rem(20);
        color: #5b7bff;

        @include media('<=small') {
          font-size: calc-rem(17);
        }
        &.active {
          opacity: 1;
          font-weight: 500;
          color: #5b7bff;
        }

        &:active,
        &:hover,
        &:focus {
          color: #5b7bff;
        }

        &.active:before {
          display: none;
        }
      }
    }
  }

  &__how-works-section {
    max-width: 1110px;
    margin: 0 auto;
    text-align: right;
    position: relative;
    padding: 40px 50px;

    @include media('<=medium') {
      padding: 40px $container-inside-padding;
    }
  }

  &__result-container {
    margin: $container-inside-padding 0;
    position: relative;

    &.previous-results {
      align-items: center;
    }

    .icon-right-arrow {
      font-size: calc-rem(20);
      display: inline-block;
      margin-top: 10px;
      color: #a3a3a3;
      cursor: pointer;

      &.up-arrow {
        transform: rotate(-90deg);
        color: #F0975B;
      }

      &.down-arrow {
        color: #459B64;
        transform: rotate(90deg);
      }

      &.previous-icon {
        margin: 0;
      }
    }
  }

  &__arrow-section {
    position: absolute;
    top: 0;
    left: calc(50% + 96px);

    &.previous-section {
      left: calc(50% + 95px);
      top: calc(50% - 22px);
    }

    @include media('<=small') {
      left: calc(50% + 60px);

      &.previous-section {
        left: calc(50% + 60px);
        top: calc(50% - 22px);
      }
    }
  }

  &__small-section {
    position: absolute;
    top: 0;
    left: calc(50% + 95px);

    @include media('<=small') {
      left: calc(50% + 60px);
    }
  }

  &__result-section {
    width: 180px;
    height: 180px;
    border: 11px solid $dodger-blue;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 20px auto 0;

    .result-title {
      font-size: calc-rem(80);
      margin: 0;
      line-height: 1;
    }

    .result-unit {
      font-size: calc-rem(22);
      margin: 0;
      line-height: 1;
      text-transform: uppercase;
    }

    &.small {
      width: 70px;
      height: 70px;
      border: 4px solid #F0975B;
      margin: 0;
      cursor: pointer;

      &.orange {
        border-color: #F0975B;
      }

      &.green {
        border-color: #459B64;
      }

      &.grey {
        border-color: #a3a3a3;
      }

      .result-title {
        font-size: calc-rem(30);
      }

      .result-unit {
        font-size: calc-rem(9);
      }

      @include media('<=small') {
        width: 55px;
        height: 55px;
        border-width: 3px;

        .result-title {
          font-size: calc-rem(24);
        }

        .result-unit {
          font-size: calc-rem(7);
        }
      }
    }

    @include media('<=small') {
      width: 110px;
      height: 110px;
      border-width: 6px;

      .result-title {
        font-size: calc-rem(48);
      }

      .result-unit {
        font-size: calc-rem(15);
      }
    }
  }

  &__healthy-range {
    max-width: 800px;
    padding: 40px 0 $container-inside-padding;
    margin: 50px auto 0;
    position: relative;

    .label-container {
      display: flex;
      justify-content: space-between;

      .healthy-range-label {
        font-size: calc-rem(14);
        letter-spacing: 0.15px;
        margin: 10px 0 0;
        text-align: left;

        p {
          margin: 0;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &__range {
    height: 11px;
    width: 100%;
    position: relative;

    .range {
      border-radius: 5px;
      display: inline-block;
      width: 50%;
      height: 11px;
      vertical-align: top;
      background-color: #e1e1e1;
      opacity: 0.2;

      &.healthy-range {
        background-color: $good-text-color;
      }

      &.normal-range {
        background-color: $avg-text-color;
      }

      &.unhealthy-range {
        background-color: $bad-text-color;
      }

      &.active {
        opacity: 1;
      }
    }

    .result-meta-container {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 14px solid #444444;
      top: -20px;
    }
  }

  &__result-status {
    font-size: calc-rem(21);
    font-weight: 500;
    color: $good-text-color;
    text-align: center;
    line-height: 30px;
    text-transform: uppercase;

    &.good {
      color: $good-text-color;
    }

    &.normal {
      color: $avg-text-color;
    }

    &.bad {
      color: $bad-text-color;
    }
  }

  &__intro {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $container-inside-padding 0 0;

    .result-hero-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 0 22px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);

      img {
        max-width: 36px;
        height: 34px;
      }
    }

    .result-intro-description {
      font-size: calc-rem(20);
      line-height: calc-rem(30);
      margin: 0;
      text-align: left;
      font-weight: 500;

      @include media('<=small') {
        width: calc(100% - 82px);
        font-size: calc-rem(18);
        line-height: calc-rem(26);
        word-break: break-all;
      }
    }
  }

  &__result-status-section {
    background-color: $background-alternative-colour;
    border-radius: 6px;
    padding: 8px $container-inside-padding;
    margin: $container-inside-padding 0;
  }
}

.result-hero-text-container {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: $container-inside-padding 0;

  @include media('<=small') {
    padding: $container-inside-padding 0;
  }

  .result-hero-inside-container {
    //width: 100%;
    max-width: 740px;
    margin: auto;
    padding: 0 $container-inside-padding;
    position: relative;
    overflow: hidden;

    .summary-text {
      font-size: calc-rem(20);
      line-height: calc-rem(30);
      margin: 0;
    }

    .talk-to-team {
      font-size: calc-rem(20);
      text-transform: uppercase;
      margin: 50px 0 0;
      color: $text-color;
      display: inline-block;
      border-bottom: 2px solid transparent;

      &:hover,
      &.active,
      &:focus {
        border-bottom: 2px solid #5b7bff;
      }

      i {
        color: $dodger-blue;
        margin-left: 10px;
      }
    }

    .hero-section-gradient {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      height: 276px;
      width: 100%;
      opacity: 0.4;

      @include media('<=small') {
        height: 250px;
        width: 170vw;
        left: -25vw;
      }

      .color-gradient {
        &.good {
          stop-color: $good-color;
        }

        &.bad {
          stop-color: $bad-color;
        }

        &.normal {
          stop-color: $avg-color;
        }
      }
    }

    @include media('<=small') {
      padding: $container-inside-padding;
    }
  }

  .title-information {
    font-size: calc-rem(22);
    font-weight: 700;
    margin: 20px 0;
  }

  .title-test-date {
    font-size: calc-rem(20);
    font-style: italic;
    margin: 20px 0;
  }

  .sub-title {
    //margin: 43px 0 0 0;
    margin: 0;
    font-size: calc-rem(24);
    text-align: center;
    position: relative;
    margin-bottom: 15px;

    &.good {
      color: $good-color;
    }

    &.bad {
      color: $bad-color;
    }

    &.normal {
      color: $avg-color;
    }
  }

  .sub-title-information {
    line-height: 1.4;
    margin: 0 0 120px 0;

    @include media('<=tinysmall') {
      margin: 0 0 170px 0;
    }
  }
}

.biological-timeline {
  @extend .result-timeline;
  margin: 0;
  align-items: center;
  padding: 13px 0 51px;

  .good {
    background: $good-text-color;
  }

  .bad {
    background: $bad-text-color;
  }

  .normal {
    background: $avg-text-color;
  }

  &__item {
    @extend .result-timeline__item;
    padding: 0 10px;
    color: #f0f0f0;
    display: none;
    z-index: 0;

    &:before {
      display: none;
    }
  }

  &__item--current {
    display: block;
  }

  &__icon {
    @extend .result-timeline__icon;
    background: url(../../img/indicator/circle_icon.png) center center no-repeat;
  }

  &__current-item {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    display: inline-block;
    position: relative;

    .item-information {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      &.good {
        color: $good-text-color;
      }

      &.bad {
        color: $bad-text-color;
      }

      &.normal {
        color: $avg-text-color;
      }

      > p {
        font-size: 60px;
        margin: 0;
        line-height: 55px;
        font-weight: 500;
      }

      > span {
        font-size: 25px;
        line-height: 1;
      }
    }

    .inner-circle {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      opacity: 0.4;

      &.good {
        background: $good-color;
      }

      &.bad {
        background: $bad-color;
      }

      &.normal {
        background: $avg-color;
      }
    }
  }
}

.indicator-section-title {
  margin: 0 0 20px;
  font-size: calc-rem(36);
  color: $text-color;
  line-height: calc-rem(40);
  font-weight: 500;

  sup {
    color: $dodger-blue;
  }

  @include media('<=medium') {
    font-size: calc-rem(22);
  }

  &.inside-title {
    font-size: calc-rem(22);
    margin: 0;

    @include media('<=medium') {
      font-size: calc-rem(20);
    }
  }
}

.indicator-section-description {
  text-align: left;
  margin: 0 0 $container-inside-padding;
  font-size: calc-rem(18);
}

.indicator-section-information {
  text-align: left;
  font-size: calc-rem(20);
  line-height: 28px;

  &--analysis {
    border-top: 1px solid $border-color;
    padding-top: $container-inside-padding;
  }

  &.good {
    color: $good-color;
  }

  &.bad {
    color: $bad-color;
  }

  &.normal {
    color: $avg-color;
  }
}

.biological-age {
  &__container {
    margin: 0 auto;
    width: 100%;
  }

  &__section-information {
    max-width: 700px;
    margin: 0 auto;
  }

  &__sub-section-information {
    border-radius: 6px;
    padding: $container-inside-padding;

    &.good {
      border: 1px solid $good-text-color;
      color: $good-text-color;
      background-color: rgba(135, 192, 76, 0.1);
    }

    &.bad {
      border: 1px solid $bad-text-color;
      color: $bad-text-color;
      background-color: rgba(251, 205, 115, 0.1);
    }

    &.normal {
      border: 1px solid $avg-text-color;
      color: $avg-text-color;
      background-color: rgba(160, 197, 231, 0.1);
    }

    .indicator-image {
      margin-right: $container-inside-padding;
      width: 90px;

      .good {
        fill: $good-text-color;
      }

      .bad {
        fill: $bad-text-color;
      }

      .normal {
        fill: $avg-text-color;
      }
    }

    .section-sub-title {
      font-size: calc-rem(20);
      margin: 0;
      line-height: calc-rem(26);
      font-weight: 500;
    }

    .section-sub-information {
      font-size: calc-rem(16);
      color: #838383;
      margin: 5px 0 0 0;
      line-height: 1.3;
    }

    @include media('<=medium') {
      padding: $mobile-container-padding;
    }
  }

  &__fun-facts-container {
    max-width: 1180px;
    padding: 0 35px;

    border-radius: 20px;
    margin: 50px auto 0 auto;

    @include media('<=medium') {
      padding: 0 $mobile-container-padding;
    }

    .fun-fact-container {
      background: #f9f2f0;
    }

    .fun-fact-container__header {
      color: $intervenable-title-color;
    }
  }

  &__graph-section {
    text-align: center;
    width: 100%;
    margin: 20px 0 0 0;
    padding: 0 $container-inside-padding 20px;

    &.no-padding {
      padding: 0 0 20px;
    }

    &.reducer-description-margin {
      .indicator-section-description {
        margin: 0 0 10px;
      }
    }

    @include media('<=small') {
      .btn.full-width {
        width: 100%;
      }
    }
  }

  &__factors-container {
    width: 100%;
    margin-top: $container-padding;
    text-align: center;

    @include media('<=small') {
      margin-bottom: 25px;
    }
  }

  &__factors-section {
    margin-top: 40px;

    &__graph {
      max-width: 800px;
      position: relative;
      margin: auto;
      height: 350px;
      min-height: 350px;
    }
  }

  &__single-factor {
    padding: $mobile-container-padding 20px;
    display: inline-block;
    cursor: pointer;

    @include media('<=small') {
      padding: $mobile-container-padding;
    }

    .factor-icon {
      background: #d8d8d8;
      border: 1px solid #979797;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      margin: 0 auto;
    }

    .factor-name {
      font-size: calc-rem(18);
      color: #000000;
      text-align: center;
      margin: 5px 0 15px;
      line-height: 26px;
    }

    .factor-information {
      font-size: calc-rem(12);
      color: #878787;
      letter-spacing: 0.8px;
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
    }

    .factor-score {
      font-size: calc-rem(16);
      text-align: center;
      line-height: 20px;
      margin: 0 0 15px 0;
      color: $good-text-color;

      &.good {
        color: $good-text-color;
      }

      &.bad {
        color: $bad-text-color;
      }

      &.normal {
        color: $avg-text-color;
      }
    }

    .btn {
      width: 110px;
      height: 30px;
      font-size: calc-rem(11);
      letter-spacing: 0.92px;
      text-align: center;
      line-height: 30px;
      padding: 0;
    }
  }

  &__age-section {
    margin: $container-padding 0 0 0;
  }

  &__how-works {
    padding: 0 0 $container-inside-padding;
    margin: $container-inside-padding 0 0;

    .how-works-section {
      background-color: $background-alternative-colour;

      &__btn-container {
        @include media('<=medium') {
          text-align: center;
        }
      }
    }
  }

  &__line-graph {
    width: 90%;
    margin: 100px auto;

    .age-bar {
      width: 100%;
      background-color: #efefef;
      height: 18px;
      border-radius: 10px;
      position: relative;
    }

    .chronological-section {
      position: absolute;
      top: -65px;
      width: 100px;

      &:after {
        content: '';
        height: 20px;
        width: 1px;
        border-right: 1px solid #222222;
        position: absolute;
        top: 45px;
      }

      > div {
        position: absolute;
        left: -40%;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #222222;
        letter-spacing: 0.14px;
        text-align: center;
        line-height: 18px;

        &:first-child {
          font-size: 18px;
          letter-spacing: 0.22px;
        }
      }

      &.biological {
        top: 50px;

        &:after {
          content: '';
          height: 26px;
          width: 26px;
          position: absolute;
          left: -13px;
          top: -54px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid #222222;
        }
      }
    }

    .age-section {
      width: 30%;
      height: 45px;
      text-align: center;
      position: absolute;
      border-left: 1px solid #9e9e9e;
      border-right: 1px solid #9e9e9e;
      top: -14px;

      .bar {
        width: 100%;
        height: 18px;
        background-color: #d8d8d8;
        position: absolute;
        top: 14px;
      }

      > p {
        margin: 10px 0 0 0;
        font-size: 16px;
        color: #6cc786;
        line-height: 1.4;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &__related-wrapper {
    margin: $container-padding auto 0;
    max-width: 910px;
    padding: 0 $container-inside-padding;

    .biological-age-related-container {
      .related-container {
        background-color: $white;
      }
    }
  }

  &__improve-your-health {
    text-align: center;
  }

  &__about-section {
    text-align: center;
    width: 100%;
    background-color: #ffffff;

    margin: 50px auto 0;
    max-width: 910px;
    padding: 0 $container-inside-padding;
  }

  &__line-graph-section {
    margin: 0 auto;
    max-width: 1110px;
    padding: $container-inside-padding 50px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }
  }

  &__pie-chart-section {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: $container-inside-padding 36px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }

    .pie-chart-container {
      width: 100%;
      height: 300px;
      margin: 0 auto;
      position: relative;
    }

    .pie-label {
      font-size: calc-rem(30);
      fill: $text-color;
      text-align: center;
      width: 150px;
      margin: 0 auto;
      line-height: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .section-active-shape {
      @include media('<=tinysmall') {
        display: none;
      }
    }

    .pie-slice-label {
      line-height: 1;
      margin: 10px 0 0;
      text-align: center;
      font-size: calc-rem(20);
      font-weight: 500;
      width: 100%;

      @include media('<=medium') {
        font-size: calc-rem(18);
      }
    }
  }

  &__recommendations-container {
    background: $background-alternative-colour;
    width: 100%;
  }

  &__recommendations-section {
    max-width: 1110px;
    margin: 0 auto;
    padding: $container-inside-padding * 2 50px;

    @include media('<=medium') {
      padding: $container-inside-padding * 2 $container-inside-padding;
    }
  }

  &__recommendations {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    margin: $container-inside-padding 0 0;

    .recommendation-item {
      flex-basis: calc(100% / 3);
      padding: $container-inside-padding $container-inside-padding 0 $container-inside-padding;

      @include media('<=medium') {
        flex-basis: 100%;
        padding: 0;
      }
    }

    .item-image {
      min-height: 80px;
      text-align: center;
      > img {
        max-width: 80px;
        max-height: 80px;
        min-height: 80px;
        width: 100%;
        margin: 0 auto;
      }
    }

    .recommendation-title {
      text-align: center;
      font-size: calc-rem(18);
      color: #000000;
      line-height: calc-rem(26);
      font-weight: 500;
      margin: 10px 0 0 0;

      &.text {
        line-height: calc-rem(20);
        margin: 10px 0 0 0;
        font-size: calc-rem(16);
        font-weight: normal;
        min-height: 250px;

        @include media('<=medium') {
          min-height: unset;
        }
      }
    }

    .recommendation-points {
      padding: $container-inside-padding 0 0 0;
      margin: 0;
      list-style: none;
    }

    .tip {
      font-size: calc-rem(17);
      color: #232323;
      letter-spacing: 0;
      line-height: 1.2;
      margin: 0 0 $mobile-container-padding;
      display: flex;

      > span {
        padding: 5px 0 0 0;
      }

      &:before {
        content: '\2022';
        color: $dodger-blue;
        display: inline-block;
        margin-right: 8px;
        font-size: calc-rem(25);
      }
    }
  }

  &__chromosome-graph-container {
    background-color: $background-alternative-colour;
    width: 100%;
    padding: $container-inside-padding 0;
    text-align: center;
  }

  &__chromosome-graph-section {
    max-width: 900px;
    margin: 0 auto;
    padding: $container-inside-padding;
  }

  &__impact-container {
    max-width: 1110px;
    margin: 0 auto 29px auto;
  }

  &__impact-section {
    margin: $container-inside-padding 0 0;
    text-align: left;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .improvement-section-title {
      border-radius: 10px 10px 0 0;
      background-color: #f2f2f2;
      text-align: left;
      padding: 18px 40px;
      font-weight: 500;
      font-size: calc-rem(22);

      @include media('<=medium') {
        padding: 18px 22px;
        font-size: calc-rem(20);
      }
    }

    .impact-items-container {
      padding: 22px 40px;
      border-radius: 0 0 10px 10px;

      @include media('<=medium') {
        padding: 29px 22px;
      }
    }

    .impact-item {
      padding: $mobile-container-padding 0 0;
      display: flex;
      align-items: center;

      &:first-child {
        padding: 0;
      }

      span {
        font-size: calc-rem(15);
        color: $white;
        line-height: 20px;
        vertical-align: top;
        display: inline-block;
        margin: 0 5px 0 0;

        &.outside {
          margin: 0 0 0 5px;
          color: $text-color;
        }
      }
    }

    .impact-item-image {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 32px;
        max-height: 32px;
        vertical-align: middle;
      }
    }

    .impact-bar-container {
      width: calc(100% - 62px);
      display: flex;
      align-items: center;
    }

    .impact-item-bar {
      display: inline-block;
      height: 11px;
      background-color: #F0975B;
      border-radius: 0 10px 10px 0;
      vertical-align: middle;
      text-align: right;
    }
  }

  &__factors-wrapper {
    max-width: 1140px;
    margin: 0 auto;

    .factors-container {
      width: calc((100% / 3) * 2);
      display: flex;
    }

    .factors-header-section {
      border-radius: 10px 10px 0 0;
      background-color: #f2f2f2;
      text-align: left;
      padding: 18px 22px;
    }

    .factors-tip-section {
      padding: 18px 0;
      &.no-padding {
        padding: 0;
      }
    }

    .featured-information-section {
      padding: 22px;
    }

    .header-title {
      font-size: calc-rem(20);
      font-weight: 500;
      margin: 0;
      width: 50%;
      display: inline-block;

      @include media('<=medium') {
        width: 100%;
        display: block;
        font-size: calc-rem(18);
      }
    }

    .section-title {
      font-size: calc-rem(18);
      font-weight: 500;
      margin: 0 0 10px;
      color: #444444;
      text-align: left;

      @include media('<=medium') {
        font-size: calc-rem(16);
      }
    }

    .section-description {
      font-size: calc-rem(18);
      line-height: 1.4;
      margin: 0;
    }

    .header-description {
      font-size: calc-rem(16);
      line-height: 1.4;
      margin: 0;
      width: 50%;
      display: inline-block;
      padding: 0 0 0 22px;

      @include media('<=medium') {
        width: 100%;
        display: block;
        padding: 10px 0 0;
      }
    }

    .factors-information-section {
      border-radius: 0 0 10px 10px;
      padding: 0 22px 0 0;
      width: 50%;
      display: inline-block;
      vertical-align: top;

      &.right-section {
        padding: 0 0 0 22px;

        .biological-age__statistics-section {
          @include media('<=medium') {
            padding: 25px 0 0;
          }
        }
      }

      .impact-item-image {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;

        img {
          max-width: 32px;
          max-height: 32px;
          vertical-align: middle;
        }
      }

      .factors-graph-section {
        padding: 18px $container-inside-padding;
        background-color: $background-alternative-colour;
        border-radius: 10px;

        &.extra-factors {
          display: flex;
          justify-content: space-between;
        }
      }

      @include media('<=medium') {
        width: 100%;
        padding: 0;

        &.right-section {
          padding: 0;
        }
      }
    }
  }

  &__featured-container {
    width: 100%;
  }

  &__featured-section {
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    margin: 40px 0 0;
    width: 100%;

    .factors-featured-graph {
      margin: 0 0 22px;

      &:last-child {
        margin: 0;
      }

      &--extra {
        margin: 0;

        &:last-of-type {
          margin: 0 0 22px;
        }
      }
    }

    .factors-graph-label {
      font-size: calc-rem(16);
      margin: 0 0 $mobile-container-padding;
    }

    .factors-items-container {
      display: flex;
      flex-direction: column;
      width: calc(100% - 55px);
      margin: 0 10px 0 0;
    }

    .factors-top-image {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .factors-graph-images {
      width: 100%;

      .factor-image {
        margin: 0 10px 0 0;
        vertical-align: top;
        display: inline-block;

        svg {
          max-width: 23px;
          max-height: 23px;
        }

        &.active {
          svg {
            fill: $dodger-blue;
            stroke: $dodger-blue;

            .sleep-quality {
              fill: #9db0ff;
            }
          }
        }

        &.dot {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: $dodger-blue;
        }

        &:last-child {
          margin: 0;
        }
      }

      img {
        max-width: 23px;
        max-height: 23px;
        margin: 0 10px 0 0;
        vertical-align: top;

        &:last-child {
          margin: 0;
        }
      }
    }

    .factors-divider {
      height: 5px;
      background-color: $background-alternative-colour;
    }

    .tip-container {
      background-color: $background-alternative-colour;
      border-radius: 10px;
      font-size: calc-rem(18);
      text-align: center;
      line-height: 26px;
      padding: 20px;
      margin: $container-inside-padding 0 0;

      .tip-title {
        text-transform: uppercase;
        margin: 0;
        font-weight: 500;
      }

      p {
        margin: 0;
        line-height: 26px;
      }
    }
  }

  &__statistics-section {
    display: flex;
    align-items: center;
    margin: 0 0 15px;

    &.feature {
      padding: 25px 0 0;
    }

    .statistics-value {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background-color: $dodger-blue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $white;
      margin: 0 $mobile-container-padding 0 0;

      &.image-section {
        background-color: $white;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
        align-items: center;

        img {
          max-width: 32px;
          max-height: 32px;
        }
      }

      p {
        font-size: calc-rem(23);
        text-align: center;
        margin: 0;
        line-height: 1;

        &:last-child {
          font-size: calc-rem(11);
        }
      }
    }

    .statistics-description {
      font-size: calc-rem(18);
      width: calc(100% - 75px);
    }
  }

  &__items-container {
    width: 100%;
  }

  &__question-section {
    padding: 0 0 $container-inside-padding;

    .form__select-wrapper select {
      margin: $mobile-container-padding 0 0;
    }
  }

  &__metabolic-affector {
    padding: $container-inside-padding 90px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }
  }

  &__affector-section {
    display: flex;
    align-items: center;

    @include media('<=medium') {
      flex-direction: column;
    }
  }

  &__age-affectors {
    &__container {
      margin: 0 auto;
      max-width: 1110px;

      .link-format {
        font-size: 1.125rem;
        color: #5b7bff;
        line-height: 1.625rem;
        font-weight: 500;
      }
    }

    &__ageing-influence {
      padding: $container-inside-padding 90px;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 25px 0 0;
      display: flex;
      align-items: center;

      @include media('<=medium') {
        padding: $container-inside-padding 22px;
        display: block;
      }
    }

    &__section-container {
      padding: $container-inside-padding 36px;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 25px 0 0;

      @include media('<=medium') {
        padding: $container-inside-padding 22px;
      }
    }

    &__affector {
      background-color: #f8f8f8;
      padding: 18px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      margin: 16px 0 0;

      .affector-title-section {
        text-align: left;
        width: 50%;
        position: relative;
        padding: 0 20px 0 20px;

        &:before {
          content: ' ';
          width: 6px;
          height: 100%;
          background-color: $dodger-blue;
          position: absolute;
          border-radius: 10px;
          left: 0;
        }

        &.genes:before {
          background-color: #51beff;
        }

        &.diet:before {
          background-color: #a577ff;
        }

        &.exercise:before {
          background-color: $dodger-blue;
        }

        &.other:before {
          background-color: #ff9eaf;
        }
      }

      .affector-value {
        font-size: calc-rem(22);
        font-weight: 500;
        margin: 0 0 6px;
        line-height: calc-rem(22);
      }

      .affector-title {
        margin: 0;
        font-size: calc-rem(14);
        color: #999999;
        line-height: calc-rem(18);
      }

      .affectors-container {
        @include media('<=small') {
          margin: 0 0 0 $mobile-container-padding;
          text-align: left;
        }
      }

      .icon-container {
        display: inline-block;
        border-radius: 50%;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
        width: 40px;
        height: 40px;
        vertical-align: top;
        padding: 8px 9px;
        margin: 0 $mobile-container-padding 0 0;
        background-color: $white;

        img {
          max-width: 22px;
          max-height: 23px;
        }

        &:last-child {
          margin: 0;
        }

        @include media('<=small') {
          width: 35px;
          height: 35px;
          padding: 6px 7px;
          text-align: center;

          img {
            max-width: 20px;
            max-height: 20px;
          }
        }
      }

      &:first-child {
        margin: 0;
        .affector-title-section:before {
          background-color: $skyblue-java-color;
        }

        @include media('<=medium') {
          margin: 36px 0 0;
        }
      }
    }

    &__affectors-section {
      display: inline-block;
      width: 50%;

      .empty-affectors {
        font-size: calc-rem(14);
        color: #999999;
        margin: 0;
      }

      @include media('<=medium') {
        width: 100%;
      }
    }

    &__gauge-chart {
      width: 50%;
      height: 180px;
      max-width: 180px;
      margin: 0 auto;
      display: inline-block;
      position: relative;

      .recharts-sector.recharts-radial-bar-background-sector {
        fill: $skyblue-java-color;
      }

      .gauge-label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 80%;
        font-size: calc-rem(24);
        color: $text-color;
        letter-spacing: 0;
        line-height: 1.4;
        margin: 0 auto;

        &.metabolic-gauge {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        }
      }

      @include media('<=medium') {
        width: 100%;
      }
    }

    &__twin-section {
      .content-container {
        display: flex;
        align-items: center;
        flex-grow: 1;

        .indicator-section-description {
          display: flex;
          align-items: center;
          margin: $container-inside-padding 0 0 20px;
          width: calc(50% - 20px);
          padding: $mobile-container-padding 25px;
          background: #f8f8f8;
          border-radius: 10px;
          font-size: calc-rem(18);
          text-align: center;
        }

        @include media('<=medium') {
          flex-direction: column;

          .indicator-section-description {
            width: 100%;
            margin: $container-inside-padding 0 0;
            text-align: center;
          }
        }
      }

      .twin-image-section {
        margin: $container-inside-padding 0 0;
        width: 50%;
        padding: 0 20px 0 0;
        text-align: left;

        img {
          width: 100%;
          max-width: 370px;
          max-height: 240px;
        }

        @include media('<=medium') {
          width: 100%;
          padding: 0;
          text-align: center;
        }
      }
    }

    &__calculate-section {
      text-align: left;

      .indicator-section-description {
        margin: 0 0 15px;
      }

      a {
        font-size: calc-rem(18);
        color: $dodger-blue;
        line-height: calc-rem(26);
        font-weight: 500;
      }
    }
  }

  &__overview-container {
    max-width: 910px;
    margin: 0 auto;
    padding: 0 $container-inside-padding;

    .faq__container {
      padding: 0;
    }

    @include media('<=medium') {
      padding: 0 $mobile-container-padding;

      &.full-width {
        padding: 0;
      }
    }
  }

  &__disease-risk-container {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 0 0 20px;
  }

  &__section-container {
    padding: $container-inside-padding 36px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    margin-top: $container-inside-padding;

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }
  }

  &__disease-risk-section {
    margin: 0 auto;
    max-width: 1110px;
  }

  &__team-section {
    margin: 20px auto 0;
    max-width: 1110px;
    padding: 0 $container-inside-padding 20px;

    @include media('<=medium') {
      padding: 0 0 20px;
    }
  }

  &__stress-section {
    padding: $container-inside-padding 36px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);

    .stress-container {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 0 $container-inside-padding;

      &:last-child {
        margin: 0;
      }

      .indicator-section-description {
        margin: 0;
      }

      @include media('<=medium') {
        flex-direction: column;

        .indicator-section-description {
          margin: 0 0 $container-inside-padding;

          &.no-margin {
            margin: $container-inside-padding 0 0;
            width: 100%;
          }
        }
      }
    }

    .stress-information-section {
      width: 50%;
      padding: 0 20px 0 0;
      display: inline-block;

      &.full-width {
        width: 100%;
        padding: 0;
      }

      @include media('<=medium') {
        width: 100%;
        padding: 0;
      }

      img {
        max-width: 300px;
        width: 100%;

        &.img-narrow {
          max-width: 250px;
        }
      }
    }

    .stress-image {
      display: inline-block;
      width: 50%;
      padding: 0 0 0 20px;

      &.show-background {
        height: 180px;
        background-color: #f1f1f1;
      }

      @include media('<=medium') {
        width: 100%;
        padding: 0;

        h4 {
          display: none;
        }
      }

      &.show-background {
        height: 180px;
        background-color: #f1f1f1;
      }

      img {
        max-width: 300px;
        max-height: 300px;

        &.img-narrow {
          max-width: 250px;
        }
      }

      .indicator-section-description {
        margin: 0;
      }
    }

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }
  }

  &__stress-epigenetics-section {
    width: 50%;
    display: inline-block;
    padding-left: 30px;

    @include media('<=medium') {
      padding: 0;
    }

    .stress-image {
      width: 100%;
    }

    .causes-section {
      max-width: 450px;
      margin: 0 auto;

      @include media('<=medium') {
        h4 {
          display: none;
        }
      }
    }

    .single-cause {
      display: flex;
      align-items: center;
      margin: 20px 0 0;

      .cause-number {
        width: 34px;
        height: 34px;
        border: 2px solid $dodger-blue;
        border-radius: 50%;
        line-height: calc-rem(30);
        font-weight: 500;
        font-size: calc-rem(12);
        margin: 0 15px 0 0;
      }

      .cause-title {
        font-size: calc-rem(16);
        margin: 0;
        text-align: left;
        width: calc(100% - 124px);
      }

      .cause-image {
        margin: 0 15px 0 0;
        width: 50px;
        height: 50px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &:first-child {
        margin: 0;
      }
    }

    @include media('<=medium') {
      width: 100%;
    }
  }

  &__result-calculation {
    &__container {
      .result-calculation-container {
        width: 100%;
        margin: 0 0 $container-inside-padding;

        &:last-child {
          margin: 0;
        }
      }

      .result-calculation-section {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 0 $container-inside-padding;

        .indicator-section-description {
          margin: 0;
        }

        &:last-child {
          margin: 0;
        }

        .result-calculation-information {
          width: 50%;
          display: inline-block;

          @include media('<=medium') {
            width: 100%;
          }
        }

        .result-calculation-image {
          width: 50%;
          display: inline-block;

          img {
            max-width: 250px;
            width: 100%;
          }

          .legend-section {
            background-color: #f8f8f8;
            border-radius: 10px;
            padding: $mobile-container-padding 18px;
            max-width: 250px;
            margin: 10px auto 0;

            .single-legend {
              display: flex;
              align-items: center;
              margin: 0 0 $mobile-container-padding;

              &:last-child {
                margin: 0;
              }
            }

            .legend-type {
              background-color: #ff9eaf;
              border-radius: 6px;
              height: 6px;
              width: 60px;
              margin: 0 15px 0 0;

              &.epigenetic_metabolism {
                background-color: $dodger-blue;
              }
            }

            .legend-name {
              font-size: calc-rem(14);
              color: $text-color;
              line-height: 1.4;
              margin: 0;
            }
          }

          @include media('<=medium') {
            width: 100%;

            img {
              max-width: 250px;
            }

            .legend-section {
              max-width: 300px;
            }
          }
        }

        @include media('<=medium') {
          flex-direction: column;

          .indicator-section-description {
            margin: 0 0 $container-inside-padding;

            &.no-margin {
              margin: 0 0 10px;

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.tooltip-text {
  color: $brand-pink-colour;
}

.tippy-popper {
  z-index: 1 !important;
}

.over-time-analysis {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 12px $container-inside-padding;
  text-align: center;
  margin: 60px 0 0 0;
}

.compare-tab {
  &__container {
    width: 100%;
    padding: 40px 0 0;
  }

  &__compare-section {
    text-align: center;
    width: 100%;
  }

  &__compare-graph {
    margin: 0 auto;
    max-width: 1110px;
    padding: 40px 50px;
    box-shadow: 0 5px 34px 0 rgba(0, 0, 0, 0.1);

    @include media('<=medium') {
      padding: $container-inside-padding $container-inside-padding;
    }

    @include media('<=small') {
      border-radius: 10px;
    }
  }

  &__how-works {
    margin: 0 auto $container-padding;
    max-width: 800px;
    padding: 0 $container-inside-padding;

    @include media('<=medium') {
      padding: 0 22px;
    }
  }

  &__filters {
    text-align: center;
    border-radius: 0;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    padding: 20px $container-inside-padding;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    flex-grow: 1;

    @media (max-width: 1140px) {
      text-align: left;
      padding: 10px 12vw;
    }

    @include media('<=tinysmall') {
      padding: 10px 5px;
    }
  }

  &__filter-item {
    display: inline-block;
    margin: 0 20px 0 0;
    &:last-child {
      margin: 0;
    }

    @media (max-width: 1140px) {
      width: 50%;
      margin: 10px 0;

      &:last-child {
        margin: 10px 0;
      }
    }
  }

  &__bar-graph-section {
    width: 100%;
    height: 250px;
    margin: 50px 0;
    position: relative;

    @include media('<=medium') {
      margin-top: 30px;
      height: 200px;
    }

    .text-section {
      font-size: calc-rem(14);
      color: #222222;
      letter-spacing: 0;
      line-height: 18px;
    }

    .tooltip {
      background-color: black;
      color: $white;
      padding: 10px $container-inside-padding;
    }

    .dot-value {
      font-size: calc-rem(22);
      font-weight: 500;

      @include media('<=small') {
        font-size: calc-rem(18);
      }
    }

    .legend-container {
      display: flex;
      justify-content: flex-end;

      &.show-background {
        background-color: $background-alternative-colour;
        border-radius: 10px;
        padding: $mobile-container-padding 10px;
      }

      @include media('<=medium') {
        justify-content: center;

        &.show-background {
          flex-direction: column;
        }
      }
    }

    .item-legend {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      padding: 0 $mobile-container-padding;

      .range-legend {
        height: 25px;
        width: 50px;
        margin: 0 10px 0 0;
      }

      .value-legend {
        height: 4px;
        width: 42px;
        background-image: linear-gradient(to right, $dodger-blue 80%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 20px 4px;
        background-repeat: repeat-x;
        margin: 0 10px 0 0;

        &.value_current {
          background-image: linear-gradient(to right, #a5a5a5 80%, rgba(255, 255, 255, 0) 0%);
        }

        &.value_quit {
          background-image: linear-gradient(to right, #459B64 80%, rgba(255, 255, 255, 0) 0%);
        }
      }

      p {
        margin: 0;
        font-size: calc-rem(18);
        line-height: calc-rem(20);
        color: $medium-gray;
        text-align: left;
        width: calc(100% - 52px);
      }

      @include media('<=medium') {
        padding: 0 0 10px;
        justify-content: flex-start;

        &:last-child {
          padding: 0;
        }
      }
    }

    .recharts-active-dot {
      circle {
        display: none;
      }
    }
  }
}

.heatmap-genotypes {
  text-align: center;

  .item {
    margin-left: 20px;
    cursor: pointer;

    &.you {
      color: #ff5d79;
    }

    &.active {
      text-decoration: underline;
    }
  }
}

.heatmap {
  &__container {
    margin: 40px auto;

    .slider-container {
      width: 100%;
      position: relative;
      max-width: 540px;
      margin: 60px auto 0;

      @include media('<=medium') {
        width: 65vw;
      }

      .rc-slider-rail {
        background: linear-gradient(-90deg, #87c04c 0%, #edf6e2 95%);
        height: 14px;
        border-radius: 0;
      }

      .rc-slider-track {
        background: transparent;
        height: 14px;
      }

      .rc-slider-disabled {
        background-color: unset;
      }

      .rc-slider-handle {
        display: none;
      }

      .slider-value {
        font-size: 12px;
        color: #656565;
        letter-spacing: 0.14px;
        text-align: center;
        line-height: 18px;
        position: absolute;
        left: 98%;
        top: -40px;

        &:first-child {
          left: -3px;
        }
      }
    }
  }

  &__graph-container {
    padding: $container-inside-padding;
    border: 1px solid #dddddd;
    border-radius: 20px;
    width: 100%;
  }

  &__graph-section {
    width: 70%;
    margin: 0 auto $container-inside-padding auto;
    border: 1px solid #dddddd;
    border-radius: 20px;

    @media (max-width: 800px) {
      width: 100%;
    }

    > div {
      width: 100% !important;
      margin: 0 auto;
      height: 50% !important;
    }
  }
}

.text-chronological-age {
  color: #8493a8;
}

.science-tab {
  &__container {
  }

  &__deep-dive-section {
    text-align: center;
    width: 100%;
    background-color: #ffffff;
    padding: $container-inside-padding;

    @include media('<=small') {
      padding: $container-inside-padding $mobile-container-padding;
    }
  }

  &__information-section {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
  }

  &__graphs-section {
    width: 100%;
    background: url(../../img/indicator/science_tab_bg_image.png) center center/cover no-repeat;
  }

  &__graphs-container {
    width: 100%;
    max-width: $block-max-width;
    margin: 0 auto;
    padding: $container-inside-padding;
  }

  &__section-container {
    width: 100%;
    display: flex;
    border-top: 1px solid rgba(202, 211, 223, 0.4);
    padding: $container-inside-padding 0;

    @include media('<=large') {
      display: block;
      padding: $container-inside-padding 0;
    }

    &:first-child {
      border-top: none;
    }
  }

  &__graph-container {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $container-inside-padding 0;
    > img {
      width: auto;
      height: auto;
    }
    @include media('<=large') {
      width: 100%;
      padding: $mobile-container-padding 0;
    }
  }

  &__graphs-information-section {
    width: 50%;
    padding: $container-inside-padding $mobile-container-padding;
    display: flex;
    flex-direction: column;
    align-items: center;

    > h3 {
      font-size: 21px;
      font-weight: 400;
      margin: 0;
      width: 100%;
    }

    > p {
      font-size: 15px;
      line-height: 1.4;
      color: white;
      margin: 15px 0 0 0;
    }

    @include media('<=large') {
      width: 100%;
      text-align: center;
      padding: 0;
    }
  }

  &__chromosome-information {
    @extend .science-tab__graphs-information-section;
    > h3 {
      color: $brand-pink-colour;
      text-align: left;
      @include media('<=large') {
        text-align: center;
      }
    }
  }

  &__methylation-graph {
    @extend .science-tab__graph-container;
    padding: 0 50px 0 $container-inside-padding;
    @include media('<=large') {
      padding: 0 $container-inside-padding;
    }
  }

  &__methylation-information {
    @extend .science-tab__graphs-information-section;
    padding: $container-inside-padding $mobile-container-padding;
    text-align: right;
    > h3 {
      color: #1ec1ca;
      text-align: right;
      @include media('<=large') {
        text-align: center;
      }
    }

    @include media('<=large') {
      text-align: center;
      padding: $mobile-container-padding 0;
    }
  }

  &__button-container {
    text-align: center;
    margin: 50px 0 0;

    .button {
      @extend .btn;
      border: 1px solid #03cad4;
      color: #03cad4;
      font-size: 15px;
      font-weight: 500;
      width: 345px;
      height: 45px;
      background: transparent;
      border-radius: 26px;
      display: flex;
      align-items: center;
      margin: 0 auto;

      i {
        width: 43px;
        height: 43px;
        padding-top: 12px;
        background: transparent;
        color: #03cad4;
        border-left: 1px solid #03cad4;
      }

      &:hover,
      &:focus {
        color: #03cad4;
      }
    }
  }
}

.compare-switch {
  @extend .switch;
}

input[type='checkbox'].compare-switch + label {
  padding: 2px 0 0 70px;
  cursor: pointer;
  display: inline-block;
  line-height: 1.5;
  word-break: break-word;
}
input[type='checkbox'].compare-switch + label:before {
  width: 58px;
  height: 26px;
  border-radius: 16px;
  background: #e9e9e9;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: none;
}
input[type='checkbox'].compare-switch + label:after {
  top: -2px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #ffffff;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}
input[type='checkbox'].compare-switch:checked + label:after {
  margin-left: 30px;
}
input[type='checkbox'].compare-switch:checked + label:before {
  background: #ffd8df;
}

.range {
  &__wrapper {
    margin: $container-inside-padding 0 $container-padding;
    color: $text-color;

    &:last-child {
      margin: $container-inside-padding 0;
    }

    @include media('<=small') {
      margin: 0 0 50px;

      &:last-child {
        margin: 0 0 $mobile-container-padding;
      }
    }
  }

  &__title {
    font-size: calc-rem(24);
    margin: 0;
    font-weight: 500;
    text-align: left;

    @include media('<=small') {
      font-size: calc-rem(18);
    }
  }

  &__description {
    margin: 5px 0 0 0;
    text-align: left;
  }

  &__container {
    width: 100%;
    position: relative;
    padding-top: 50px;
    margin-top: $container-inside-padding;
  }

  &__line-container {
    .line {
      width: 100%;
      height: 8px;
      position: relative;
      border-radius: 5px;
      background-color: #eaeaea;
    }

    .line-labels {
      display: flex;
      justify-content: space-between;
      font-size: calc-rem(16);
      color: $dim-grey;

      @include media('<=small') {
        font-size: calc-rem(14);
        color: #a5a5a5;
      }
    }
  }

  &__biological-age-line {
    width: 100%;
    display: flex;
    align-items: center;

    .colored-line {
      display: inline-block;
      background-color: $dodger-blue;
      position: absolute;
      top: 0;
      height: 8px;
      border-radius: 5px;
    }

    span {
      font-size: calc-rem(12);
      color: $dim-grey;
    }
  }

  &__biological-point {
    position: absolute;
    height: calc(100% - 34px);
    bottom: 8px;
    //bottom: $container-inside-padding;
    border-right: 2px dashed black;

    @include media('<=medium') {
    }
  }

  &__biological-number {
    position: absolute;
    top: -3px;
    font-size: calc-rem(18);
    text-align: center;
    font-weight: 500;
  }

  &__biological-text {
    position: absolute;
    bottom: -26px;
    font-size: calc-rem(16);
    color: $dim-grey;

    @include media('<=medium') {
    }
  }

  &__result-point-container {
    position: absolute;

    .result-point {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $white;
    }
  }
}

.how-works {
  &__section {
    width: 100%;
    text-align: center;

    @include media('<=small') {
      width: 100%;
    }
  }

  &__learn-more {
    text-align: center;
    margin: 50px 0 0 0;
  }

  &__card {
    position: relative;
    margin: 60px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #666666;
    width: 80%;

    img {
      max-width: 450px;
      max-height: 380px;
      position: absolute;
      left: 0;
      z-index: 0;

      &.full-height {
        height: 100%;

        @include media('<=medium') {
          height: auto;
        }
      }

      @include media('<=medium') {
        max-width: 100%;
        position: static;
        margin: 0 0 40px;
      }
    }

    &.right-image {
      justify-content: flex-start;
      margin: 60px 0 0 auto;

      .how-works__card-section {
        justify-content: flex-start;
      }

      img {
        right: 0;
        left: unset;

        @include media('<=medium') {
          left: 0;
        }
      }
    }

    @include media('<=medium') {
      width: 100%;
    }
  }

  &__card-section {
    //width: 85%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $background-alternative-colour;

    @include media('<=medium') {
      width: 100%;
      padding: 0 0 50px;
      height: auto;
      position: relative;
      display: block;
    }
  }

  &__information-section {
    width: 50%;
    text-align: left;
    padding: $container-inside-padding;
    position: relative;
    z-index: 1;

    @include media('<=medium') {
      width: 100%;
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
      padding: 0;
      position: static;
    }
  }

  &__step-number {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: $dodger-blue;
    font-size: calc-rem(18);
    font-weight: 500;
    color: $white;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    line-height: 32px;
    position: absolute;
    left: calc(100% - 20px);
    top: -20px;
    border: 3px solid #fcfcfc;

    &.left-number {
      left: -20px;
    }
  }

  &__card-info {
    display: inline-block;
    width: 100%;

    .card-title {
      font-size: calc-rem(23);
      font-weight: 500;
      line-height: calc-rem(28);
      margin: 0 0 20px 0;
    }

    .card-description {
      font-size: calc-rem(16);
      line-height: calc-rem(22);
      margin: 0;
    }

    @include media('<=medium') {
      padding: 0 $mobile-container-padding;
    }
  }
}

.faq {
  &__container {
    max-width: 1180px;
    padding: 0 35px;
    margin: $container-inside-padding auto 0;
    color: $text-color;
    display: flex;

    @include media('<=medium') {
      display: block;
      padding: 0 $container-inside-padding;
    }
  }

  &__section {
    width: calc(50% - 15px);
    display: flex;
    flex-wrap: wrap;
    background: $background-alternative-colour;
    margin: 0 15px 0 0;
    box-shadow: 5px 10px 20px rgba(100, 100, 101, 0.36);

    &:last-child {
      margin: 0 0 0 15px;
    }

    @include media('<=medium') {
      margin: 0;
      width: 100%;

      &:last-child {
        margin: $container-inside-padding 0 0 0;
      }
    }
  }

  &__section-image {
    width: 40%;
    overflow: hidden;
    display: inline-block;
    max-height: 190px;
  }

  &__information {
    text-align: left;
    display: flex;
    width: 60%;
    flex-direction: column;
    padding: 20px;

    .title {
      font-size: calc-rem(20);
      margin: 0 0 10px 0;
      font-weight: 500;

      @include media('<=small') {
        font-size: calc-rem(16);
      }
    }

    .description {
      font-size: calc-rem(18);
      line-height: calc-rem(26);
      margin: 0;

      @include media('<=small') {
        font-size: calc-rem(14);
      }
    }
  }
}

.health-specialist {
  &__container {
    background-color: $background-alternative-colour;
    border-radius: 10px;
    padding: $container-inside-padding 36px;

    @include media('<=medium') {
      padding: $container-inside-padding;
      border-radius: 0;
    }

    @include media('<=small') {
      .btn {
        width: 100%;
      }
    }

    &.comparison-section {
      padding: 55px 36px;

      @include media('<=medium') {
        padding: 55px $container-inside-padding;
      }
    }
  }

  &__section-content {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0 42px;

    .content-section {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;

      @include media('<=medium') {
        width: 100%;
      }
    }

    .image-container {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      background: $white;
      margin: 0;
      display: inline-block;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      @include media('<=medium') {
        display: flex;
        height: auto;
        align-items: center;
        margin: 0 0 0 $mobile-container-padding;
      }
    }

    .information-section {
      display: inline-block;
      width: calc(100% - 112px);
      text-align: left;
      margin: 0 0 0 16px;
      padding: 0 $container-inside-padding 0 0;
      border-right: 1px solid #d8d8d8;

      .specialist-name {
        margin: 0;
        font-size: calc-rem(20);
        line-height: 1.4;
        font-weight: 500;
      }

      .specialist-designation {
        font-size: calc-rem(18);
        color: #808080;
        line-height: 20px;
        margin: 10px 0 0;
      }

      @include media('<=medium') {
        padding: 0;
        border-right: none;
        width: auto;
      }
    }

    .specialist-description {
      font-size: calc-rem(18);
      color: $text-color;
      line-height: calc-rem(28);
      margin: 0;
      text-align: left;
      width: 50%;
      padding: 0 0 0 60px;
      display: flex;
      align-items: center;

      @include media('<=medium') {
        width: 100%;
        padding: $container-inside-padding 0 0;
      }
    }
  }
}

.analysis-text {
  font-size: calc-rem(18);
  text-align: left;
  line-height: calc-rem(26);
  background-color: $background-alternative-colour;
  border-radius: 10px;
  padding: $mobile-container-padding 25px;
  width: 50%;
  display: inline-block;

  &--wide {
    width: 100%;
  }

  &--stress {
    margin: $container-inside-padding 0;
    width: 100%;
  }

  &--small {
    margin: 0 0 0 20px;
    width: calc(40% - 20px);
  }

  &.text-center {
    text-align: center;
  }

  @include media('<=medium') {
    width: 100%;
    text-align: center;
    padding: $mobile-container-padding 10px;

    &--stress {
      margin: 0;
      width: 100%;
    }

    &--small {
      margin: 0;
      width: 100%;
    }
  }
}

.comparison {
  &__container {
    padding: $container-inside-padding 36px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    margin: $container-inside-padding 0 0;

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }

    .compare-icon-container {
      display: inline-block;
      margin: 0 6px 0 0;

      &.add-colour {
        circle,
        path {
          fill: $dodger-blue;
        }
      }
      &:last-child {
        margin: 0;
      }

      @include media('<=small') {
        margin: 0 5px 0 0;
        width: calc((100% / 10) - 5px);

        svg {
          width: 100%;
        }
      }
    }

    .compare-graph-section {
      text-align: left;
      margin: $container-inside-padding 0 0;
      display: flex;
      align-items: center;

      @include media('<=medium') {
        flex-direction: column;
      }
    }

    .compare-graph {
      width: 50%;
      display: inline-block;
      text-align: left;

      @include media('<=medium') {
        width: 100%;
        text-align: center;
      }
    }

    .analysis-text {
      font-size: calc-rem(18);
      text-align: center;
      line-height: calc-rem(26);
      background-color: $background-alternative-colour;
      border-radius: 10px;
      padding: $mobile-container-padding 25px;
      width: 50%;
      display: inline-block;

      &--wide {
        width: 100%;
      }

      &--stress {
        margin: $container-inside-padding 0;
        width: 100%;
      }

      &--small {
        margin: 0 0 0 20px;
        width: calc(40% - 20px);
      }

      &.text-center {
        text-align: center;
      }

      @include media('<=medium') {
        width: 100%;
        text-align: center;
        padding: $mobile-container-padding 10px;

        &--stress {
          margin: 0;
          width: 100%;
        }

        &--small {
          margin: 0;
          width: 100%;
        }
      }
    }

    .metabolic-comparison {
      display: flex;
      justify-content: space-evenly;
      margin: 0 0 29px;
    }
  }

  &__graph-container {
    display: flex;
    flex-grow: 1;

    &.start-section {
      align-items: flex-start;
    }

    &.stress-section {
      align-items: center;
      flex-direction: column;
    }

    @include media('<=medium') {
      flex-direction: column;
    }

    .graph-section-container {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      position: relative;
      padding: 0 20px 0 0;
      margin: $container-inside-padding 0;

      &.no-margin {
        margin: 0;
      }

      @include media('<=medium') {
        width: 100%;
        padding: 0;
        max-width: 300px;
        margin: $container-inside-padding auto;

        &.no-margin {
          margin: 0 auto $container-inside-padding;
          max-width: unset;
        }
      }

      &.stress-compare-graph {
        display: flex;
        align-items: center;
        padding: 0;
        width: 100%;

        &.bar-graph-container {
          width: 60%;
        }

        .compare-graph-bar-container {
          position: relative;
          width: calc(100% - 124px);
          background-color: $dodger-blue;
          height: 6px;
        }

        .label-section {
          position: relative;
        }

        .label-title {
          position: absolute;
          margin: 10px 0 0;
          width: 100%;
          font-size: calc-rem(16);
        }

        .compare-label {
          width: 62px;
          height: 62px;
          border-radius: 50%;
          background-color: $white;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 40px;
            max-height: 40px;
          }
        }

        .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child {
          stroke-opacity: 0;
        }

        @include media('<=medium') {
          margin: $container-inside-padding auto 60px;

          &.bar-graph-container {
            width: 100%;
          }

          &.no-margin {
            margin: 0 auto $container-inside-padding;
            max-width: unset;
          }
        }
      }

      &.bar-graph {
        height: 200px;

        @include media('<=medium') {
          max-width: 350px;
        }
      }

      &.radar-graph {
        height: 250px;
        margin: 0 auto;
        width: 80%;

        @include media('<=medium') {
          max-width: 350px;
          margin: 0 auto $container-inside-padding;
          width: 100%;
        }
      }

      .radar-center-label {
        width: 32px;
        height: 32px;
        position: absolute;
        border-radius: 50%;
        left: calc(50% - 26px);
        top: calc(50% + 10px);
        z-index: 1;
        background-color: $white;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 19px;
          max-height: 18px;
        }

        @include media('<=small') {
          left: calc(50% - 16px);
          top: calc(50% + 16px);
        }
      }
    }

    .radar-chart-container {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      margin: 70px 0 0;
      text-align: center;

      .recharts-polar-angle-axis .recharts-polygon {
        fill: #f3f3f3;
      }

      .recharts-polar-grid-concentric .recharts-polar-grid-concentric-polygon {
        stroke: transparent;
      }

      .label-image-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .label-image {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;

        img {
          max-width: 25px;
          max-height: 25px;
        }
      }

      @include media('<=medium') {
        width: 100%;
        text-align: center;
      }
    }

    .graph-section {
      width: 100%;
      height: 200px;

      .graph-label {
        font-size: calc-rem(14);
        fill: #a5a5a5;
        letter-spacing: 0.17px;
      }
    }

    .dot-value {
      font-size: calc-rem(25);
      font-weight: 500;

      @include media('<=small') {
        font-size: calc-rem(21);
      }
    }

    .label-container {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: calc-rem(14);
      color: #a5a5a5;
      letter-spacing: 0.17px;
      bottom: 0;
    }

    .legends-section {
      width: calc(50% - 20px);
      margin: 0 0 0 20px;
    }
  }

  &__question-section {
    .question {
      .question-options.active {
        max-height: 200px;

        @include media('<=small') {
          .custom__multiple-choice {
            width: 100%;
          }

          .custom__radio-container {
            margin: 0 $mobile-container-padding 0 0;
            width: calc(50% - 7px);

            &:last-child {
              margin: 0;
            }

            label {
              margin: $mobile-container-padding 0;
              min-width: unset;
            }
          }
        }
      }
    }

    .question-options {
      text-align: center;
    }
  }

  &__jobs-container {
    display: flex;
    align-items: center;

    @include media('<=medium') {
      flex-direction: column;
    }

    .ellipsis-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: $mobile-container-padding 0 0;

      span {
        background-color: #d5d5d5;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        border: 1px solid #979797;
        margin: 0 4px;
      }
    }
  }

  &__jobs-section {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .job-container {
      display: flex;
      align-items: center;
      font-size: calc-rem(18);
      padding: 8px $mobile-container-padding;
      background-color: $background-alternative-colour;
      border-radius: 10px;
      flex-basis: calc((100% / 3) - 13px);
      margin: $mobile-container-padding $mobile-container-padding 0 0;

      &.active {
        background-color: $dodger-blue;
        color: $white;
        margin: $mobile-container-padding 0 0;
      }

      @include media('<=medium') {
        flex-basis: calc((100% / 2) - 13px);
      }

      @include media('<=small') {
        flex-basis: 100%;
        margin: $mobile-container-padding 0 0;
      }
    }

    .job-position {
      background-color: $white;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: calc-rem(18);
      color: $dodger-blue;
      text-align: center;
      line-height: calc-rem(40);
      margin: 0 $mobile-container-padding 0 0;
      font-weight: 500;
    }

    .job-title {
      line-height: calc-rem(26);
      margin: 0;
      width: calc(100% - 53px);
      text-align: left;
    }

    .job-section {
      flex-basis: 100%;
      margin: $mobile-container-padding 0 0;
    }

    &--small {
      display: inline-block;
      width: 50%;
      padding: 0 20px 0 0;

      .job-container {
        width: 100%;
        margin: 0;

        @include media('<=small') {
          margin: 0;
        }
      }

      @include media('<=medium') {
        margin: 0 0 40px;
        padding: 0;
        width: 100%;
      }
    }
  }

  &__analysis-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .analysis-image {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin: 0 15px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);

      img {
        max-width: 27px;
        height: 26px;
      }
    }

    .comparison-analysis-text {
      width: calc(100% - 60px);
      text-align: center;
    }
  }

  &__radar-chart {
    margin: 0 0 $container-inside-padding;

    &:last-child {
      margin: 0;
    }
  }
}

.metabolic-bar {
  &__container {
    background: #e2e2e2;
    box-shadow: none;
  }

  &__content {
    margin: 0 auto;
    padding: 15px 10px;
    max-width: 1180px;

    .information-section {
      display: inline-block;
      width: calc(100% - 100px);
      margin: 0 10px 0 0;

      @include media('<=medium') {
        display: block;
        width: 100%;
      }

      .icon-info-circled-alt {
        vertical-align: middle;
        display: inline-block;
        margin: 0 10px 0 0;
      }
    }

    .test-banner-title {
      display: inline-block;
      width: calc(100% - 36px);
      vertical-align: middle;
      font-size: calc-rem(17);
      margin: 0;
      font-weight: 500;
    }

    .date-section {
      width: 80px;
      display: inline-block;
      margin: 0 10px 0 0;
      vertical-align: middle;
      text-align: right;

      @include media('<=medium') {
        width: 100%;
        display: block;
      }

      .test-banner-title {
        width: 100%;
      }
    }
  }
}

.genes-affected {
  .indicator-section-description {
    margin: 0;
  }

  &__affected-section {
    color: $text-color;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;

    .affector-section {
      background-color: $white;
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: $container-inside-padding 20px 0 0;
      text-align: left;
      display: inline-block;
      width: calc(50% - 20px);
      vertical-align: top;

      &:nth-child(even) {
        margin: $container-inside-padding 0 0 20px;
      }

      .header-section {
        background-color: #f2f2f2;
        padding: $mobile-container-padding 36px;
        border-radius: 10px 10px 0 0;
        font-size: calc-rem(18);
        font-weight: 500;

        @include media('<=medium') {
          padding: $mobile-container-padding 22px;
        }
      }

      .affector-information-section {
        border-radius: 0 0 10px 10px;
        padding: 20px 36px;

        .affector-information-description {
          font-size: calc-rem(18);
          line-height: calc-rem(26);
          margin: 0;
        }

        .affector-information-link {
          margin: 0 0 20px;
          display: block;
        }

        @include media('<=medium') {
          padding: 20px 22px;
        }

        .methylation-graph__title {
          text-align: left;
          line-height: 1;
        }

        .methylation-graph__bars {
          margin: 15px 0 0;
        }

        .affector-text {
          font-size: calc-rem(16);
          color: #a5a5a5;
          margin: 0;
        }
      }

      .methylation-graph__container {
        padding: 40px 0 0;
      }

      @include media('<=medium') {
        width: 100%;
        margin: $container-inside-padding 0 0;

        &:nth-child(even) {
          margin: $container-inside-padding 0 0;
        }
      }
    }
  }
}

.line-graph {
  &__container {
    width: 100%;

    .item-legend {
      display: flex;
      align-items: center;
      margin: $mobile-container-padding 0 0;

      .range-legend {
        height: 4px;
        width: 42px;
        background-image: linear-gradient(to right, $dodger-blue 80%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 20px 4px;
        background-repeat: repeat-x;
        margin: 0 10px 0 0;

        &.you,
        &.you_no_data {
          width: 18px;
          height: 18px;
          border: 3px solid $dodger-blue;
          border-radius: 50%;
          background-color: $dodger-blue;
          margin: 0 10px 0 20px;
        }

        &.type {
          background-color: #ff9eaf;
        }
      }

      .range-legend.you,
      .range-legend.you_no_data {
        ~ p {
          width: calc(100% - 48px);
        }
      }

      p {
        margin: 0;
        font-size: calc-rem(14);
        color: $text-color;
        line-height: 18px;
        text-align: left;
      }
    }

    &.horizontal-graph {
      .range-legend {
        height: 6px;
      }

      .legends-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex-grow: 1;
      }
    }
  }

  &__tabs-container {
    display: flex;
    align-items: center;
    margin: 0 0 $container-inside-padding;
    overflow-x: auto;
    height: 40px;

    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }

    &--no-margin {
      margin: 0;

      @include media('<=medium') {
        margin: 0 0 40px;
      }
    }

    &.center-align {
      justify-content: center;
    }

    .single-tab {
      font-size: calc-rem(14);
      letter-spacing: 0;
      text-align: center;
      line-height: 1.2;
      background-color: #f3f3f3;
      box-shadow: 0 2px 7px 0 #dad8d8;
      border-radius: 15px;
      padding: 5px 15px;
      color: #808080;
      margin: 0 0 0 10px;
      border: none;

      &.active {
        background-color: $dodger-blue;
        color: $white;
        outline: none;

        &.you {
          background-color: $dodger-blue;
        }

        &.chronomics_community {
          background-color: #F0975B;
        }

        &.type {
          background-color: #ff9eaf;
        }
      }

      &:active,
      &:focus,
      &:hover {
        outline: none;
      }
    }
  }

  &__graph-container {
    width: 100%;
    height: 300px;
    position: relative;

    .graph-label,
    .recharts-cartesian-axis-tick-value {
      font-size: calc-rem(14);
      fill: #a5a5a5;
      letter-spacing: 0.17px;
    }

    &.bar-graph-section .recharts-yAxis .recharts-cartesian-axis-tick-value {
      fill: $text-color;
    }

    .graph-label {
      color: $text-color;
    }

    .label-container {
      position: absolute;
      text-align: center;
      bottom: 6px;
      justify-content: center;
      width: 100%;

      &.top-label {
        top: -10px;
        justify-content: flex-start;
        text-align: left;
        bottom: unset;
        width: auto;
        line-height: 1;
      }
    }

    .tooltip-child {
      width: 18px;
      height: 18px;
      border: 3px solid $dodger-blue;
      border-radius: 50%;
      background-color: $dodger-blue;
    }

    @include media('<=medium') {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}

.legends {
  &__container {
    background-color: $background-alternative-colour;
    border-radius: 10px;
    padding: $mobile-container-padding;

    @include media('<=medium') {
      &.full-width {
        max-width: 350px;
        margin: 0 auto;
      }
    }
  }

  &__section {
    margin: $mobile-container-padding 0 0;
    display: flex;
    align-items: center;

    &:first-child {
      margin: 0;
    }

    .item-legend {
      width: 60px;
      height: 6px;
      margin: 0 10px 0 0;
      border-radius: 5px;
      background-color: $dodger-blue;

      &.type {
        background-color: #ff9eaf;
      }
    }

    .legend-title {
      font-size: calc-rem(14);
      color: $text-color;
      line-height: 1.4;
      margin: 0;
    }
  }
}

.metabolic-status {
  &__epigenetic-section {
    padding: $container-inside-padding 36px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    margin-top: $container-inside-padding;

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }

    .metabolic-image-container {
      width: 100%;
      max-width: 400px;
      margin: $container-inside-padding auto 0;

      &.show-background {
        height: 180px;
        background-color: #f1f1f1;
      }

      img {
        width: 100%;
      }
    }
  }
}

.what-affecting {
  &__container {
    padding: $container-inside-padding 36px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    margin: $container-inside-padding 0 0;

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }
  }

  &__section {
    display: flex;
    align-items: center;

    @include media('<=medium') {
      flex-direction: column;
    }
  }

  &__graph-section {
    width: 50%;
    padding: 0 20px 0 0;
  }
}

.comparison-graph {
  &__container {
    width: 100%;
    position: relative;
    margin: 120px 0 0;

    @include media('<=medium') {
      margin: 160px 0 0;
    }
  }

  &__bar {
    height: 5px;
    width: 100%;
    border-radius: 5px;
    background-color: $dodger-blue;
    position: relative;

    .other-point {
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &__other-section {
    .other-image-section {
      position: absolute;
      text-align: center;
      bottom: 15px;

      .other-label {
        font-size: calc-rem(14);
        color: $text-color;
        letter-spacing: 0.17px;
        margin: 0 0 5px;

        @include media('<=medium') {
          transform: rotate(-45deg);
          position: absolute;
          bottom: 50px;
          text-align: left;
          margin: 0;
          z-index: 1;
          left: 10px;
          line-height: 1.3;
        }
      }

      .other-image {
        width: 36px;
        height: 36px;
        background-color: $white;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 23px;
          max-height: 23px;
        }
      }
    }
  }

  &__you-section {
    .tooltip-container {
      position: absolute;
      bottom: 15px;
    }

    .tooltip-line {
      width: 2px;
      height: 50px;
      background-color: #d8d8d8;

      @include media('<=medium') {
        height: 90px;
      }
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 $container-inside-padding;

    p {
      font-size: calc-rem(12);
      color: #a5a5a5;
      letter-spacing: 0.14px;
      margin: 0;

      &.graph-label {
        color: $text-color;
        font-size: calc-rem(14);
      }
    }
  }
}

.epigenetic-heartbeat {
  &__container {
    padding: $container-inside-padding 36px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);

    .read-more-link {
      text-align: left;
    }

    @include media('<=medium') {
      padding: $container-inside-padding 22px;
    }
  }

  &__images-section {
    display: flex;
    align-items: center;

    .heartbeat-image {
      width: 50%;
      padding: 0 20px 0 0;
      display: inline-block;
      vertical-align: top;
      margin: 0 0 $container-inside-padding;

      &:last-child {
        padding: 0 0 0 20px;
      }

      img {
        max-width: 100%;
        max-height: 200px;
      }

      @include media('<=medium') {
        width: 100%;
        padding: 0;

        &:last-child {
          padding: 0;
        }

        .indicator-section-description.last-section {
          margin: 0;
        }
      }
    }

    @include media('<=medium') {
      flex-direction: column;
    }
  }
}

.compare-line {
  &__container {
    width: 100%;
    margin: 0 0 $container-inside-padding;
  }

  &__section {
    width: 100%;
    margin: 0 0 $container-inside-padding;

    &:last-child {
      margin: 0;
    }

    .compare-line-sub-title {
      font-size: calc-rem(18);
      color: $text-color;
      line-height: 1.4;
      margin: -10px 0 20px;
      text-align: left;
    }
  }

  &__bar {
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: $background-alternative-colour;
    position: relative;
    margin: 60px 0 0;

    &--inside-bar {
      position: absolute;
      background-color: $dodger-blue;
      top: 0;
      margin: 0;
    }

    .compare-result-point {
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $white;
      top: 0;
      z-index: 1;
    }
  }
}

.custom-tooltip {
  background-color: $white;
  color: $text-color;
  border-radius: 5px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: relative;
  font-size: calc-rem(12);
  font-weight: 500;

  .tooltip-title {
    font-size: calc-rem(12);
    color: #a5a5a5;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.3;
    margin: 0 0 10px;
  }
}

.custom-tooltip::after {
  content: ' ';
  position: absolute;
  border: 7px solid transparent;
  border-right: 10px solid white;
  left: -14px;
  top: calc(50% - 7px);
}

.previous-test-tooltip {
  width: 160px;
  font-size: calc-rem(14);
  color: #31394d;
  text-align: center;

  p {
    margin: 0;
    line-height: 1.2;
    &:last-child {
      color: #a3a3a3;
    }
  }
}

.custom-popper {
  &.tippy-popper {
    .tippy-tooltip.light-theme {
      box-shadow: none;
    }
  }
}
