.faq-page {
    padding: 15px;
    margin: auto;
    max-width: 1180px;

    &__title-container {
        text-align: center;
        margin-bottom: 50px;

        &__header {
            color: $dodger-blue;
            font-size: calc-rem(40);
            line-height: 63px;
            //text-transform: uppercase;
            margin: 40px 0 0 0;
        }

        &__description {
            max-width: 460px;
            color: $text-color;
            font-size: calc-rem(18);
            line-height: calc-rem(26);
            margin: auto;
        }
    }

    .questionnaire-container {
        margin-top: 35px;
        &__title {
            color: $melrose;

            p {
                font-size: calc-rem(22);
                line-height: calc-rem(25);
            }
        }

        .question-section {
            text-align: left;
            border-bottom: 1px solid $gainsboro;
            cursor: pointer;

            header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                i {
                    margin-right: $container-inside-padding;
                    color: $black;
                    font-size: calc-rem(18);
                    cursor: pointer;
                }

                .question-item {
                    color: $black;
                    font-size: calc-rem(20);
                    line-height: 24px;
                }
            }

            .answer {
                color: #838383;
                max-height: 0;
                transition: max-height 0.3s ease-out;
                overflow: hidden;

                p {
                    font-size: calc-rem(18);
                    line-height: 24px;
                }

                &.active {
                    max-height: 500px;
                    transition: max-height 0.5s ease-in;
                }
            }
        }
    }
}
