.plan-details-container{
    margin: 20px auto 0;
    max-width: 880px;
    padding: 29px;

    @include media("<=small") {
        padding: $container-inside-padding $mobile-container-padding;
    }

    &__title {
        font-size: calc-rem(36);
        line-height: calc-rem(40);
        padding: 0;
        margin: 0;
        font-weight: 500;

        @include media ("<=small") {
            font-size: calc-rem(28);
            line-height: calc-rem(35);
        }
    }

    &__description {
        font-size: calc-rem(20);
        line-height: calc-rem(30);
        color: $text-color;
        margin: $mobile-container-padding 0;
        text-align: left;

        @include media ("<=small") {
            font-size: calc-rem(18);
            line-height: calc-rem(26);
        }
    }

    &__details-section{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0 0;

        .details-icon{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin: 0 22px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);

            img{
                max-width: 36px;
                height: 34px;
            }
        }

        .details{
            width: calc(100% - 82px);
            font-size: calc-rem(20);
            line-height: calc-rem(30);
            margin: 0;
            text-align: left;

            @include media ("<=small") {
                font-size: calc-rem(18);
                line-height: calc-rem(26);
                word-break: break-all;
            }
        }

        @include media ("<=small") {
            margin: 29px 0 0;
        }
    }

    &__improve-card-container{
        display: flex;
        padding: 29px 0;
        flex-wrap: wrap;

        .improve-card{
            width: 100%;
            margin: 13px 10px;
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            background-color: #ffffff;
            flex-basis: calc(100%/2 - 20px);
            max-width: calc(100%/2 - 20px);
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-between;


            @include media ("<=medium") {
                flex-basis: calc(100% - 20px);
                max-width: calc(100% - 20px);                
            }

            &:hover{
                background-color: #f3f3f340;

                .btn{
                    transform: scale(1.05);
                }
            }

            .width-100{
                width: 100%;
            }
            
            &__information-section{
                background-color: #F2F2F2;
                padding: 20px 13px;
                color: #444444;
                border-radius: 10px 10px 0 0;
                min-height: 125px;

                @include media ("<=medium") {
                    min-height: 100px;
                }

                .card-title{
                    font-size: calc-rem(18);
                    margin: 0 0 13px;
                    font-weight: 500;
                }

                .card-information{
                    font-size: calc-rem(16);
                    margin: 0;
                    line-height: 1.4;
                }
            }

            &__details-section{
                text-align: center;
                padding: 20px 13px;

                .product-information{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 15px;

                    .icon-container{
                        width: 22px;
                        height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        box-shadow:  2px 3px rgba(0,0,0,0.15);
                        background-color: #ffffff;
                        border: 2px solid #5B7BFF;

                        img{
                            width: 12px;
                            height: 14px;
                        }
                    }

                    p{
                        font-size: calc-rem(16);
                        line-height: calc-rem(22);
                        margin: 0;
                        text-transform: capitalize;
                        color: #444444;
                    }
                }

                .indicator-section{
                    margin: 0 0 15px;
                    background: transparent;


                    p{
                        font-size: calc-rem(16);
                        line-height: calc-rem(22);
                        margin: 0;
                        text-transform: capitalize;
                        text-align: left;
                        color: #444444;
                    }


                    
                    &__icon-container-section{
                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .icon-container{
                                                    
                            width: 38px;
                            max-width: 38px;
                            flex-grow: 1;
                            display: flex;
                            height: 38px;
                            align-items: center;
                            border-radius: 50%;
                            box-shadow: 1px 2.5px rgba(0, 0, 0, 0.15);
                            background-color: #ffffff;
                            justify-content: center;
                            margin: 10px 10px 0 0;

                            img{
                                width: 15px;
                                height: 15px;
                                filter: grayscale(100%);
                            }

                        }
                    }
                }
            }
        }
    }
}
