// -----------------------------------------------------------------------------
// This file contains styles that are specific to the disease risk section
// -----------------------------------------------------------------------------

.disease-risk {
    &__container {
        max-width: 1110px;
        margin: 0 auto;
        color: $text-color;

        .indicator-section-description {
            margin: 0;
        }
    }

    &__diseases-section {
        max-width: 880px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }

    &__disease-card {
        width: calc(50% - 15px);
        display: inline-block;
        margin: $container-inside-padding 15px 0 0;
        vertical-align: top;
        text-align: left;
        padding: 17px $container-inside-padding $container-inside-padding;
        background-color: $background-alternative-colour;
        box-shadow: 0 3px 15px 0 rgba(0,0,0,0.10);
        border-radius: 10px;
        font-size: calc-rem(18);
        line-height: calc-rem(26);

        &:nth-child(even) {
            margin: $container-inside-padding 0 0 15px;
        }

        .header-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .disease-title {
            font-size: calc-rem(20);
            line-height: calc-rem(28);
            margin: 0;
            font-weight: 500;
        }

        .disease-image {
            width: 62px;
            height: 62px;
            background-color: $white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 1px 10px 0 rgba(0,0,0,0.15);

            img {
                max-width: 32px;
                max-height: 38px;
            }
        }

        .disease-description {
            font-size: calc-rem(18);
            line-height: calc-rem(26);
            margin: 24px 0 10px;

            &.category-description {
                margin: 24px 0 0;
            }
        }

        .disease-read-link {
            font-size: calc-rem(18);
            color: $dodger-blue;
            line-height: calc-rem(26);
        }

        @include media ("<=medium") {
            width: 100%;
            margin: $container-inside-padding 0 0;
            padding: 17px 22px 22px;

            &:nth-child(even) {
                margin: $container-inside-padding 0 0;
            }
        }
    }

    &__analysis-section {
        text-align: center;
        width: 100%;
        margin: $container-inside-padding 0 0 0;
        padding: 0 0 $container-inside-padding;

        .analysis-description {
            font-size: calc-rem(18);
            text-align: center;
            line-height: calc-rem(26);
            max-width: 850px;
            margin: 0 auto;
            background-color: $background-alternative-colour;
            padding: $mobile-container-padding 50px;
            border-radius: 10px;

            @include media ("<=medium") {
                padding: $mobile-container-padding 22px;
            }
        }
    }

    &__epigenetics-section {
        max-width: 200px;
        margin: 0 auto;
        text-align: center;

        img {
            max-width: 50px;
            max-height: 50px;
            margin: 0 auto;
        }

        .epigenetic-title {
            font-size: calc-rem(16);
            color: #232323;
            letter-spacing: 0;
            text-align: center;
            margin: 0;
            line-height: 1.4;
        }
    }

    &__develop-section {
        max-width: 850px;
        margin: $container-inside-padding auto 0;
        box-shadow: 0 3px 15px 0 rgba(0,0,0,0.10);
        padding: 0 $container-inside-padding;
        border-radius: 10px;
        cursor: pointer;

        .header-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            text-align: left;

            .header-title {
                font-size: calc-rem(18);
                line-height: calc-rem(30);
                margin: 0 $mobile-container-padding 0 0;
                font-weight: 500;
            }

            i {
                font-size: calc-rem(25);
            }
        }

        .develop-description {
            font-size: calc-rem(18);
            text-align: left;
            width: 100%;
            max-height: 0;
            transition: max-height 0.3s ease-out;
            overflow: hidden;

            &.active {
                max-height: 1600px;
                transition: max-height 0.5s ease-in;
            }

            ul {
                margin: 0;
                list-style-type: none;
                padding: 0;

                li {
                    margin: 15px 0 0;
                    position: relative;
                    padding: 0 0 0 36px;

                    &:before {
                        content: " ";
                        height: 11px;
                        width: 11px;
                        background-color: $dodger-blue;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 9px;
                    }

                    &:first-child {
                        margin: 0;
                    }

                    @include media ("<=medium") {
                        padding: 0 0 0 $container-inside-padding;
                    }
                }
            }
        }

        @include media ("<=medium") {
            padding: 0 22px;
        }
    }
}