// -----------------------------------------------------------------------------
// This file contains all styles related to the chromosome chart component.
// -----------------------------------------------------------------------------

.chromosome-chart {
    &__container {
        width: 100%;
    }

    &__section {
        width: 100%;
        text-align: left;
        margin: 20px 0 0;
        display: flex;
        align-items: center;
    }

    &__parts-container {
        display: inline-block;
        width: 100%;
    }

    &__part-section {
        display: inline-block;
        height: 12px;
        padding: 0 3px;
        min-width: 20px;

        @include media ("<=medium") {
            padding: 0 1px;
            min-width: 9px;
        }
    }

    &__part {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 2px solid #A3A3A3;
    }

    &__label {
        display: inline-block;
        font-size: calc-rem(14);
        text-align: right;
        margin: 0 10px;
        width: 40px;

        @include media ("<=medium") {
            width: auto;
            margin: 0 10px 0 0;
        }
    }
}
