.help-box {
    &__container {
        border: 1px solid;
        border-radius: 20px;
        padding: $container-inside-padding 0;

        .btn {
            margin: $container-inside-padding 0 0;
        }

        span {
            margin: $container-inside-padding 0 0;
        }
    }

    &__information-section {
        max-width: 950px;
        margin: 0 auto;
        padding: 0 $container-inside-padding;

        @include media ("<=medium") {
            padding: 0 $mobile-container-padding;
        }
    }

    &__title {
        margin: 0;
        font-weight: 500;
        font-size: calc-rem(28);
        line-height: 25px;
    }

    &__information {
        @extend .search-section-information;
        line-height: 25px;
    }
}
