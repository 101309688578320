.accordion-container {
    width: 100%;

    .accordion-content {
        width: 100%;
        text-align: center;
        padding: $container-inside-padding 0;
    }

    .accordion-title {
        font-size: calc-rem(24);
        color: $intervenable-title-color;
        text-align: center;
        margin: 0;
        line-height: 1.4;
    }
}

.accordion-content {
    &__section {
        width: 100%;
        padding: $mobile-container-padding 0;
        text-align: left;
        border-bottom: 1px solid #DDDDDD;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        i {
            margin-right: $container-inside-padding;
            color: #000000;
            font-size: 18px;
            cursor: pointer;

            @include media ("<=small") {
                margin-right: 10px;
            }
        }

        .title {
            font-size: 20px;
            color: #000000;
            line-height: 24px;
            margin: 15px 0;
        }

        .description {
            font-size: 16px;
            color: #838383;
            text-align: left;
            width: 80%;
            max-height: 0;
            transition: max-height 0.3s ease-out;
            overflow: hidden;

            &.active {
                max-height: 1600px;
                transition: max-height 0.5s ease-in;
            }
        }

        .section-description {
            margin: 0;
            line-height: 24px;
        }
    }

    &__header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.accordion-section{
    max-width: 850px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: left;
    margin: 20px auto;
    border: none;
        

    &__title-button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 15px 0;

        .title{
            font-size: calc-rem(20);
            letter-spacing: 0px;
            color: #444444;
            font-weight: 500;
        }

        .symbol{
            font-size: calc-rem(15);
            font-weight: bolder;
            color: #5b7bff;
            margin: 0 10px;
            transition: transform 0.6s ease;
        }

        .symbol-rotate{
            font-size: calc-rem(15);
            font-weight: bolder;
            color: #5b7bff;
            margin: 0 10px;
            transform: rotate(180deg);
            transition: transform 0.6s ease;
        }
    }

    &__title-button:focus{
        outline: none;
    }

    &__content{
        background-color: white;
        overflow: hidden;
        transition: max-height 0.6s ease;

        .accordion-text {
            font-size: calc-rem(18);
        }
    }
}