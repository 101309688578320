// -----------------------------------------------------------------------------
// This file contains styles that are specific to the dashboard
// -----------------------------------------------------------------------------

@include media("<=xlarge") {
    .desktop-only {
        display: none;
    }
}

.app-filters {
    color: #8493a8;
    display: flex;
    margin-bottom: 50px;

    &__categorys, &__price {
        flex: auto;
    }

    &__price {
        text-align: right;
        align-self: center;

        div {
            cursor: pointer;
            margin-left: 16px;
        }

        div:nth-child(2){
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        div:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 0;
        }
    }

    &__list {
        padding: 0 0 0 10px;
    }
}

.app-list {
    margin-bottom: 50px;

    &__title {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $border-color;
        position: relative;
        margin-bottom: 50px;
        font-size: calc-rem(17);

        &:before {
            @include media(">medium") {
                content: '';
                position: absolute;
                border-top: 1px solid $border-color;
                width: calc(50% - 80px);
                left: 0;
                top: 50%;
            }
        }

        
        &:after {
            @include media(">medium") {
                content: '';
                position: absolute;
                border-top: 1px solid $border-color;
                width: calc(50% - 80px);
                right: 0;
                top: 50%;
            }
        }
        
    }
}

.app-list-item {
    padding: 0 $container-inside-padding/2;


    &__image-container {
        position: relative;
    }

    &__image {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &__image-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &--featured {
            background: linear-gradient(to bottom, rgba(26,11,140,0.4) 0%,rgba(250,80,203,0.4) 100%);
        }

        &--lifestyle {
            background: linear-gradient(to bottom, rgba(251,71,50,0.5) 0%,rgba(254,233,73,0.5) 100%); 
        }

        &--meantal-wellbeing {
            background: linear-gradient(to bottom, rgba(70,162,52,0.5) 0%,rgba(247,165,8,0.5) 100%); 
        }

        &--immune-system {
            background: linear-gradient(to bottom, rgba(0,195,255,0.5) 0%,rgba(255,255,28,0.5) 100%); 
        }
    }

    &__title {
        position: absolute;
        color: #fff;
        font-size: calc-rem(25);
        line-height: calc-rem(27);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    &__content-container {
        background-color: #fff;
        text-align: center;
        padding-bottom: $container-inside-padding;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &__excerpt {
        padding: $container-inside-padding;
        min-height: 132px;
    }

    &__coming-soon {
        color: $brand-color;
        text-transform: uppercase;
    }

    &__cash-credits-container {
        div:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        div:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 0;
        }
    }

    .price-box {
        margin-left: 16px;
    }
}


.app-detail {
    &__2cols {
        display: flex;
        align-items: flex-start;
        //flex-wrap: wrap;
        margin-bottom: 50px;
    }

    &__col {
        flex: calc(50% - #{$container-padding});
        

        &--left {
            margin-right: $container-inside-padding;
        }
        &--right {
            margin-left: $container-inside-padding;
        }

        &--image-container {
            max-width: 50%;
        }
    }

    &__intro-text {
        font-weight: 300;
        font-size: calc-rem(18);
    }

    &__btn {
        margin-right: $container-inside-padding;
    }

    &__background-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        //background: url(../img/apps_tmp/pollution-image.jpg) center center / cover no-repeat;
        height: 385px;
        width: 100%;
        border-radius: 5px;
    }
}


.app-more-information {

    &__title {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $border-color;
        position: relative;
        margin-bottom: 50px;
        font-size: calc-rem(17);

        &:before {
            @include media(">medium") {
                content: '';
                position: absolute;
                border-top: 1px solid $border-color;
                width: calc(50% - 120px);
                left: 0;
                top: 50%;
            }
        }

        &:after {
            @include media(">medium") {
                content: '';
                position: absolute;
                border-top: 1px solid $border-color;
                width: calc(50% - 120px);
                right: 0;
                top: 50%;
            }
        }
    }

    &__box {
        text-align: center;
    }

    &__box-title {
        margin: 0;
        margin-bottom: 20px;
    }


    &__science-box {
        background: $background-alternative-colour url(../../img/science-bg-homepage.png) top center no-repeat;
        color: #fff;
        padding: $container-inside-padding;
        border-radius: 5px;
    }
}

.app-limitations {
    &__title {
        margin: 0 0 $container-inside-padding 0;
        font-size: calc-rem(18);
        color: #000;
    }

    &__impact-type {
        color: $brand-pink-colour;
    }

    &__image {
        border-radius: 5px;
        max-width: 100%;
    }

    &__graph-container {
        position: relative;
        top: -62px;
        left: -20px;
        margin-bottom: -35px;
    }

    &__impact-container {
        position: absolute;
        left: 138px;
        top: 82px;
    }

    &__graph-label {
        position: absolute;
        top: 94px;
        left: 53px;
        font-weight: bold;
        color: #000;
    }

    ul {
        margin: 0;
        padding: 0 $container-inside-padding;
    }
}



.slider-container {
    max-width: 350px;

    .rc-slider-track {
        background-color: #55D069;
    }

    .rc-slider-handle {
        border: 2px solid #55D069;
    }
}


.app-installed {
    background-color: #fff;
    padding: $container-inside-padding;
    border-radius: 5px;

    &__title {
        position: relative;

        a {
            color: $text-color;
            //text-decoration: underline;
        }

        img {
            max-width: 59px;
            max-height: 59px;
            vertical-align: middle;
            margin-right: $container-inside-padding;;
        }

        /*
        &:before {
            content: '';
            position: absolute;
            width: 59px;
            height: 59px;
            background-position: 0 0;
            margin: auto;
            background-image: url(../img/what-do-sprite.png);
            background-size: 400%;
            background-position: -59px 0;
            //display: inline-block;
            left: 0;
            top: -10px;
        }
        */
    }

    &__gauge {
        display: block;
        max-width: 100px;
    }

    &__2cols {
        display: flex;
        align-items: flex-start;
        //flex-wrap: wrap;
        margin-bottom: 50px;
    }

    &__col {
        flex: calc(50% - #{$container-padding});

        &--left {
            margin-right: $container-inside-padding;
        }
        &--right {
            margin-left: $container-inside-padding;
        }
    }

    &__box {
        margin-bottom: $container-inside-padding;

        &--science {
            background: $background-alternative-colour url(../../img/science-bg-homepage.png) top center no-repeat;
            color: #fff;
            padding: $container-inside-padding;
            border-radius: 5px;
            height: 526px;
            overflow: scroll;
        }
    }

    &__box-title {
        margin: 0;
        margin-bottom: 20px;
    }

    &__latest-result {
        font-size: calc-rem(30);

        span {
            font-weight: 700;
            font-size: calc-rem(45);
            margin-left: 10px;
            color: $secondary-text-color;
        }
    }

    &__predisposition-key {
        margin: 20px 0;
        span {
            width: 10px;
            height: 10px;
            margin-right: 10px;
            margin-left: 20px;
            display: inline-block;
        }

        &--yours {
            background-color: #7ed868;
        }

        &--high {
            background-color: #fb1e0c;
        }

        &--low {
            background-color: #b3803d;
        }
    }
}

.graph-filter {
    //padding-top: 72px;
    margin: 0 0 $container-inside-padding 0;
    position: relative;
    left: 49px;
    

    &--compare {
        max-width: 373px;
    }

    h4 {
        margin: 0 0 10px -20px;
    }

    input[type=checkbox] {
        margin-right: 5px;
    }

    label {
        margin-right: $container-inside-padding;
    }

    &__item-container {
        width: 50%;
        display: inline-block;
    }
}

.recommended-form {
    max-width: 600px;
    margin: auto;
    text-align: center;
    border: 1px solid $border-color;
    border-radius: 5px;
    padding: $container-inside-padding;

    &__title {
        margin: 10px 0;
    }

    label {
        display: inline-block;
        margin-left: 10px;
    }

    &__select-wrapper {
        width: 150px;
        margin: auto;

        select {
            width: 100%;
        }
    }

    button {
        margin-top: 20px;
    }
}

.recommended-products {
    display: flex;
    justify-content: center;

    &__main-title {
        margin-bottom: $container-inside-padding;
        text-align: center;
        font-size: calc-rem(30);
    }


    &__item {
        flex: 33%;
        max-width: 33%;
        padding: $container-inside-padding;
        text-align: center;
    }

    &__title {
        font-size: calc-rem(17);
        font-weight: 700;
    }

    &__description {
        min-height: 93px;
    }

    &__image-container {
        img {
            max-width: 100%;
        }
    }
}

//Gauge hacks
.app-installed__latest-result {
    .current-value {
        display: none;
    }
}