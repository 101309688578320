%intervenable-anchor-style {
    border-radius: unset;
    color: $white;
    display: block;
    text-transform: uppercase;
}

%intervenable-text-rating {
    &.normal {
        color: $avg-text-color;
    }

    &.good {
        color: $good-text-color;
    }

    &.bad {
        color: $bad-text-color;
    }
}

%intervenable-section-title {
    color: $intervenable-title-color;
    font-size: calc-rem(24);
    font-weight: 500;
    line-height: 63px;
    margin: 0;
}

.intervenable-container {
    color: $intervenable-generic-text-color;
    margin: auto;
    max-width: 1180px;
    padding: 10px 15px 0;
    text-align: center;

    &__title {
        color: $intervenable-title-color;
        font-size: 40px;
        line-height: 63px;
        margin: 11px 0;
        font-weight: 500;
    }

    &__description {
        font-size: 18px;
        line-height: 26px;
        margin: auto;
        max-width: 520px;
    }

    &__active-on-improvement-container {
        margin-top: 20px;
        text-align: end;

        .active-on-improvement-text {
            color: $melrose;
            display: inline-block;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
            width: 110px;
            text-transform: uppercase;
        }

        .active-on-improvement-icon {
            margin-bottom: -6px;
            margin-left: 7px;
        }

        @include media ("<=tinysmall") {
            display: none;
        }
    }

    &__improvement-container {
        border: 1px solid $gainsboro;
        border-radius: 20px;
        color: $black;
        margin: $container-padding 0;
        padding: $mobile-container-padding 0 0 0;
        overflow: hidden;

        .needing-improvement {
            background: url(../../img/you/actionable-you/analysis-background.png) center center/cover no-repeat;
            min-height: calc(450px - 120px);
            padding: 170px 0 30px 0;
            position: relative;

            img {
                display: none;
            }

            &__container {

                p {
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 35px;
                    margin: 0;
                }

                &__result-status{
                    color: $bad-color;
                    font-size: 28px;
                    font-weight: 500;
                    margin: 0;
                    @extend %intervenable-text-rating;
                }
            }

            &__disclaimer-text{
                position: absolute;
                bottom: 10px;
                right: 30px;
                font-style: initial;
                font-size: calc-rem(15);
            }

        }

        &__result {
            border-top: 1px solid $gainsboro;
            display: flex;
            justify-content: space-around;
            min-height: 120px;

            .content {
                align-self: center;

                span:first-child {
                    display: block;
                }

                a {
                    margin-left: 8px;

                    img {
                        margin-bottom: -5px;
                        max-width: 20px;
                    }
                }

                &__title {
                    font-size: 16px;
                    line-height: 33px;
                }

                &__description {
                    font-size: 20px;
                    line-height: 22px;
                    @extend %intervenable-text-rating;
                }
            }
        }


        @include media ("<=tinysmall") {
            margin-top: 50px;

            .needing-improvement {
                background-image: none !important;
                min-height: unset;
                padding: 30px 18px 5px;

                img {
                    display: block;
                    position: absolute;
                    right: -9px;
                    top: -14px;
                }

                &__container {
                    position: static;
                    transform: translateX(0);
                    padding-bottom: 25px;
                }

                &__disclaimer-text {
                    position: static;
                }
            }

            &__result {
                flex-direction: column;
                justify-content: unset;
                padding: 25px 0;

                .content {
                    padding: 15px 0;
                }
            }
        }
    }

    &__implications-container,
    &__mean-container {
        margin: $container-padding/2 0 $container-padding;

        &__title {
            @extend %intervenable-section-title;
        }
        &__description {
            max-width: 700px;
            line-height: 26px;
            margin: auto;
        }

        @include media ("<medium") {
            margin: 35px 0;

                &__description {
                    font-size: 16px;
                }
        }
        &__button {
            margin-top: 15px;
        }
    }

    // Below CSS styles update the fun fact component styles
    .fun-fact-container {
        background-color: $intervenable-fun-fact-background;
        //color: $black;

        &__header {
            color: $intervenable-title-color;
        }
    }

    &__affect-indicators {
        padding: 41px 0;

        &__title {
            color: $intervenable-title-color;
            font-size: 24px;
            line-height: 63px;
            margin: 0;
        }

        &__container {
            display: flex;
            justify-content: space-around;
            padding: 30px 0;

            .affect-indicators-content {
                div {
                    display: inline-block;
                }

                span {
                    color: $black;
                    display: block;
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 10px;
                }

                &__link {
                    @extend %intervenable-anchor-style;
                    background-color: $intervenable-affect-indicator-know-more-background;
                    font-size: 11px;
                    letter-spacing: 0.92px;
                    line-height: 14px;
                    padding: 9px 14px;
                }
            }
        }

        @include media ("<=tinysmall") {
            padding: 41px 20px;

            &__title {
                font-size: 20px;
                line-height: 24px;
            }

            &__container {
                flex-direction: column;
                justify-content: unset;

                .affect-indicators-content {
                    padding: 15px 0;

                    a {
                        width: 110px;
                        margin: auto;
                    }
                }
            }
        }
    }

    &__help-in-improving-card {
        min-height: 450px;
        border-radius: 20px;
        border: 1px solid $gainsboro;
        display: flex;
        margin-bottom: 95px;

        .summary-container {
            width: 50%;
            border-right: 1px solid $gainsboro;
            color: $black;
            padding: 90px 25px;

            img {
                max-width: 125px;
            }

            &__title {
                margin: 57px 0 20px;
                font-size: 30px;
                line-height: 33px;
            }

            &__description {
                line-height: 33px;
                font-size: 22px;
            }
        }

        .detail-container {
            width: 50%;
            background-color: $melrose;
            opacity: 0.75;
            border-radius: 0 20px 20px 0;
            padding: 0 50px;

            &__title {
                color: $white;
                font-size: calc-rem(28);
                line-height: 28px;
                margin: 123px 0 40px;
                font-weight: 500;
            }

            &__description {
                color: $white;
                font-size: 18px;
                line-height: 25px;
            }

            &__improve-link {
                @extend %intervenable-anchor-style;
                background-color: $dodger-blue;
                font-size: 16px;
                letter-spacing: 2px;
                line-height: 16px;
                margin: 50px auto 0;
                padding: 13px 11px 13px 20px;
                width: 230px;
            }
        }

        @include media ("<medium") {
            flex-direction: column;
            margin-bottom: 30px;

            .summary-container {
                padding: 25px 0;
                width: 100%;
                border-right: none;

                &__title {
                    font-size: 20px;
                }
            }

            .detail-container {
                border-radius: 0 0 20px 20px;
                padding: 34px 15px 30px;
                width: 100%;

                &__title {
                    margin-top: 0;
                }
            }
        }
    }

    &__accordian-section {
        padding: 0 0 40px;
        margin-top: 100px;
        @include media ("<medium") {
            margin-top: 35px;
            padding: 0;
        }
    }

    &__bar-graph-section {
        &__graph {
            max-width: 600px;
            position: relative;
            margin: auto;
            height: 350px;
            min-height: 350px;

            @include media ("<=small") {
                .recharts-cartesian-axis-tick-value {
                    font-size: 14px;
                }
            }
        }

        &__title {
            @extend %intervenable-section-title;
        }
    }
}

// Below CSS styles update the footer component styles
.footer-row-bottom__copyright.intervenable-copyright {
    background-color: $melrose;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    padding: 21px 0;
    text-align: center;
}

