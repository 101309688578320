// -----------------------------------------------------------------------------
// This file contains all keyframe you can re-use
// -----------------------------------------------------------------------------

@include keyframes(hero-fade-in) {
    0% { opacity: 0;  }
    100% { opacity: 1;  }
}

@include keyframes(tabs-fade-in) {
    0% { opacity: 0;  }
    100% { opacity: 1;  }
}

@include keyframes(product-slide-down) {
    0% { opacity: 0; transform: translateY(-100%); }
    100% { opacity: 1; transform: translateY(0); }
}

@include keyframes(page-fade-in) {
    0% { opacity: 0.3;  }
    100% { opacity: 1;  }
}

@include keyframes(percentage-bar-in) {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
}

@include keyframes(pulse-good) {
    0% {
        background-color: $good-color;
        -webkit-box-shadow: 0 0 3px #333;
    }
    50% {
        background-color: #91bd09;
        -webkit-box-shadow: 0 0 9px #91bd09;
    }
    100% {
        background-color: $good-color;
        -webkit-box-shadow: 0 0 3px #333;
    }
}

@include keyframes(pulse-bad) {
    0% {
        background-color: $bad-color;
        -webkit-box-shadow: 0 0 3px #333;
    }
    50% {
        background-color: #e33100;
        -webkit-box-shadow: 0 0 9px #e33100;
    }
    100% {
        background-color: $bad-color;
        -webkit-box-shadow: 0 0 3px #333;
    }
}

@include keyframes(pulse-avg) {
    0% {
        background-color: $avg-color;
        -webkit-box-shadow: 0 0 3px #333;
    }
    50% {
        background-color: #ffb515;
        -webkit-box-shadow: 0 0 9px #ffb515;
    }
    100% {
        background-color: $avg-color;
        -webkit-box-shadow: 0 0 3px #333;
    }
}

@include keyframes(show-sub-menu) {
    0% {transform: scaleY(0);}
    100% {transform: scaleY(1);}
}

@include keyframes(show-animation) {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
