$choose-country-container-header-height: 60px;

.ancestry-container {
    margin: auto;
    max-width: 1180px;
    padding: 40px 15px 0;

    &__your-ancestry-container {
        text-align: center;

        &__title {
            color: $skyblue-java-color;
            font-size: 40px;
            line-height: 63px;
            margin: 20px 0;
            font-weight: 500;
        }

        &__description {
            margin: auto;
            max-width: 710px;
            padding: 0 10px;
        }
    }

    &__map-container {
        margin-top: 65px;

        &__header {
            display: flex;
            height: 40px;
            margin: 30px 0;

            &__title {
                align-self: center;
                color: $skyblue-java-color;
                font-size: 24px;
                padding-right: 21px;
            }

            &__border {
                border-left: 1px solid $dark-gray;
                height: inherit;
            }

            &__description{
                align-self: center;
                color: $dark-gray;
                padding-left: 17px;
            }

            @include media ("<medium") {
                flex-direction: column;
                height: auto;
                text-align: center;

                &__title {
                    align-self: center;
                    padding-right: unset;
                }

                &__description{
                    padding-left: 17px;
                }
            }
        }

        &__content {
            display: flex;
            margin-bottom: 85px;

            &__map-detial {
                flex-basis: 75%;
                padding-right: 25px;

                .map {
                    border: 1px solid $gainsboro;
                    border-radius: 20px;

                    > div {
                        margin: auto;
                    }
                }

                .country-detial .country-name {
                    color: $fountain-blue;
                    font-size: 24px;
                    margin: 19px 0;
                }
            }

            &__choose-country {
                border: 1px solid $gainsboro;
                border-radius: 20px 20px 0 0;
                margin-left: 25px;
                width: 350px;

                .choose-country-header {
                    color: $skyblue-java-color;
                    font-size: 22px;
                    height: $choose-country-container-header-height;
                    line-height: 60px;
                    padding-left: 21px;
                }

                .choose-country-body {
                    overflow: auto;
                }
            }

            @include media ("<medium") {
                flex-direction: column;

                &__map-detial {
                    flex-basis: unset;
                    padding-right: 0;
                }

                &__choose-country {
                    margin-left: 0;
                    margin-top: 25px;
                    width: 100%
                }
            }
        }
    }
}

.expandable-list {

    &:first-child {
        header {
            border-top: 1px solid $gainsboro;
        }
    }

    &:last-child {
        header {
            border-bottom: none;
        }

        &.open {
            .expandable-list__content {
                border-bottom: none;
            }
        }
    }

    header {
        border-bottom: 1px solid $gainsboro;
        background-color: $white-smoke;
        color: $fountain-blue;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 22px;
        line-height: 48px;
        padding: 0 18px 0 22px;

        .continental-ancestry-percentage {
            align-self: center;
            background: blue;
            border-radius: 50%;
            color: $white;
            font-size: 16px;
            height: 44px;
            line-height: 48px;
            text-align: center;
            width: 44px;
        }

        .continent-name {
            flex-grow: 1;
            padding-left: 17px;
        }

        .chevron-icon {
            margin-top: 3px;

            i:before {
                transform: rotate(-90deg);
            }
        }
    }

    &__content {
        max-height: 0;
        overflow: hidden;
        transition: all .5s;
    }

    &.open {
        .expandable-list__content {
            max-height: 1140px;
            transition: all 1s;
            border-bottom: 1px solid $gainsboro;
        }

        header {
            border-bottom: 1px solid $gainsboro;
            .chevron-icon {
                i:before {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &__item {
        border-bottom: 1px solid $foam;
        color: $fountain-blue;
        cursor: pointer;
        display: flex;
        //font-size: 16px;
        line-height: 48px;
        padding: 0 18px 0 22px;

        &:last-child {
            border-bottom: none;
        }

        &__country-ancestry-percentage {
            text-align: center;
            width: 44px;
        }

        &__country-name {
            padding-left: 17px;
        }
    }
}
