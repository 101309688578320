.orders-section {

    &__container {
        padding: 50px 29px;
        max-width: 910px;
        margin: auto;
    }

    &__sub-title {
        font-size: calc-rem(28);
        font-weight: 500;
        color: $text-color;
        line-height: 1.4;
        margin: 0;
        margin-bottom: 13px;
    }
}
