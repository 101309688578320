.product-details-container{
    margin: 20px auto 0;
    max-width: 880px;
    padding: 29px;

    @include media("<=small") {
        padding: $container-inside-padding $mobile-container-padding;
    }

    &__title {
        font-size: calc-rem(36);
        line-height: calc-rem(40);
        padding: 0;
        margin: 0;
        font-weight: 500;

        @include media ("<=small") {
            font-size: calc-rem(28);
            line-height: calc-rem(35);
        }
    }

    &__description {
        font-size: calc-rem(20);
        line-height: calc-rem(30);
        color: $text-color;
        margin: $mobile-container-padding 0;
        text-align: left;

        @include media ("<=small") {
            font-size: calc-rem(18);
            line-height: calc-rem(26);
        }
    }

    .product-stage-section{
        padding: 29px 0 0 0;

        &__title{
            font-size: calc-rem(28);
            font-weight: 500;
            color: #444444;
            line-height: 1.4;
            padding: 0 0 13px;
            margin: 0;
        }

        &__container{
            position: relative;
            width: 100%;

            .stage-details{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding-bottom: 15px;

                &__icon{
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    box-shadow: 0px 2px 3px rgba(0,0,0,0.15);
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;

                    img{
                        width: 24px;
                    }

                    .line{
                        height: 55px;
                        width: 2px;
                        background-color: #c5c5c5;
                        position: absolute;
                        top: 50%;
                        z-index: -1;
                    }
                }

                &__description{
                    width: calc(100% - 60px);
                    color: #5B7BFF;
                    font-size: calc-rem(20);
                    line-height: calc-rem(30);
                    margin: 0px;

                    @include media ("<=small") {
                        font-size: calc-rem(18);
                        line-height: calc-rem(26);
                    }
                }

                &__description-selected{
                    width: calc(100% - 60px);
                    color: #5B7BFF;
                    font-size: calc-rem(20);
                    line-height: calc-rem(30);
                    margin: 0px;
                    font-weight: 500;

                    @include media ("<=small") {
                        font-size: calc-rem(18);
                        line-height: calc-rem(26);
                    }
                }

                .selected{
                    border: 2px solid #5B7BFF;
                }

                &:last-child{
                    .line{
                        display: none;
                    }
                }
            }
        }
        
    }

    .graph-section{
        padding: 29px 36px;
        text-align: left;
        color: #444444;

        .graph-container{
            background-color: #f8f8f8;
            border-radius: 10px;
            padding: 13px 25px;
            width: 50%;
            margin-bottom: 29px;

            @include media ("<=medium") {
                width: 100%;
            }
        }

        &__title{
            color: #444444;
            text-align: left;
            margin: 10px 0 0 0;
            font-weight: 500;
        }
    }
    .impact-item-image {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 32px;
            max-height: 32px;
            vertical-align: middle;
        }
    }
}