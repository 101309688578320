// -----------------------------------------------------------------------------
// This file contains all styles related to the tabs component.
// -----------------------------------------------------------------------------

.tabs {
    &__menu {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-end;
        flex-wrap: wrap;
        padding-left: 0;
        border-bottom: 1px solid $border-color;
        padding-bottom: 15px;
        margin: 0 24% $container-inside-padding 24%;

        @include media("<=medium") {
            margin: 0 0 $container-inside-padding 0;
        }

        li {
            flex: 1;
            //padding: 0 10px;
            text-align: center;
            display: block;

            a {
                position: relative;
                text-transform: uppercase;
                transition: all 0.3s ease-in-out;
                padding: 5px auto;
                text-overflow: ellipsis;
                transition: all 0.2s ease-in-out;
                white-space: nowrap;
                -webkit-touch-callout: none;
                color: #8493a8;
                text-decoration: none;
                

                @include on-event {
                    outline: 0;
                    color: $tertiary-text-colour;
                }

                &.active:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -19px;
                    border-bottom: 3px solid $dodger-blue;
                    width: 100%;

                    @include animation('tabs-fade-in 0.5s ease-out');

                    //transform: translate3d(100%, 0px, 0px);
                    //transition: transform 250ms ease-out;
                }
            }
        }
    }

    &__content {
        padding: 20px 0;
        @include animation('tabs-fade-in 1s ease-out');
    }
}