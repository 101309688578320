%ellipsis {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// -----------------------------------------------------------------------------
// This file contains styles that are specific to the basic you page
// -----------------------------------------------------------------------------

.basic-you-page {
    margin: auto;
    max-width: 1180px;
    padding: 36px 15px;
    position: relative;

    @include media("<=tinysmall") {
        padding-top: 0;
    }

    &__image-container {
        border-radius: 50%;
        height: 395px;
        position: absolute;
        right: 43px;
        top: -20px;
        width: 395px;
        z-index: -1;

        @include media ("<medium") {
            height: 315px;
            width: 315px;
        }

        @include media("<=tinysmall") {
            width: 260px;
            height: 260px;
            position: static;
            margin: -15px auto 0;
        }

        img {
            width: 100%;
        }
    }

    &__title-container {
        height: 295px;
        display: flex;
        width: 50%;
        padding-left: 15px;

        > div {
            align-self: center;
        }


        &__header {
            color: $skyblue-java-color;
            font-size: 40px;
            margin: 0;
        }

        &__description {
            font-size: 18px;
            line-height: 25px;
            margin: 0;
            width: 100%;
        }

        @include media ("<medium") {
            height: 220px;
        }

        @include media("<=tinysmall") {
            height: unset;
            padding: 0 15px;
            text-align: center;
            width: 100%;
        }
    }

    &__card-container {
        //color: $black;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;

        // ----------------------------------------------------
        // Below styles are specific to the basic you card
        // ----------------------------------------------------

        .card-wrapper {
            padding: 15px;
            width: 25%;
            @extend %hover-up-animation;

            a {
                color: $text-color;
            }

            @media (max-width: 950px) {
                width: 33.3333%;
            }

            @include media ("<medium") {
                width: 50%;
            }

            @include media("<=tinysmall") {
                width: 100%;
            }

            &__card {
                background-color: $white;
                border: 1px solid $gainsboro;
                border-radius: 9px;
                padding: 20px 15px 33px;
                text-align: center;
                cursor:pointer;

                > div {
                    padding: 21px 0;
                    img {
                        max-width: 100px;
                        max-height: 90px;

                        &.cilantro-taste-aversion {
                            padding: 8.5px 0;
                        }

                        &.eye-color {
                            padding: 15px 0;
                        }

                        &.hair-thickness {
                            padding: 8px 0;
                        }

                        &.misophonia,
                        &.light-or-dark-hair,
                        &.earwax-type {
                            padding: 5.5px 0;
                        }

                        &.red-hair,
                        &.photic-sneeze-reflex {
                            padding: .5px 0;
                        }

                        &.sweet-vs-salty {
                            padding: 7.5px 0;
                        }

                        &.caffeine-consumption {
                            padding: 9.5px 0;
                        }

                        &.deep-sleep,
                        &.genetic-weight,
                        &.lactose-intolerance,
                        &.deep-sleep {
                            padding: 4px 0;
                        }

                        &.sleep-movement {
                            padding: 9px 0;
                        }

                        &.dizygotic-twinning,
                        &.saturated-fat-and-weight,
                        &.skin-pigmentation {
                            padding: 5px 0;
                        }

                        &.muscle-composition {
                            padding: 3.5px 0;
                        }

                        &.early-hair-loss {
                            padding: 1px 0;
                        }

                        &.asparagus-odor-detection,
                        &.bitter-taste,
                        &.ancestry {
                            padding: 4.5px 0;
                        }

                    }
                }

                &__title {
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 500;
                    @extend %ellipsis;
                }

                &__description {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: lighter;
                    @extend %ellipsis;
                }
            }
        }

    }
}
