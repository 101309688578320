.biomarker-modal {
  font-size: 16px;
  text-align: center;
  max-width: 430px;

  &__title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
  }

  &__text {
      white-space: pre-wrap;
      text-align: left;

      a {
          color: #5B7BFF;
          cursor: pointer;
      }

      &.result {
        font-weight: bold;
        color: #8d8d8d;
        
        &.positive {
          color: #F0975B;
        }
    
        &.negative {
          color: #459B64;
        }
      }
  }

  &__buttons {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      button {
          width: 100%;

          &:not(:first-child) {
              margin-left: 0;
              margin-top: 10px;
          }
      }
  }
}
