// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.footer {
    &__container {
        max-width: $max-width;
        margin: auto;
        padding: 24px $mobile-container-padding;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        @include media('>medium') {
            align-items: center;
        }

        @include media('<=medium') {
            flex-direction: column;
            align-items: left;
        }
    }

    &__content-container {
        span {
            font-weight: bold;
            margin-bottom: 16px;
        }

        a {
            text-decoration-line: underline;
            text-decoration-skip-ink: none;
            cursor: pointer;
            font-size: 1.2rem;
        }

        display: flex;
        flex-direction: column;

        @include media('>medium') {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
    }

    &-copyright {
        font-size: calc-rem(18);
        text-align: left;
        line-height: 18px;
    }

    &__powered-by {
        font-family: "Noto Sans Mono", "serif";
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 20px;
        letter-spacing: 2px;
        text-transform: uppercase;

        @include media("<=medium") {
            margin: 2em 0 0;
        }
    }
}

@include media('<=small') {

    // Need to shift chat icon up to avoid overlapping mobile sticky nav at the bottom of the page
    body div#hubspot-messages-iframe-container div.shadow-container+iframe {
        bottom: 60px !important;
    }

    // Chat window goes full screen on mobile widths so needs to be shifted to avoid being rendered partly off-screen
    body div#hubspot-messages-iframe-container div.shadow-container.active+iframe {
        bottom: 0px !important;
    }
}
