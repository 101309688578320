

.article-section {
  background-color: $white;
  min-height: $document-height;
  align-items: center;
  display: flex;
  flex-direction: column;

  .back-button {
    align-self: flex-start;
    cursor: pointer;
    padding-bottom: 13px;
    margin-top: 40px;
    height: 33px;
    width: 28px;

    @include media(">=medium") {
      display: none;
    }
  }

  .section-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;

    @include media(">=medium") {
      flex-direction: row;
      margin-bottom: 60px; // adding a chunk of space below articles
    }
  }

  .button-container {
    align-self: center;
    width: 50%;
  }

  .article {
    display: flex;
    flex-direction: column;
    padding: 0 $mobile-container-padding $mobile-container-padding;

    h3 {
      font-size: calc-rem(28);
      line-height: calc-rem(36);
      font-weight: 500;
      margin: 0;
    }

    .tagline {
      margin-top: 0px;
    }

    @include media(">=medium") {
      padding: 0;
      h3 {
        margin: 40px 0 0;
      }
    }
  }

  quote {
    display: none;
    font-size: calc-rem(25);
    line-height: calc-rem(32);
    font-weight: 500;

    @include media(">=medium") {
      display: flex;
      padding-left: 60px;
      width: 330px;
      min-width: 330px;
    }
  }
}