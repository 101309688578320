.progress-steps {

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__step {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:first-child {
            align-items: flex-start;
        }

        &:last-child {
            align-items: flex-end;
        }

        &:not(&--active) {
            color: #D0D0D0;

            &::before,
            .progress-steps__step-icon {
                color: #EEEEEB;
            }
        }

        &--active {
            .progress-steps__step-icon,
            &::before {
                color: #FFAF4B;
            }
        }

        label {
            font-weight: 500;;
        }

        &:not(:first-child)::before {
            content: "";
            position: absolute;
            top: 19px;
            right: 50%;
            border-bottom: 2px solid currentColor;
            width: 130%;
        }
        &:last-child::before {
            right: 15%;
        }
    }

    &__step-icon {
        margin-bottom: 13px;
        font-size: 40px;
        z-index: 1;

        svg {
            display: block;
        }

        .outline {
            $outline-padding: 5px;
            $outline-width: 2px;

            content: "";
            position: absolute;
            top: -$outline-padding;
            left: -$outline-padding;
            width: calc(100% + #{$outline-padding * 2});
            height: calc(100% + #{$outline-padding * 2});
            border-radius: 100%;
            border-width: $outline-width;
            border-style: solid;
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                border: ($outline-padding - $outline-width) solid white;
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }
        }
    }
}

.progress-step-text {
    &__date {
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: -0.5px;
    }
}
