.disclaimer {
    &__container {
        max-width: 800px;
        margin: 0 auto;
        padding: 52px 0 47px;

        @include media ("<medium") {
            padding: 50px 15px 50px;
        }
    }

    &__content {
        font-size: 14px;
        color: $silver-dark;
        text-align: center;
        line-height: 22px;
        margin: 0;
    }
}
