// -----------------------------------------------------------------------------
// This file contains all styles related to the testomonies component.
// -----------------------------------------------------------------------------


.loader-container {
    min-height: calc(100vh - (85px + 60px + 65px)); // hardcoded values are heights of header, sub-menu bar and footer.
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    display: block;
    margin: $container-inside-padding auto 0 auto;
}

.help-icon {
    color: $brand-color;
}

.hide-mobile {
    @include media ("<=tinysmall"){
        display: none;
    }
}


.share-icons {
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    //justify-content: center;
    align-items: center;

    &__share-text {
        color: $text-color;
        text-transform: uppercase;
    }

    li {
        //flex: 1;
        text-align: center;
        padding: 0 10px;

        a {
            font-size: 20px;
            color: #8a8a8a;
            display: block;

            @include on-event {
                opacity: 0.6;
            }
        }
    }
}


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.progress-bar {
    width: 300px;
    height: 20px;
    border-radius: 9px;
    background-color: #eceff4;
    border: 1px solid $border-color;
    position: relative;
    overflow: hidden;

    &__bar {
        opacity: 0.9;
        display: block;
        height: 20px;
        border-radius: 9px;
        background-color: $secondary-text-color;
        position: absolute;
        top: -1px;
        //left: -1px;

        @include animation('percentage-bar-in 1.5s ease-out 0s 1');  
    }

    &__text {
        position: absolute;
        font-size: calc-rem(13);
        top: -1px;
        padding-left: 5px;
    }
}








/* IOS switch https://codepen.io/aorcsik/pen/OPMyQp */
input[type="checkbox"].switch {
    position: absolute;
    margin: 8px 0 0 16px;    
    cursor: pointer;
}
input[type="checkbox"].switch + label {
    position: relative;
    padding: 5px 0 0 50px;
    line-height: 2.0em;
}
input[type="checkbox"].switch + label:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 40px; /* x*5 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid $border-color;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].switch + label:after {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    top: 0px;
    width: 24px; /* x*3 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid $border-color;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].switch + label:hover:after {
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
}
input[type="checkbox"].switch:checked + label:after {
    margin-left: 16px;
}
input[type="checkbox"].switch:checked + label:before {
    background: #55D069;
}
