// -----------------------------------------------------------------------------
// This file contains styles for printing purposes
// -----------------------------------------------------------------------------


@media print {
    .header--dashboard {
        display: none;
    }

    .wrapper {
        margin-top: 0;
    }

    .sub-menu-bar__container {
        display: none;
    }

    .you-page__wrapper {
        margin-top: 0;
    }

    .intervenable-container__improvement-container {
        .needing-improvement {
            padding-top: 30px;
            min-height: unset;
        }
    }

    .question {
        display: none;
    }

    .accordion-content__section {
        .description, .description .active {
            max-height: 1600px;
        }
    }

    .fun-fact-container__body {
        display: block;

        .fun-fact-container__body__item {
            width: 33%;
            float: left;
            padding: 35px $mobile-container-padding 0;
        }
    }
}
