$you-container-max-width: 1400px;

%take-a-look-image-container {
    width: 567px;
    height: 566px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 15px;
};

%you-page-card-color {
    .actionable-you & {
        color: $intervenable-title-color;
    }

    .basic-you & {
        color: $genetic-you-color;
    }

    .drug-passport & {
        color: $dim-grey;
    }
}

%overlay {
    background: $white;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    z-index: 1;
}

.you-section__card__overlay {
    &--link {
        bottom: 53px;
    }
}


/*
  Below CSS styles are defined for the 'Card' and 'take a look component'
 */

.you-page__container {
    padding-top: 100px;
}

.you-section {
    &__take-a-look-container {
        margin: auto;
        max-width: 1180px;
    }

    &__take-a-look-text-container {
        padding-left: 15px;
        padding-top: 110px;
        position: relative;
        max-width: 550px;
        z-index: 1;

        h2 {
            color: $dodger-blue;
            font-size: calc-rem(50);
            line-height: 63px;
            font-weight: 500;
            margin: 0;
        }

        p {
            color: $black;
            margin: 30px 0;
        }
    }

    &__oval {
        @extend %take-a-look-image-container;
        background-color: $you-take-a-look-overlay-color;
    }

    &__image-container {
        @extend %take-a-look-image-container;
        display: flex;
        z-index: 1;

        div {
            margin: auto;

            img {
                width: 355px;
            }
        }
    }

    &__dna-1 {
        position: absolute;
        top: 5px;
        right: 0;
        transform: rotate(-5deg);
        @media screen and (max-width: 1190px) {
            transform: rotate(-20deg);
        }
    }

    @media screen and (max-width: 970px) {
        &__take-a-look-text-container {
            padding-top: 30px;
            width: 350px;
        }

        &__oval {
            width: 400px;
            height: 400px;
            right: 56px;
            top: 35px;
        }

        &__image-container {
            width: 400px;
            height: 400px;
            right: 56px;
            top: 35px;

            div {
                img {
                    width: 250px;
                }
            }
        }

        &__dna-1 {
            right: -110px;
            transform: rotate(-25deg);
        }
    }

    &__card {
        background: white;
        border-radius: 10px;
        box-shadow: 0 6px 17px rgba(138,138,138,0.5);
        cursor: pointer;
        padding: 25px;
        width: 372px;
        position: relative;
        @extend %hover-up-animation;

        &__overlay {
            @extend %overlay
        }

        &__title {
            line-height: 43px;
            font-size: calc-rem(35);
            margin: 0;
            font-weight: 500;
            color: $dim-grey;
        }

        p {
            margin: 5px 0;
            color: $black;
            line-height: 26px;
            font-size: calc-rem(18);
        }

        &__see-more-link {
            text-align: end;
            position: relative;

            a {
                @extend %you-page-card-color;
                padding-right: 30px;
                &:hover {
                    text-decoration: underline;
                }
            }

            span {
                position: absolute;
                right: 0;
                bottom: -5px;
            }
        }
    }
}

/*
  Below CSS styles are defined for the 'Footer'
 */
.you-footer-section {
    background-color: $dark-blue;
    color: white;
    padding: 0 15px;
    position: relative;
    border-radius: 20px;
    margin: 50px 0 0;


    .you-footer-wrapper {
        margin: auto;
        max-width: $you-container-max-width;
        overflow: hidden;
        padding: 50px 0;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
    }

    .title {
        font-size: calc-rem(40);
        display: inline-block;
        margin: 0;
        font-weight: 500;
        padding-right: 20px;
    }

    .search-input {
        position: relative;
        display: inline-block;
        font-weight: 500;
        vertical-align: super;

        input {
            border: none;
            height: 45px;
            outline: none;
            padding: 0 45px 0 15px;
            min-width: 236px;
            font-size: calc-rem(16);
            font-weight: 500;
            letter-spacing: 0.94px;
            color: $text-color;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $text-color;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $text-color;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $text-color;
            }
        }

        button {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-40%);
            border: none;
            cursor: pointer;
            background-color: white;

            &:active, &:hover, &:focus {
                outline: none;
            }
        }
    }

    p {
        font-size: calc-rem(20);
        line-height: 26px;
        margin: 25px auto;
        max-width: 569px;
        margin-bottom: 0;
    }

    &.locked {
        .search-input {
            button {
                width: 17px;
                padding: 0;
                margin-right: 5px;
            }
        }
    }

    &__overlay {
        @extend %overlay;
    }
}

/*
  Below CSS styles are defined for the to fit all the component (i.e DNA images, blobs, Footer and Cards)
  in Desktop
 */

.you-wrapper {
    margin: auto;
    max-width: $you-container-max-width;
    min-height: 1377px;
    overflow: hidden;
    position: relative;
    $offset-for-blobs: -30px;

    &__blob-0,
    &__blob-1,
    &__blob-2 {
        position: absolute;
        width: 355px;
    }

    &__blob-0 {
        left: $offset-for-blobs;
        top: 590px;
    }

    &__blob-1 {
        right: $offset-for-blobs;
        top: 924px;
    }

    &__blob-2 {
        left: $offset-for-blobs;
        top: 1269px;
    }

    .card-container {
        max-width: 1024px;
        margin: auto;
        min-height: inherit;
        position: relative;
        top: 40px;
        $offset-for-cards: 70px;

        .card-0,
        .card-1,
        .card-2,
        &__dna-1,
        &__dna-2 {
            position: absolute;
        }

        .card-0 {
            left: $offset-for-cards;
            top: 135px;
        }

        .card-1 {
            right: $offset-for-cards;
            top: 460px;
        }

        .card-2 {
            left: $offset-for-cards;
            top: 839px;
        }

        &__dna-1,
        &__dna-2 {
            z-index: -1;
        }

        &__dna-1 {
            right: 399px;
            top: 281px;
            transform: rotate(67deg);
        }

        &__dna-2 {
            left: 345px;
            top: 628px;
        }

        .status-text {
            color: $dodger-blue;
            font-size: calc-rem(18);
            margin: 25px 0;
            line-height: 43px;

            img {
                width: 15px;
                vertical-align: middle;
                margin-top: -7px;
            }
        }
    }
}

/*
  Below CSS styles are defined for the to fit all the component (i.e DNA images, blobs, Footer and Cards)
  in mobile screen
 */

main {
    .device {
        overflow: hidden;
        padding: 0 15px;

        &__dna-report-container {
            &__image-container {
                display: flex;
                justify-content: center;

                .image-container {
                    width: 300px;
                    height: 300px;
                    border-radius: 50%;
                    background-color: #f6f7ff;
                    position: relative;

                    img {
                        width: 195px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            &__take-a-look-container {
                padding: 0;
                text-align: center;

                h2 {
                    font-size: calc-rem(28);
                    line-height: 36px;
                    color: $dodger-blue;
                    font-weight: 500;
                    margin: 0;
                }

                p {
                    font-size: calc-rem(18);
                    line-height: 24px;
                    color: $black;
                }
            }
        }

        &__content {
            &__image-container {
                text-align: center;
                img {
                    width: 310px;
                    height: 310px;
                }
            }


            &__card-container {
                position: relative;
                width: 100%;
                margin: -25px auto 100px;
                max-width: 400px;

                .status-text {
                    font-size: calc-rem(15);
                    line-height: 35px;
                    color: $dodger-blue;
                    margin: 25px 0;

                    img {
                        width: 15px;
                        vertical-align: middle;
                        margin-top: -7px;
                    }

                    h3 {
                        text-align: center;
                    }
                }
            }
        }
    }

    .you-footer-section {
        h3 {
            font-size: calc-rem(25);
            line-height: 35px;
        }

        p {
            font-size: calc-rem(16);
            line-height: 24px;
        }

        &__search-container {
            .search-input  input {
                width: 282px;
            }
        }
    }
}

/*
      Below CSS is for the device that are in between desktop and tablets
     */
@media screen and (max-width: 1280px) {
    .you-wrapper {
        .card-container {
            $card-offset-for-breakpoint-1280: 170px;
            .card-0, .card-2 {
                left: $card-offset-for-breakpoint-1280;
            }

            .card-1 {
                right: $card-offset-for-breakpoint-1280;
                top: 563px;
            }

            .card-2 {
                top: 1000px;
            }

            &__dna-1 {
                transform: rotate(99deg);
            }

            &__dna-2 {
                left: 329px;
                top: 780px;
                transform: rotate(-18deg);
            }
        }

        &__blob-2 {
            top: 1450px;
        }

        &__blob-1 {
            top: 1024px;
        }
    }
}

@media screen and (max-width: 1050px) {
    .you-wrapper {
        .card-container {
            $card-offset-for-1050-devices: 230px;
            $dna-offset-for-1050-devices: 285px;
            .card-0,
            .card-2 {
                left: $card-offset-for-1050-devices;
            }

            .card-1 {
                right: $card-offset-for-1050-devices;
            }

            &__dna-1 {
                right: $dna-offset-for-1050-devices;
                top: 301px;
                transform: rotate(110deg);
            }
            &__dna-2 {
                left: $dna-offset-for-1050-devices;
                top: 796px;
                transform: rotate(-25deg);
            }
        }
    }
}
