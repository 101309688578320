// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
    display: inline-block;
    background: $button-colour;
    color: #fff;
    border: none;
    text-shadow:none;
    font-size: calc-rem(15);
    line-height: calc-rem(18);
    padding: 10px 20px;
    font-family: $heading-font-stack;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    opacity: 1;
    position: relative;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 5px;
    box-shadow: 0 11px 28px 0 rgba(0,0,0,0.10);
    text-decoration: none;

    @include transition(opacity .3s ease);

    i {
        font-size: 17px;
        padding-top: 9px;
        text-align: center;
        color: #000;
        background: $button-colour;
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        display: none;
    }

    @include on-event {
        color: #fff;
        opacity: 0.8;
        text-decoration: none;
    }

    transform: translateY(0);
    @include transition(transform .3s ease);
    &:hover {
        transform: translateY(-2px);
        @include transition(transform .3s ease);
    }

    &:disabled,
    &--disabled {
        opacity: 0.5;
        text-decoration: none;
        cursor: not-allowed;
    }

    &--extra-padding {
        padding: 10px 40px;
    }

    &--full-width {
        width: 100%;
    }

    &--inverse {
        background: none;
        border: 1px solid #fff;
        color: #fff;
        i {
            background: none;
            border: 1px solid #fff;
            color: #fff;
            top: -1px;
            right: -1px;
            width: 40px;
            height: 40px;
        }

        @include on-event {
            color: #fff;
            border: 1px solid transparent;
            background: $brand-pink-colour;
            background: linear-gradient(
                to right,
                $brand-pink-colour,
                rgba($brand-pink-colour, 0.3)
            );
            
        }
    }

    &--inverse-pink {
        background: none;
        border: 1px solid $brand-pink-colour;
        color: $brand-pink-colour;
        i {
            background: none;
            border: 1px solid $brand-pink-colour;
            color: $brand-pink-colour;
            top: -1px;
            right: -1px;
            width: 40px;
            height: 40px;
        }

        @include on-event {
            border: 1px solid transparent;
            background: $brand-pink-colour;
            color: #fff;
            i {
                border: 1px solid #fff;
                color: #fff;
            }
        }
    }

    &--light-green-1 {
        background: $light-green-1;

        i {
            background-color: $light-green-1;
        }
    }

    &--green {
        background: $green;
        background: linear-gradient(
            to right,
            $green,
            rgba($green, 0.3)
        );

        i {
            background-color: $green;
        }
    }

    &--orange {
        background: $orange;
        background: linear-gradient(
            to right,
            $orange,
            rgba($orange, 0.3)
        );

        i {
            background-color: $orange;
        }
    }

    &--no-icon {
        padding: 10px 40px;
    }

    //Matches form input height: 
    &--inline-small {
        padding: 7px 14px;
    }

    &--red {
        background: $intervenable-affect-indicator-know-more-background;
    }

    &--yellow {
        background: $yellow-button-colour;
    }

    &--pale-orange {
        background: $pale-orange;
    }

    &--white {
        background: $white;
        color: $text-color;

        @include on-event {
            color: $text-color;
        }
    }
}
