// -----------------------------------------------------------------------------
// This file contains styles that are specific to the input field component
// -----------------------------------------------------------------------------

.input-field {
  &__container {
    position: relative;
    margin: 20px 0 0 0;

    .form__helper {
      color: #444444;
      text-align: left;
      font-size: 12px;
      text-indent: 15px;
      padding-top: 3px;
    }

    .input-field {
      border: none;
      border-bottom: 2px solid #cccccc;
      background-color: $white;
      padding: 10px 15px;
      border-radius: 0;
      width: 100%;
      font-size: calc-rem(16);
      color: $text-color;

      &:focus,
      &:active,
      &:hover {
        outline: none;
        background-color: $white;
        box-shadow: none;
      }

      &:disabled {
        opacity: 0.3;
      }
    }

    .input-field:focus ~ .input-field__label,
    .input-field__label.valid {
      top: -25px;
      left: 0;
      font-size: calc-rem(16);
      opacity: 1;
      font-weight: 300;
    }
  }

  &__label {
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 5px;
    transition: 0.2s all;
    letter-spacing: 1.26px;
    opacity: 0.6;

    &__select {
      left: 20px;
    }
  }

  &__helper-label {
    color: #909090;
    font-size: 14px;
    line-height: 18px;
    height: 20px;
    padding-top: 3px;
  }

  &__icon {
    position: absolute;
    right: 5px;
    color: #444444;
    opacity: 0.6;
    cursor: pointer;
    top: 3px;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
