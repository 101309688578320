.fun-fact-container {
    background-color: $aqua-spring-light;
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: $container-inside-padding;
    text-align: center;
    margin: auto;

    &__header {
        color: $skyblue-java-color;
        font-size: calc-rem(24);
        margin: 30px 0 0 0;
    }

    &__body {
        display: flex;
        justify-content: space-around;
        padding: 0 29px;
        color: #000000;

        @include media ("<medium") {
            flex-wrap: wrap;
        }

        &__item {
            flex-basis: 32%;
            padding-top: 35px;
            font-size: calc-rem(16);

            @include media ("<medium") {
                flex-basis: 100%;
            }

            p {
                line-height: 1.4;
                margin: 16px 0 5px;
            }
        }
    }
}

.fun-fact {
    &__title {
        font-weight: 500;
        margin: 10px 0;
    }

    &__icon-container {
        margin-bottom: 10px;
        min-height: 125px;
        position: relative;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__link {
        color: #444444;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
