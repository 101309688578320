// -----------------------------------------------------------------------------
// This file contains styles that are specific to the tests component
// -----------------------------------------------------------------------------

%card {
    box-shadow: 0 5px 34px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
}

.tests {
    &__container {
        max-width: 910px;
        padding: 0 $container-inside-padding 40px;
        margin: 0 auto;

        @include media ("<=small") {
            padding: 0 $mobile-container-padding $container-padding;
        }
    }

    &__card-wrapper {
        margin: 29px 0 0;
        display: inherit;
        text-decoration: none;
    }

    &__card {
        @extend %card;
        margin: $container-inside-padding 0 0;
        padding: 20px $container-padding;
        cursor: pointer;
        background-color: $white;
        color: $text-color;
        display: inherit;

        &--register-card {
            background-color: $dodger-blue;
            color: $white;

            .tests__card-title {
                i { display: none; }
            }

            @include media ("<=small") {
                .tests__card-wrapper-header {
                    display: block;
                    margin: 0;
                    text-align: center;
                }

                .tests__card-title {
                    i { display: inline; }
                }

                .tests__card-description {
                    display: none;
                }
            }
        }

        &--grey-background {
            background-color: #D5D5D5;
        }

        &--no-shadow {
            box-shadow: none;
            border: 1px solid $text-color;
            cursor: default;
            pointer-events: none;
            background-color: $white;
        }

        &--no-result {
            cursor: auto;
            pointer-events: none;
        }

        @include media ("<=medium") {
            padding: 20px 40px;
        }

        @include media ("<=small") {
            padding: 20px $container-inside-padding;
        }
    }

    &__card-title {
        font-size: calc-rem(20);
        margin: 0;
        font-weight: 500;
    }

    &__card-description {
        font-size: calc-rem(20);
        text-decoration: underline;
        margin: 0;

        &.view-link {
            font-weight: 500;
        }

        @include media ("<=small") {
            margin: 10px 0 0;

            &.view-link {
                margin: 0;
            }
        }
    }

    &__card-wrapper-title {
        font-size: calc-rem(24);
        margin: 0;
    }

    &__card-title-section {
        width: 50%;
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;

        @include media ("<=small") {
            display: block;
            width: auto;
        }
    }

    &__card-wrapper-description {
        max-height: 0;
        transition: max-height 0.3s ease-out;
        overflow: hidden;
        display: flex;

        &.active {
            max-height: 400px;
            transition: max-height 0.5s ease-in;
        }

        @include media ("<=medium") {
            flex-wrap: wrap;
        }
    }

    &__card-wrapper-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 10px 0;

        &.no-result {
            justify-content: center;
            margin: 0;
        }
    }

    &__indicator {
        background-color: $white;
        padding: $mobile-container-padding;
        font-size: calc-rem(18);
        color: $text-color;
        margin: 10px;
        line-height: 1;
        border-radius: 4px;
        display: flex;
        width: 200px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.grey-background {
            background-color: #D5D5D5;
        }

        @media (max-width: 493px) {
            margin: 10px 0;
        }
    }
}
