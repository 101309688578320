.covid-result{

    min-height: $document-height;

    &__container{
        padding: 50px $container-inside-padding;
        max-width: 910px;
        margin: auto;

        @include media("<=medium") {
            padding: $container-padding/2 29px;
        }
    }

    &__title {
        font-size: calc-rem(36);
        color: $text-color;
        line-height: calc-rem(40);
        margin: 0;
        margin-bottom: $container-inside-padding;
        font-weight: 500;

        @include media ("<=small") {
            font-size: calc-rem(28);
            line-height: calc-rem(35);
        }
    }

    &__test-details-container{
        width: 100%;
    
        .covid-result-indicator{
            border-radius: 10px;
            background-color: #f8f8f8;
            text-align: center;
            font-size: calc-rem(24);
            line-height: calc-rem(30);
            padding: 10px;
            text-transform: uppercase;
            font-weight: bold;

            @include media("<=medium") {
                font-size: calc-rem(18);
            }
        }

        .test-result-download{
            display: flex;
            justify-content: flex-end;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 8px;
            color: #006ed2;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .positive{
            color: #F0975B;    
        }

        .negative{
            color: #459B64;
        }

        .inconclusive{
            color: #8d8d8d;
        }

        .test-rerun {
            text-align: center;
            font-style: italic;
        }

        .lab-info {
            text-align: center;
            font-weight: bold;
        }

        .test-details{
            margin: 30px auto;
            width: 50%;

            @include media("<=medium") {
                width: 100%;
            }

            .details-row{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 0;

                p{
                    margin: 0px;
                    font-size: calc-rem(20);
                    color: #444444;

                    @include media("<=medium") {
                        font-size: calc-rem(16);
                    }
                }
            }
        }

        .test-result-info{
            width: 100%;
            margin: 45px 0;

            .info-title{
                font-size: calc-rem(28);
                line-height: calc-rem(24);
                color: #444444;
                font-weight: 500;
                padding: 0 0 13px;
                
                @include media("<=medium") {
                    font-size: calc-rem(18);
                    line-height: calc-rem(28);
                }
            }
            
            .info-discription{
                font-size: calc-rem(18);
                line-height: calc-rem(26);
                background-color: $background-alternative-colour;
                border-radius: 10px;
                padding: $mobile-container-padding 25px;
                display: inline-block;
                margin: 20px 0;

                @include media("<=medium") {
                    font-size: calc-rem(18);
                }
            }

            .info-help {
                text-align: center;
                font-weight: 500;
            }

            .info-disclaimer {
                font-size: calc-rem(15);
                text-align: center;
            }

            .test-history-container-align{
                text-align: center;
            }
            
            .test-history-container{
                border-radius: 10px;
                box-shadow: 0px 3px 7.5px rgba(0,0,0,0.1);
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 20px;
                font-size: calc-rem(20);
                line-height: calc-rem(24);
                margin: 30px 0;
                font-weight: 500;
                text-transform: none;
                margin-top: 0;

                @include media("<=medium") {
                    font-size: calc-rem(18);
                }

                &__status-container p {
                    text-transform: capitalize;
                }
            }
        }
    }
    
    .selection-container{
        text-align: center;
        margin: 50px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media("<=medium") {
            flex-direction: column;
        }

        .selection-card{
            background-color: #ffffff;
            color: #666;
            box-shadow: 0 2px 6.5px rgba(0,0,0,.1);
            overflow: hidden;
            border-radius: 8px;
            flex-grow: 1;
            flex-basis: calc((100% / 3) - 20px);
            max-width: 48%;

            @include media("<=medium") {
                max-width: 100%;
                flex-basis: 100%;
                margin: 20px 0;
                width: 100%;
            }
        
            &__image{
                background-size: contain;
                min-height: 176px;
                background-repeat: no-repeat;
                background-position: 50%;
                width: 100%;
                background-color: #ffffff;
                filter: contrast(0.7);
            }
            
            &__description-box{

                position: relative;
                padding: 15px 20px 20px 20px;
                min-height: 200px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                p{
                    font-size: calc-rem(18);
                    line-height: calc-rem(26);
                    margin: 5px 0;
                }

                .title {
                    font-weight: 500;
                }
            }
        }

        .selection-card:hover{
            background-color: #f9f8f8;

            .btn{
                transform: scale(1.05);
            }
        }
    }
}