// ------------------------------------------------------__input-container-----------------------
// This file contains styles that are specific to the invite friends page
// -----------------------------------------------------------------------------

.invite-friends {
    &__container {
        padding: 50px $container-inside-padding;
        max-width: 910px;
        margin: auto;

        @include media("<=medium") {
            padding: $container-padding/2 $mobile-container-padding;
        }
    }

    &__title {
        font-size: calc-rem(36);
        color: $text-color;
        line-height: calc-rem(40);
        margin: 0;
        font-weight: 500;
        padding: 0 0 $container-inside-padding;
    }

    &__information-section {

        &.share-section {
            margin: $container-inside-padding 0 $mobile-container-padding;
        }

        .information-title {
            font-size: calc-rem(24);
            line-height: 1.4;
            margin: 0;
        }

        .information-description {
            font-size: calc-rem(18);
            line-height: 1.4;
            margin: $mobile-container-padding 0 20px;
        }
    }

    &__form-container {
        max-width: 650px;

        .form__error {
            margin: -20px 0 0;

            @include media ("<=small") {
                margin: -10px 0 0;
            }
        }
    }

    &__success-container {
        width: 100%;
        max-width: 650px;
        padding: $container-inside-padding 0;

        .success-message {
            font-size: calc-rem(24);
            font-weight: 500;
            margin: 0;
        }
    }

    &__input-container {
        width: 100%;
        max-width: 650px;
        padding: $container-inside-padding 0;
        display: flex;



        .input-field {
            font-size: calc-rem(18);
            line-height: 1.4;
            border: 1px solid $text-color;
            border-right: none;
            border-radius: 5px 0 0 5px;
            height: 50px;
            vertical-align: top;
            padding: 0 20px;
            width: 70%;

            &--full-border {
                border-right: 1px solid $text-color;
                border-radius: 5px;
            }

            &:disabled {
                cursor: text;
            }

            &:focus, &:active {
                outline: none;
            }

            &::placeholder {
                color: #A5A5A5;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: #A5A5A5;
            }

            &::-ms-input-placeholder {
                color: #A5A5A5;
            }
        }

        .btn {
            height: 50px;
            border-radius: 0 5px 5px 0;
            transition: none;
            transform: none;
            width: 30%;

            @include on-event {
                opacity: 1;
            }
        }

        .copy-text {
            display: inline-block;
            width: 70%;

            .input-field {
                width: 100%;
            }
        }

        @include media ("<=small") {
            display: block;
            padding: $mobile-container-padding 0;

            .copy-text {
                width: 100%;
            }

            .input-field {
                width: 100%;
                border-right: 1px solid $text-color;
                border-radius: 5px;
            }

            .btn {
                width: 100%;
                border-radius: 5px;
                margin: 20px 0 0;
            }
        }
    }

    &__icons {
        display: flex;
        padding: 0 0 0 15px;

        .icon-container {
            width: 50px;
            height: 50px;
            vertical-align: top;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px 0 0;
            border-radius: 50%;
            color: $white;
            font-size: $container-inside-padding;
            cursor: pointer;

            &.ellipsis {
                border: 1px solid $text-color;
            }

            .ellipsis-item {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin: 0 2px;
                background-color: $text-color;
            }
        }

        .share-button {
            &:last-child {
                .icon-container {
                    margin: 0;
                }
            }
        }

        @include media ("<=small") {
            padding: $container-inside-padding 0 0;
        }
    }

    &__modal-overlay {
        background: rgba(0,0,0,0.50);

        .invite-icons-modal {
            border-radius: 10px;
            padding: $container-inside-padding;
            max-width: 450px;
        }

        .invite-close-button {
            cursor: pointer;
            top: 29px;
            right: 29px;
        }

        @include media ("<=small") {
            background: rgba(0,0,0,0.20);
            box-shadow: 0 4px 12px 0 rgba(0,0,0,0.50);

            .invite-icons-modal {
                position: fixed;
                bottom: 0;
                width: 100%;
                border-radius: 0;
                left: 0;
                max-width: none;
            }
        }
    }

    &__modal-title {
        font-size: calc-rem(18);
        line-height: 1.4;
        margin: 0;
        font-weight: 500;
        display: inline-block;
    }

    &__modal-icons {
        display: block;
        padding: $container-inside-padding 0 0;

        .icon-wrapper {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .icon-container {
                width: 64px;
                height: 64px;
                margin: 0 auto 10px;
                font-size: calc-rem(33);
            }
        }

        .icon-title {
            font-size: calc-rem(10);
            color: #808080;
            letter-spacing: 0;
            text-align: center;
            line-height: 18px;
            text-transform: uppercase;
            margin: 0;
        }

        .icon-left-open-big {
            top: 15px;
            left: -20px;
            position: absolute;
            cursor: pointer;
        }

        .icon-right-open-big {
            top: 15px;
            position: absolute;
            right: -20px;
            cursor: pointer;
        }

        @include media("<=small") {
            .slick-list {
                overflow: visible;
            }
        }
    }
}
