// -----------------------------------------------------------------------------
// This file contains styles that are specific to the chromosome age page
// -----------------------------------------------------------------------------

%line-section {
    position: absolute;
    padding: 0 3px;
    min-width: 20px;
    width: 100%;
    left: 0;
}

.chromosome-age {
    &__container {
        text-align: center;
    }

    &__hero-section {
        max-width: 900px;
        margin: 0 auto;
        padding: 50px $container-inside-padding 0;

        @include media ("<=medium") {
            padding-top: 40px;
        }

        .result-hero__title-information {
            max-width: 800px;
            background: $white;
        }
    }

    &__gauge-chart {
        width: 100%;
        height: 300px;
        max-width: 300px;
    }

    &__overview {
        &__container {
          max-width: 910px;
          margin: 0 auto;

            &.science-container {
                max-width: 910px;
                margin: 0 auto;
                padding: 0 $container-inside-padding;

                .section-description {
                    text-align: center;
                    margin: 10px auto 0;
                    max-width: 600px;
                }

                .down-arrow {
                    max-height: 60px;
                }

                @include media ("<=small") {
                    padding: 0 $mobile-container-padding;
                }
            }

            .section-title {
                font-size: calc-rem(22);
                text-align: left;
                color: #444444;
                @include media ("<=medium") {
                    text-align: center;
                }
            }

            .section-description {
                font-size: calc-rem(20);
                color: #444444;
                text-align: left;
                margin: 10px 0 0 0;

                @include media ("<=medium") {
                    font-size: calc-rem(18);
                }
            }

            .section-button-container {
                text-align: center;
                margin: 50px 0 0 0;
            }

            .biological-age__how-works {
                padding: 0;
            }
        }

        &__section {
            box-shadow: 0 5px 34px 0 rgba(0,0,0,0.10);
            width: 100%;
            max-width: 1110px;
            padding: $container-inside-padding 50px;
            margin: 0 auto;

            .graphs-wrapper {
                margin: 0 auto 0;
                max-width: 750px;
            }

            @include media ("<=medium") {
                padding: $container-inside-padding $mobile-container-padding;

                .section-title {
                    text-align: center;
                }

                .section-description {
                    text-align: center;
                }
            }

            .chromosome-button {
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                font-size: calc-rem(16);
                color: #484848;
                text-align: center;
                padding: 10px 20px;
                cursor: pointer;
                background-color: $white;

                &.ageing-same {
                    background-color: $new-normal-color;
                    color: $white;
                    border: 1px solid $new-normal-color;
                }

                &.ageing-faster {
                    background-color: $new-good-color;
                    color: $white;
                    border: 1px solid $new-good-color;
                }

                &.ageing-slower {
                    background-color: $new-bad-color;
                    color: $white;
                    border: 1px solid $new-bad-color;
                }

                @include media ("<=tinysmall") {
                    margin: 0;
                    padding: 10px;
                }
            }

            .chromosome-buttons-container {
                display: flex;
                justify-content: space-around;
                max-width: 550px;
                margin: 25px auto 50px auto;

                @include media ("<=tinysmall") {
                    //display: block;
                    //margin-top: 0;
                    margin: 0 auto;
                }
            }
        }

        &__information {
            max-width: 1110px;
            margin: 0 auto;
            padding: 0 0 20px;
        }

        &__graph-section {
            margin: 50px auto 0;
            max-width: 650px;

            @include media ("<=tinysmall") {
                margin: 0 auto;
            }
        }
    }

    &__science {
        &__section {
            margin: $container-padding 0 0 0;

            @include media ("<=medium") {
                margin: $container-padding/2 0 0 0;
            }

            &.first-section {
                margin: 0;
            }
        }

        &__dna-section {
            margin: 50px 0;
            position: relative;

            &:first-child {
                margin: 0 0 50px 0;
                padding: 0;
            }

            &--no-margin {
                margin-bottom: -50px;
            }
        }

        &__bar-container {
            padding: 50px 0;
            position: relative;
            margin: $container-inside-padding auto;
            max-width: 600px;
            overflow: hidden;
        }

        &__line-section {
            width: 100%;
            border-bottom: 2px solid $dodger-blue;
            font-size: calc-rem(16);
            letter-spacing: 0.19px;
            color: $dodger-blue;

            &.top {
                border-top: 2px solid $dodger-blue;
                border-bottom: none;
            }

            .chromosome-title {
                min-width: 150px;
                text-align: left;
                color: $dodger-blue;
                font-weight: 500;
            }

            &.single-element {
                border: none;
            }
        }

        &__chromosome-information {
            @extend %line-section;
            bottom: 20px;

            @include media("<=tinysmall") {
                //bottom: 49px;
                .chromosome-age__science__chromosome-line {
                    height: 25px;
                }
            }
        }

        &__chromosome {
            position: absolute;
            width: 100%;
            font-weight: 500;
        }

        &__chromosome-line {
            width: 1px;
            border-right: 2px solid $dodger-blue;
            height: 20px;
            margin: 0 auto;
        }

        &__epigenetic-marker {
            @extend %line-section;
            top: 20px;

            @include media("<=tinysmall") {
                bottom: 20px;
                top: unset;

                .top {
                    border-bottom: 1px solid black;
                    border-top: none;
                }

                .chromosome-title {
                    position: absolute;
                    bottom: 20px;
                    font-weight: 500;
                }
            }
        }

        &__ageing-faster {
            @extend %line-section;
            bottom: 20px;

            .chromosome-title {
                color: $new-good-color;
            }

            .chromosome-age__science__chromosome-line {
                border-right: 2px solid $new-good-color;
            }
        }

        &__ageing-same {
            @extend %line-section;
            bottom: 20px;

            @include media("<=tinysmall") {

                .chromosome-age__science__chromosome-line {
                    height: 25px;
                }
            }

            .chromosome-title {
                color: $new-normal-color;
            }

            .chromosome-age__science__chromosome-line {
                border-right: 2px solid $new-normal-color;
            }
        }

        &__ageing-slower {
            @extend %line-section;
            top: 20px;

            @include media("<=tinysmall") {
                .chromosome-age__science__chromosome-line {
                    height: 25px;
                }
            }

            .chromosome-title {
                color: $new-bad-color;
            }

            .chromosome-age__science__chromosome-line {
                border-right: 2px solid $new-bad-color;
            }
        }

        &__steps {
            max-width: 750px;
            margin: 0 auto;

            @include media("<=small") {
                padding: 0 $mobile-container-padding;
            }
        }

        &__combination {
            max-width: 450px;
            margin: 0 auto;
            font-size: calc-rem(25);
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: $container-inside-padding 0;

            .chromosome-part {
                width: 65px;
                height: 48px;
                border-radius: 10px;
                background-color: #CACACA;
                display: inline-block;
                margin: 0 $mobile-container-padding 0 0;
                position: relative;
            }
        }

        &__related-articles {
            max-width: 900px;
            margin: 0 auto;

            .related-container {
                background-color: $white;
            }

            .related-container__title {
                text-align: left;
                color: $text-color;
                padding: 0 $container-inside-padding;
                margin-left: $container-inside-padding;
            }

            .related-container__sections {
                text-align: left;
            }
        }

        &__part-section {
            display: inline-block;
            height: 12px;
            width: calc(100%/20);
            padding: 0 3px;
            min-width: 20px;
            position: relative;

            @include media ("<=medium") {
                padding: 0 2px;
                min-width: 9px;
            }
        }

        &__bars {
            width: 100%;
            overflow: hidden;
        }

        &__oval-bars {
            display: flex;
            justify-content: center;
            position: relative;

            .chromosome-title {
                color: $dodger-blue;
            }
        }

        &__chromosome-part {
            @extend %line-section;
            bottom: 60px;
        }

        &__changes-bars {
            position: relative;
            display: flex;

            .chromosome-age__science__chromosome-part {
                bottom: 70px;
            }
        }

        &__oval-bar {
            height: 48px;
            width: 14px;
            border-radius: 7px;
            background-color: #CACACA;
            font-size: calc-rem(10);
            color: $white;
            letter-spacing: 0.12px;
            text-align: center;
            display: flex;
            flex-direction: column;
            margin: 0 10px;
            align-items: center;
            position: relative;

            &.large {
                width: 20px;
                height: 62px;
                margin: 0 $mobile-container-padding;
                border-radius: 10px;
                font-size: calc-rem(13.5);

                .chromosome-age__science__chromosome-part {
                    bottom: 70px;
                }
            }

            .coloured-section {
                width: 100%;
                background-color: $dodger-blue;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .bar-code {
                display: flex;
                flex-grow: 1;
                align-items: flex-end;
            }
        }
    }

    &__faq {
        &__container {
            padding: $container-padding $container-inside-padding 0 $container-inside-padding;
            max-width: 910px;
            margin: 0 auto;
        }
    }
}

.gauge-chart {
    &__container {
        width: calc(100%/3);
        padding: 0 $mobile-container-padding;
        display: inline-block;
        vertical-align: top;

        @include media ("<=medium") {
            width: 100%;
            padding: 0;
        }
    }

    &__title {
        font-size: calc-rem(18);
        letter-spacing: 0.22px;
        text-align: center;
        font-weight: 500;
    }

    &__section {
        width: 100%;
        height: 200px;
    }

    &__description {
        font-size: calc-rem(16);
        letter-spacing: 0.19px;
        text-align: center;
    }
}
