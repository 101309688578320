// -----------------------------------------------------------------------------
// This file contains all styles related to the questionnaire component.
// -----------------------------------------------------------------------------

.questionnaire {
  &__wrapper {
    padding: $container-inside-padding 0 $container-padding;
    margin: auto;

    @include media('<=medium') {
      padding: $container-inside-padding $container-inside-padding $container-padding/2;
    }
  }

  &__container {
    padding: $container-inside-padding 0;
    border-radius: 5px;
    max-width: 800px;
    margin: auto;
    position: relative;

    .form__required {
      color: $dodger-blue;
    }
  }

  &__title {
    font-weight: 500;
    font-size: calc-rem(36);
    color: $text-color;
    line-height: calc-rem(40);
    text-align: center;
    padding: 0 $container-inside-padding;

    @include media('<=medium') {
      text-align: left;
    }
  }

  &__intro-text {
    font-weight: 300;
    font-size: calc-rem(18);
    line-height: 26px;
    text-align: center;
    padding: 0 $container-inside-padding;

    @include media('<=medium') {
      text-align: left;
    }
  }

  &__go-back {
    font-size: calc-rem(20);
    color: $text-color;
    text-transform: lowercase;
    line-height: 40px;

    i {
      color: #d5d5d5;
    }

    &.edit-link {
      margin-left: $container-inside-padding;
      text-transform: capitalize;
    }
  }

  &__percentage-bar {
    max-width: $block-text-max-width;
    width: auto;
    margin: 0 auto 50px;
    background: #eaeaea;
    border-radius: 5px;
    border: none;
    height: 8px;

    .progress-bar__bar {
      height: 8px;
      border-radius: 5px;
      top: 0;
    }
  }

  &__required {
    font-size: calc-rem(13);
    position: absolute;
    right: 6px;
    top: -37px;
  }

  &__sub-title {
    margin: 0;
    font-size: calc-rem(15);
  }

  &__progressbar-text {
    margin: auto;
    margin-bottom: 15px;
    max-width: $block-text-max-width;
    color: $secondary-text-color;

    > div {
      display: inline-block;
      width: 70%;
      &:last-child {
        text-align: right;
        width: 30%;
      }
    }

    .bar-title {
      font-size: calc-rem(25);
      margin: 0 0 $container-inside-padding 0;
      color: $header-color;
    }

    .bar-status {
      font-size: calc-rem(25);
      font-weight: lighter;
    }

    .questionnaire-bar {
      font-size: calc-rem(25);
      color: #ffa800;
    }
  }

  &__button-container {
    text-align: center;
    margin: 50px 0 0;
  }

  &__graphs {
    width: 100%;
    background-color: #fff;
    //padding: $container-inside-padding;
    margin: 0;

    .graph-container {
      max-width: 900px;
      margin: auto;
      text-align: center;
      padding: $container-inside-padding;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
      }

      .graph-title {
        margin: 10px 0 0 0;
        font-weight: lighter;
        line-height: 1.2;
      }

      .graph-information {
        font-weight: lighter;
        margin: 30px 0 0 0;
        font-size: calc-rem(25);
        line-height: 1.2;

        > span {
          color: $brand-pink-colour;
        }
      }

      .bar-chart-container {
        width: 100%;
        height: 350px;
        max-width: 600px;
        margin: 50px auto 0;
      }

      .pie-chart-container {
        width: 50%;
        display: inline-block;
        height: 400px;
        vertical-align: middle;

        .graph-data {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
        }

        @include media('<=medium') {
          width: 100%;
          &:last-child {
            height: auto;
          }
        }

        .pie-slice-label {
          width: 100%;
          height: 100%;
          display: table;

          > p {
            margin: 0;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }

    .recharts-wrapper {
      margin: auto;
    }
  }

  &__complete {
    text-align: center;

    .box-title--pink {
      font-weight: lighter;
      margin: 15px 0;
    }

    .questionnaire-complete-text {
      font-size: 18px;
    }

    .continue-btn {
      margin: $container-padding 0 0;
    }
  }

  &__button-link-container {
    .button-section {
      display: inline-block;
      width: 50%;
      float: right;
      text-align: right;
    }

    .btn {
      padding: 0;
      border-radius: 4px;
      font-size: calc-rem(16);
      line-height: 40px;
      width: 100%;
      max-width: 160px;
    }
  }

  &__comparison-wrapper {
    margin: 80px 0 0;
  }

  &__comparison-container {
    color: $text-color;

    .section-title {
      font-size: calc-rem(22);
      color: $text-color;
      text-align: left;
    }
  }

  &__comparison-section {
    .comparison-title {
      font-size: calc-rem(18);
      font-weight: 500;
      margin: $container-inside-padding 0 0;
    }

    .comparison-labels {
      display: flex;
      justify-content: space-between;
      margin: 7px 0 0;

      p {
        font-size: calc-rem(14);
        margin: 0;
      }
    }
  }

  &__comparison-range {
    height: 10px;
    border-radius: 5px;
    background-color: #eaeaea;
    position: relative;
    margin: 56px 0 0;

    .highlighted-range {
      height: 10px;
      border-radius: 5px;
      background-color: $dodger-blue;
      position: absolute;
    }

    .result-section {
      position: absolute;
    }

    .comparison-result {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $white;
    }
  }
}

.comparison-tooltip {
  font-size: calc-rem(14);
  letter-spacing: 0;
  font-weight: 500;
}

.continue-btn {
  font-size: calc-rem(16);
  padding: 16px 30px;
  width: 220px;
}

.section-title {
  color: $dodger-blue;
  margin: 10px 0 0 0;
  font-weight: 500;
  text-align: center;
}

.questionnaire-greetings {
  text-align: center;
  margin-bottom: $mobile-container-padding;

  .greetings-title {
    margin: 0;
    padding: $mobile-container-padding 0;
    font-weight: 500;
    text-align: center;
  }

  .greetings-next-section-button {
    margin: $container-inside-padding 0 0 0;
  }
}

.badge__container {
  .badge-text {
    margin: 0;
    font-weight: lighter;
  }

  .badge-image-container {
    margin: 10px 0 20px;

    img.badge-image {
      max-width: 170px;
    }
  }
}

.questionnaire-tabs {
  max-width: 900px;
  margin: auto;
  padding-top: $container-inside-padding;
  font-size: calc-rem(16);

  @include media('<=medium') {
    flex-direction: column;
    align-items: center;
  }

  li {
    flex: auto;

    a {
      font-weight: 300;
      text-transform: none;
      color: $dodger-blue;
      opacity: 0.5;
      &.active,
      &:hover {
        //font-weight: 500;
        opacity: 1;
        color: $dodger-blue;
        &:before {
          @include media('<=medium') {
            bottom: -7px;
          }
        }
      }
    }

    @include media('<=medium') {
      margin-bottom: 10px;
    }
  }

  &__complete-icon {
    color: #00b300;
  }
}

.questionnaire-form {
  &__row {
    margin-bottom: $container-inside-padding;

    &__answer {
      color: $black;
    }

    &--has-error {
      padding: 10px;
      background-color: #ffebee;
    }
  }
  .form__select-wrapper {
    width: 60%;
    @include media('<=medium') {
      width: 100%;
    }

    select {
      width: 100%;
    }

    &--small {
      width: 100px;
    }
  }

  &__title-label {
    font-size: calc-rem(22);
    margin-bottom: 10px;
    color: #444444;
    line-height: 32px;
  }

  input[type='text'],
  input[type='date'],
  input[type='datetime'],
  input[type='number'],
  input[type='search'],
  input[type='time'],
  input[type='url'],
  input[type='email'],
  input[type='password'],
  input[type='tel'] {
    width: 60%;

    @include media('<=medium') {
      width: 100%;
    }
  }

  textarea {
    width: 60%;
    height: 100px;

    @include media('<=medium') {
      width: 100%;
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 10px;
  }
}

.form input[type='text'].questionnaire-form__other-text {
  margin-left: 13px;
  width: 50%;

  @include media('<=small') {
    width: calc(100% - 26px);
  }
}

.questionare-intro-text {
  background-color: white;
  box-shadow: 0 5px 34px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 7px 15px;

  &__title {
    font-weight: bold;
  }
}

.questionnaire-scale {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__item {
    text-align: center;

    input[type='radio'] {
      margin: auto;
    }
  }

  &__label {
    align-self: flex-end;
  }

  &__value {
    padding-bottom: 5px;
  }
}

.questionnaire-grid {
  width: 100%;
  table-layout: fixed;

  tr {
    display: inline-block;
    width: 100%;
  }

  thead {
    tr {
      display: block;
      position: relative;
    }

    th:first-child {
      //min-width: 90px;
    }

    th {
      padding: 10px;
      display: inline-block;
      word-break: break-word;
    }
  }

  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 300px;

    td:first-child {
      //min-width: 90px;
    }

    td {
      text-align: center;
      padding: 10px;
      display: inline-block;
      word-break: break-word;

      input[type='radio'],
      input[type='checkbox'] {
        margin: 0;
      }

      &.questionnaire-grid__cell {
        cursor: pointer;
      }
    }

    td.questionnaire-grid__row-label {
      text-align: left;
    }
  }

  &__row {
    &:hover {
      background-color: #efefef;
    }
  }

  @include media('<=medium') {
    width: auto;

    tr {
      display: table-row;
    }

    thead th,
    tbody td {
      display: table-cell;
    }
  }
}

.questionnaire-time {
  input[type='text'] {
    display: inline-block;
    width: 50px;
  }

  &__divider {
    margin: 0 5px;
  }
}
.questionnaire-form .questionnaire-time .questionnaire-time__select-wrapper {
  display: inline-block;
  width: 80px;
  margin-left: 10px;
}

.questionnaire-error {
  text-align: center;
  color: $error-red-color;
}

.date-confirmation {
  font-size: calc-rem(15);
  font-weight: bold;
  color: #ff5080;
  letter-spacing: 0;
  padding: 0 $mobile-container-padding;
}

.questionnaire-length {
  &__units-container {
    margin: $mobile-container-padding $mobile-container-padding 0;
    background-color: $white;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    display: inline-block;

    &.slider-unit {
      height: 30px;
      margin: $mobile-container-padding 0 0;
    }
  }

  &__units {
    text-align: right;
  }

  .form__select-wrapper {
    display: inline-block;
  }

  input[type='text'].questionnaire-length__input-inline,
  input[type='number'].questionnaire-length__input-inline {
    display: inline-block;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    background-color: #f8f8f8;
    font-size: calc-rem(16);
    color: $text-color;
    letter-spacing: 1.26px;
    margin: $mobile-container-padding;
    padding: 10px 20px;
    line-height: 22px;
    width: 100px;
  }

  &__unit {
    margin-right: 20px;
  }

  &__length-unit {
    margin-left: 5px;
    margin-right: 20px;
  }

  &__measurement-section {
    color: $text-color;
    margin: $container-inside-padding 0 0;
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: 20px;

    .measurement-section-title {
      font-size: calc-rem(18);
      letter-spacing: 0.19px;
      margin: 0;
    }

    .image-container {
      text-align: center;
      max-width: 120px;
      max-height: 75px;
      margin: $container-inside-padding auto;

      img {
        max-width: 120px;
        max-height: 75px;
        margin: 0 auto;
      }
    }

    .description-container {
      .description-item {
        font-size: calc-rem(16);
        letter-spacing: 0.17px;
        margin: 0 0 10px;
        line-height: 1.4;
      }
    }
  }
}

.questionnaire-previousaddress {
  margin-bottom: 29px;

  &__date-wrapper {
    margin-top: 10px;
  }

  &__to {
    margin-left: 30px;
  }

  input[type='text'].postcode {
    width: 40%;
    margin-right: 20px;
    margin-left: 34px;
  }
  input[type='text'].address {
    width: 250px;
    margin-right: 20px;
    margin-left: 34px;
  }
  .form__select-wrapper {
    width: 40%;
    display: inline-block;
  }
  .form__select-wrapper.from,
  .form__select-wrapper.untill {
    width: 100px;
    display: inline-block;
    margin-left: 10px;
  }
  .form__select-wrapper.area {
    width: 200px;
    display: inline-block;
    margin-left: 10px;
  }
}

.introduction {
  &__wrapper {
    padding: 0 $container-inside-padding;
    text-align: center;

    @include media('<=small') {
      padding: 0 $mobile-container-padding;
    }
  }

  &__title {
    font-size: calc-rem(43);
    line-height: 63px;
    margin: 0 0 $container-inside-padding;
  }

  &__description {
    font-size: calc-rem(20);
    line-height: 40px;
    margin: $mobile-container-padding auto;
    max-width: 680px;

    &.helpful-description {
      margin: $mobile-container-padding auto $container-padding/2;
    }
  }

  &__image {
    img {
      width: 100px;
      height: 100px;
    }
  }

  &__button-container {
    margin: $container-inside-padding 0;

    .btn {
      padding: 0;
      border-radius: 4px;
      font-size: calc-rem(16);
      line-height: 45px;
      width: 220px;
    }
  }

  &__images-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__image-section {
    text-align: center;
    margin: $mobile-container-padding $container-inside-padding $container-inside-padding;

    .image-replacement {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #d8d8d8;
      margin: 0 auto;
    }

    .image-section {
      width: 60px;
      height: 60px;
      margin: 0 auto $mobile-container-padding;

      img {
        max-width: 60px;
        max-height: 60px;
        width: 100%;
      }
    }

    .item-image-title {
      font-size: calc-rem(20);
      line-height: 1.4;
      margin: 0;
    }

    @include media('<=medium') {
      width: calc(50% - 58px);
    }

    @include media('<=small') {
      margin: $mobile-container-padding $mobile-container-padding $container-inside-padding;
      width: calc(50% - 26px);
    }
  }
}
