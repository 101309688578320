// -----------------------------------------------------------------------------
// This file contains styles that are specific to the auto-suggest component
// -----------------------------------------------------------------------------

.autosuggest-container {
    .react-autosuggest {
        &__container {
            display: flex;
            margin-right: 10px;
            position: relative;
        }

        &__suggestions-container {
            display: none;
        }

        &__suggestions-container--open {
            display: block;
            position: absolute;
            top: 45px;
            width: 100%;
            box-shadow: 0 2px 5px 0 #445CBF;
            background-color: #fff;
            max-height: 200px;
            overflow-y: auto;
            z-index: 2;
        }

        &__suggestions-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        &__suggestion {
            cursor: pointer;
            padding: 10px 20px;
            color: $dodger-blue;
            text-align: left;
            font-size: calc-rem(18);
            font-weight: 500;
            border-bottom: 1px solid #e6e5e5;
        }

        &__suggestion--highlighted {
            background-color: #f7f7f7;
        }
    }
}
