$card-border-radius: 20px;

%button {
    border-radius: 18px;
    background-color: #c7c7c7;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: 14px;
    height: 36px;

    @include on-event {
        outline: none;
    }
}

%aspiration {
    border-radius: 10px;
    padding: $mobile-container-padding 18px;
    width: 100%;
    margin: $mobile-container-padding 0;
    font-size: calc-rem(16);
    color: $text-color;
    background-color: #F3F3F3;
    letter-spacing: 0;
    text-align: center;
}

.improvement-container {
    margin: 20px auto 0;
    max-width: 880px;
    padding: $container-inside-padding;

    @include media("<=small") {
        padding: $container-inside-padding $mobile-container-padding;
    }

    &__section-container {
        padding: $container-inside-padding 0;
        
    }

    &__sub-title {
        font-size: calc-rem(28);
        font-weight: 500;
        color: $text-color;
        line-height: 1.4;
        padding: 0 0 $mobile-container-padding;
        margin: 0;
    }

    &__main-description{
        font-size: calc-rem(18);
        color: $text-color;
        margin: $mobile-container-padding 0;
        text-align: left;
    }

    &__results-section {
        background: $white;
        box-shadow: 0 3px 15px 0 rgba(0,0,0,0.10);
        border-radius: 10px;
        margin: 10px 0;
        height: 100%;

        .result-button {
            padding: 15px 20px 20px;
            text-align: center;

            .btn {
                width: 100%;
            }
        }
    }

    &__single-result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F3F3F3;
        padding: 15px 20px;
        color: $text-color;

        &.more-padding {
            padding: 31.5px 20px;
        }

        img {
            transition: transform 0.3s ease;
        }

        &:hover {
            background: #f9f8f8;

            img {
                transform: scale(1.1);
                transition: transform 0.3s ease;
            }
        }

        &:last-child {
            border-bottom: none;
        }

        .result-header {
            display: flex;
            align-items: center;
            min-width: 60%;

            @include media ("<=small") {
                display: block;
                min-width: unset;
            }
        }

        .result-title {
            margin: 0 10px 0 0;
            font-size: calc-rem(18);
            line-height: calc-rem(24);
            color: $text-color;
            font-weight: 500;
            min-width: 50%;


            @include media ("<=small") {
                margin: 0 0 8px;
            }
        }

        .recommendation-image {
            background-color: rgba(91, 123, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 62px;
            height: 62px;
            border-radius: 50%;

            img {
                max-width: 30px;
                max-height: 30px;
            }
        }

        .recommendation-header {
            width: calc(100% - 82px);
            margin: 0 0 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media ("<=small") {
                display: block;
            }
        }

        .recommendation-description {
            font-size: calc-rem(14);
            line-height: calc-rem(18);
            color: $text-color;
            margin: 0;
        }

        p {
            margin: 0;
            @include media ("<=small") {
                span {
                    display: none;
                }
            }
        }
    }

    &__routes-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        @include media("<=small") {
            display: block;
            margin: 0;
        }
    }

    &__title {
        font-size: calc-rem(36);
        line-height: calc-rem(40);
        padding: 0;
        margin: 0;
        font-weight: 500;

        @include media ("<=small") {
            font-size: calc-rem(28);
            line-height: calc-rem(35);
        }
    }

    &__description {
        font-size: calc-rem(24);
        text-align: center;
        line-height: 40px;
        max-width: 650px;
        margin: 0 auto;
        padding: 0 0 $mobile-container-padding;
    }

    &__card {
        display: flex;
        justify-content: space-between;
        flex-basis: calc(50% - 29px);
        flex-grow: 1;
        background-color: $white;
        box-shadow: 0 5px 34px 0 rgba(0,0,0,0.10);
        padding: $container-inside-padding;
        margin: 10px 10px 0;
        border-radius: 10px;
        text-decoration: none;

        &--wide {
            flex-basis: 100%;
        }

        @include media("<=small") {
            width: 100%;
            margin: $container-inside-padding 0 0;
            padding: 25px 22px;
        }

        .information-container {
            padding: 0 $mobile-container-padding 0 0;
            color: $text-color;
            width: 90%;

            .card-information-title {
                font-size: calc-rem(22);
                margin: 0 0 $mobile-container-padding;
                font-weight: 500;
            }

            .card-information-description {
                font-size: calc-rem(20);
                line-height: 30px;
            }

            .card-information-link {
                font-size: calc-rem(20);
                letter-spacing: 1.43px;
                line-height: 14px;
                text-decoration: underline;
                margin: 0;
            }
        }

        .aspiration-goal-section {
            @extend %aspiration;
            min-height: 80px;
            line-height: 1.15;
            font-family: sans-serif;
            background-color: $white;
            border: 1px solid $gainsboro;
            position: relative;

            .aspiration-edit {
                position: absolute;
                top: 10px;
                right: 10px;
                max-width: 35px;
                cursor: pointer;
            }

            .aspiration-text {
                margin: 0;
                padding: 0 35px 0 0;
            }
        }

        .card-image-section {
            font-size: calc-rem(25);
            color: #F05AE4;

            img {
                max-width: 43px;
                max-height: 43px;
            }
        }

        .submit-button {
            border-radius: 5px;
        }

        .aspiration-container {
            text-align: center;
            width: 100%;
        }

        &:hover:not(.no-hover) {
            background-color: #f9f8f8;
            img {
                transition: transform 0.3s ease;
                transform: scale(1.2);
            }

            i {
                transition: font-size 0.3s ease;
                font-size: calc-rem(30);
            }
        }
    }

    &__aspiration-form {
        width: 100%;

        .aspiration-form-section {
            display: flex;
            justify-content: space-between;
        }

        .textarea-container {
            text-align: right;
        }

        &__text-area {
            @extend %aspiration;
            outline: none;
            resize: none;
            border: none;
        }
    }

    &__coaches-container {
        text-align: center;

        &.coaches-report-container{
            max-width: 910px;
            margin: auto;
        }

        &__cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: $container-inside-padding 0 10px 0;
            position: relative;

            .card {
                display: inline-block;
                margin: $container-inside-padding 20px;
                position: relative;
                vertical-align: top;
                flex-basis: calc(100% / 3 - 40px);
                max-width: calc(100% / 2);
                background-color: $white;
                box-shadow: 0 5px 34px 0 rgba(0,0,0,0.10);
                padding: 50px 15px;
                border-radius: 10px;

                &.data-share-preferences {
                    background-color: #d8dfff;
                    padding: 50px 0;
                }

                &.locked-card {
                    opacity: 0.4;
                }

                &._2 {
                    flex-basis: calc(50% - 40px);
                }

                &._1 {
                    flex-basis: calc(100% - 40px);
                }

                &__overlay {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                }

                .locked {
                    position: absolute;
                    right: 10px;
                    cursor: pointer;

                    &.icon-cancel {
                        top: 15px;
                        color: $dodger-blue;

                        &:before {
                            font-size: 30px;
                        }
                    }
                }

                &__data-share-preferences__switch-container {
                    text-align: left;
                    margin: 0 auto;
                    max-width: 85%;

                    .improvement-switch {
                        @extend .compare-switch;
                    }

                    input[type="checkbox"].improvement-switch + label {
                        padding: 0 0 0 70px;
                    }
                    input[type="checkbox"].improvement-switch:checked + label:before {
                        background: $dodger-blue;
                    }
                }

                &__edit-data-sharing-preferences {
                    color: $dodger-blue;
                    cursor: pointer;
                    margin: $mobile-container-padding 0 0 0;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &__member-name {
                    font-size: calc-rem(24);
                    margin: 20px 0 0;
                    text-align: center;
                    font-weight: 500;
                }

                &__occupation {
                    color: #808080;
                    margin-top: 0;
                }

                &__data-share-preferences__description,
                &__description {
                    font-size: calc-rem(15);
                    color: $black;
                    padding: 0 20px;
                    height: 130px;
                    overflow: auto;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-height: calc-rem(21);
                }

                &__member-image {
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 200px;
                    margin: 0 auto;
                    overflow: hidden;
                    width: 200px;

                    &--no-photo {
                        background-color: #D8D8D8;
                        font-size: 50px;
                    }

                    > img {
                        height: 100%;
                        width: 100%;
                    }
                }

                &__chat-with {
                    @extend %button;
                    background-color: #61cec7;
                    border: none;
                    max-width: 100%;
                    min-width: 151px;
                }
            }

            @include media ("<=small") {
                display: block;

                .card {
                    width: 100%;
                    max-width: 100%;
                    padding: $container-inside-padding 0;
                    margin: $container-inside-padding 0;

                    &__data-share-preferences {
                        &__switch-container {
                            max-width: 70%;

                            @include media ("<=tinysmall") {
                                max-width: 85%;
                            }
                        }
                    }
                }

            }
        }
    }

    &__invite-section {
        background-color: $white;
        padding: $container-inside-padding;
        box-shadow: 4px 6px 111px rgba(24,24,25,.08);
        margin: 50px 0 0;
        cursor: pointer;

        .icon-container {
            width: 70px;
            height: 70px;
            background-color: $pale-orange;
            display: inline-block;
            color: $white;
            border-radius: 50%;
            font-size: calc-rem(30);
            line-height: 70px;
        }

        p {
            display: inline-block;
            margin: 0 0 0 $container-inside-padding;
            font-size: calc-rem(25);
            line-height: calc-rem(35);
            font-weight: 500;

            @include media ("<=small") {
                margin: $mobile-container-padding 0 0 0;
            }
        }
    }

    &__help-section {
        padding: 50px 0;
        text-align: center;
    }

    &__modal {
        max-width: 500px;
        border-radius: 6px;
        padding: $container-inside-padding;

        .modal-header {
            margin: 0;
            text-align: center;
            font-weight: 500;
            font-size: calc-rem(29);
        }

        .modal-description {
            margin: $mobile-container-padding 0;
        }

        .modal-buttons-container {
            display: flex;
            justify-content: space-between;
            margin: $container-inside-padding auto 0;
            max-width: 250px;
        }

        .close-button {
            cursor: pointer;
        }
    }

    .biological-age__featured-section {
        margin: $container-inside-padding 0 0;
    }
}

.terms {
    &__checkbox-container {
        padding: $container-inside-padding $mobile-container-padding;

        input {
            cursor: pointer;
        }

        label {
            font-size: calc-rem(15);
            margin: 0 0 0 10px;
            cursor: pointer;

            a {
                text-decoration: underline;
            }
        }
    }
}
