.related-container {
    background-color: $white;
    padding: $container-inside-padding 0;
    text-align: left;

    &__title {
        font-size: calc-rem(24);
        color: $text-color;
        margin: 0 0 0 $container-inside-padding;
        line-height: 1.4;
        text-align: left;
        font-weight: 500;

        @include media ("<=small") {
            margin: 0 0 0 $mobile-container-padding;
        }
    }

    &__sections {
        margin: $container-inside-padding 0 0 0;
        display: flex;
        flex-flow: wrap;
        flex-grow: 1;
    }

    &__related-article {
        padding: $container-inside-padding;
        border-right: 1px solid $gainsboro;
        display: inline-block;
        width: 25%;

        &.articles-in-a-row {
            &-1 {
                width: 100%;
            }

            &-2 {
                width: 50%;
            }

            &-3 {
                width: 33.3333%;
            }

            &-4 {
                width: 25%;
            }
        }

        &:nth-child(4n) {
            border-right: none;
        }

        &:last-child {
            border-right: none;
        }

        @include media ("<=medium") {
            width: 50%;
            padding: $container-inside-padding;

            &.articles-in-a-row {
                &-2, &-3, &-4 {
                    width: 50%;
                }
            }

            &:nth-child(even) {
                border-right: none;
            }
        }

        @include media ("<=small") {
            padding: $container-inside-padding $mobile-container-padding;
        }

        .article-title {
            font-size: calc-rem(20);
            color: $indigo;
            font-weight: 500;
            margin: 0 0 10px 0;
            line-height: 1.4;
        }

        .article-information {
            color: $text-color;
            font-size: calc-rem(16);
            line-height: 22px;
            margin: 0;
        }

        .article-read-more {
            font-size: calc-rem(16);
            font-weight: 500;
            color: $text-color;
            line-height: 43px;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            i {
                &:before {
                    font-size: calc-rem(12);
                }
            }
        }
    }
}
