
// -----------------------------------------------------------------------------
// This file contains all styles related to the chromosome section component.
// -----------------------------------------------------------------------------

.chromosome-section {
    &__container {
        width: 100%;
        overflow: hidden;
    }

    &__section-container {
        display: inline-block;
        width: calc(100%/24);

        @include media ("<=medium") {
            width: auto;
        }
    }

    &__section {
        margin: 0 3px;
        display: inline-block;
        width: calc(100% - 6px);
        text-align: center;
        @include media ("<=medium") {
            width: auto;
        }
    }

    &__bar-container {
        display: flex;
        justify-content: center;
        position: relative;
        @include media ("<=medium") {
            width: auto;
        }
    }

    &__bar {
        background-color: #b98295;
        display: inline-block;
        color: white;
        overflow: hidden;
        position: relative;
        border-radius: 16px;
        margin: 0 2px;
        width: 20px;

        @include media ("<=medium") {
            width: 4px;
            margin: 0 1px;
        }
    }

    &__bar-content {
        width: 100%;
    }

    &__notch {
        width: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        position: absolute;

        &.left {
            border-left: 7px solid #8b314d;
            left: 0;
        }

        &.right {
            border-right: 7px solid #8b314d;
            right: 0;
        }

        @include media ("<=medium") {
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;

            &.left {
                border-left: 1px solid #8b314d;
            }

            &.right {
                border-right: 1px solid #8b314d;
            }
        }
    }

    &__pink-section {
        position: absolute;
        background-color: white;
        width: 100%;
        opacity: 1;
    }

    &__oval-shape {
        border: 4px solid white;
        height: 28px;
        width: 40px;
        border-radius: 14px;
        position: absolute;
        z-index: 1;

        @include media ("<=medium") {
            width: 20px;
        }
    }
}
