// -----------------------------------------------------------------------------
// This file contains all application-wide Sass extends.
// -----------------------------------------------------------------------------


%tick-list {
  list-style: none;
  margin-top: 40px;

  li {
      position: relative;
      margin-bottom: 20px;

      //the .icon-check icons
      &:before {
          font-family: "fontello_icons";
          content: '\e806';
          color: $turquoise-color;
          font-size: 20px;
          position: absolute;
          top: -2px;
          left: -31px;
      }

  }
}


%youtubeContainer {
    height: 0;
    margin-bottom: 40px;
    padding-bottom: 56.5%;    // 16:9
    padding-top: 25px;
    position: relative;
}

%youtubeContainerIframe {
    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

%link-item {
    a {
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $brand-pink-colour;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
            transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
        }

        &:hover, &:active {
            opacity: 1;
            outline: none;
            &:before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);

                @media (max-width: 1199px) {
                    visibility: hidden;
                }
            }
        }


        &.active {
            opacity: 1;
            &:before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);

                @media (max-width: 1199px) {
                    visibility: hidden;
                }
            }
        }
    }
}

%hover-up-animation {
    &:hover {
        transform: translateY(-3px);
        @include transition(transform .3s ease);
    }
}
