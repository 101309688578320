// -----------------------------------------------------------------------------
// This file contains styles that are specific to the you page version 3
// -----------------------------------------------------------------------------

%new-arrow {
  left: calc(100% - 7px);
  bottom: -22px;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-style: solid;
  border-color: transparent;
  border-top-color: $text-color;
  border-width: 12px 7px;
}

.you-page-v3 {
  &__container {
    width: 100%;
    max-width: 910px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 85px);
    padding: 50px $container-inside-padding;
    // margin-top: 50px; //negate the sub menu we dont have on this page?

    &.margin-top-0 {
      margin-top: 0px;
    }

    .page-title {
      font-size: calc-rem(36);
      color: $text-color;
      line-height: calc-rem(40);
      margin: 0;
      font-weight: 500;
    }

    .information-title {
      padding: 0;
    }

    @include media('<=small') {
      padding: 40px $mobile-container-padding;
      .page-title {
        font-size: calc-rem(28);
        line-height: calc-rem(35);
      }
    }
  }

  &__next-test {
    margin: 40px 0 40px;
    width: 100%;

    @include media('<=small') {
      margin: 20px 0 20px;
    }

    .next-title {
      margin: 0 0 10px;
      //color: $text-color;
      color: #8d8d8d;
      font-size: calc-rem(18);
      letter-spacing: 0.26px;
      line-height: 1;
    }

    .next-test-outerbar {
      height: 8px;
      width: 100%;
      background-color: #f3f3f3;
      border-radius: 4px;
    }

    .next-test-innerbar {
      height: 6px;
      width: 75%;
      background-color: $dodger-blue;
      border-radius: 4px;
    }

    .next-due {
      margin: 7px 0 0;
      font-size: calc-rem(18);
      color: #8d8d8d;
      text-align: right;
      line-height: 1;
    }
  }

  &__test-timeline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    padding: 10px $container-inside-padding $container-inside-padding;
    background-color: $white;
    box-shadow: 0px 3px 7.5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 29px 0 0 0;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }

    .single-test-tooltip {
      display: block;
      min-width: 100px;
      //min-height: 50px;
      padding: 5px 7px;
      background-color: #f3f3f3;
      border-radius: 7px;
      position: absolute;
      left: -40px;
      top: 15px;

      .test-info {
        font-size: calc-rem(18);
        letter-spacing: 0.19px;
        text-align: center;
        margin: 0 0 3px;
        font-weight: 500;
      }

      .selected-test-info {
        font-size: calc-rem(16);
        color: #8d8d8d;
        letter-spacing: 0.19px;
        text-align: center;
        margin: 3px 0 0;
        font-weight: 500;
      }
    }

    .single-test-container {
      display: flex;
      flex-direction: column;
      color: $text-color;
      vertical-align: middle;
      cursor: pointer;
      min-height: 50px;
      justify-content: center;
      margin: 0 5px;

      .test-year {
        font-size: calc-rem(16);
        letter-spacing: 0.19px;
        text-align: center;
        margin: 0;
        position: absolute;
        bottom: 10px;
        font-weight: 400;
      }

      .test-point {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #d5d5d5;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
        margin: 0 auto;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        z-index: 1;

        &.active {
          width: 20px;
          height: 20px;
          background-color: $white;
          border: 3px solid $dodger-blue;
        }

        &.white-background {
          background-color: $white;
        }
      }

      &.show-tooltip {
        .test-point {
          background-color: $dodger-blue;
        }
      }
    }

    @include media('<=small') {
      padding: 10px $mobile-container-padding $container-inside-padding;
    }
  }

  &__sections {
    margin: 0 0 50px;

    .you__overlay.show {
      .tooltip-child {
        z-index: 0;
      }
    }
  }

  &__actionable-card {
    width: 100%;
    padding: $container-inside-padding 36px;
    background: $white;
    box-shadow: 0px 3px 7.5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: inline-block;
    margin: 35px 0 0;
    text-decoration: none;

    @include media('<=small') {
      margin-top: 25px;
    }

    &:hover {
      background-color: #f9f8f8;
    }

    .actionable-container {
      width: 100%;
    }

    .indicator-section-title {
      position: relative;
      color: $text-color;

      &.no-status {
        padding: 0;

        &:before {
          display: none;
        }
      }

      &.good:before {
        background-color: $good-text-color;
      }

      &.normal:before {
        background-color: $avg-text-color;
      }

      &.bad:before {
        background-color: $bad-text-color;
      }
    }

    &.flex-card {
      display: flex;

      .test-header {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .indicator-section-title {
        padding: 0 15px 0 0;
      }

      .test-information {
        font-size: calc-rem(20);
        color: #8d8d8d;
        margin: 0;

        &.good {
          color: $good-text-color;
        }

        &.normal {
          color: $avg-text-color;
        }

        &.bad {
          color: $bad-text-color;
        }
      }

      @include media('<=small') {
        justify-content: space-between;

        .test-header {
          width: auto;
        }

        .indicator-section-title {
          padding: 0 0 5px;
          margin: 0;
        }
      }
    }

    @include media('<=small') {
      padding: $mobile-container-padding 22px;
    }
  }

  &__line-graph {
    height: 150px;
    width: 100%;

    svg.recharts-surface {
      cursor: pointer;
    }

    .line-graph-tooltip {
      background-color: #f3f3f3;
      color: #8d8d8d;
      font-size: calc-rem(12);
      padding: 10px 15px;

      .main-tooltip {
        color: $good-text-color;
        font-size: calc-rem(14);

        svg {
          fill: $good-text-color;
          stroke: $good-text-color;
        }

        &.same {
          color: $avg-text-color;

          svg {
            fill: $avg-text-color;
            stroke: $avg-text-color;
          }
        }

        &.up {
          color: $bad-text-color;
          svg {
            fill: $bad-text-color;
            stroke: $bad-text-color;
          }
        }
      }

      .factor-image {
        display: inline-block;
        vertical-align: sub;
        margin: 0 0 0 5px;

        svg {
          max-width: 18px;
          max-height: 18px;
        }
      }
    }

    .recharts-cartesian-axis-tick-line {
      display: none;
    }

    .recharts-text.recharts-cartesian-axis-tick-value .dot-value {
      font-size: calc-rem(18);
    }

    .previous-test-label {
      font-size: calc-rem(22);
      fill: #a5a5a5;
      text-align: left;
      font-weight: 500;

      @include media('<=small') {
        font-size: calc-rem(12);
      }
    }

    @include media('<=small') {
      height: 100px;
    }
  }

  &__test-line {
    width: 50%;
    padding: 0 0 0 15px;

    .methylation-graph__bars {
      padding: $container-padding 0 0;
      margin: 0;

      @include media('<=small') {
        display: none;
      }
    }

    .highlighted-line {
      height: 6px;
      position: absolute;
      background-color: transparent;
    }

    .line-overlay {
      height: 6px;
      position: absolute;
      background-color: $white;
      opacity: 0.6;
    }

    .test-point-container {
      bottom: 18px;
      position: absolute;

      &.arrow {
        &:after {
          @extend %new-arrow;
          border-top-color: #d5d5d5;
        }
      }
    }

    .tooltip-child {
      border: 1px solid $text-color;
      position: relative;
      height: 11px;
      z-index: 1;

      &:after {
        @extend %new-arrow;
      }

      @include media('<=small') {
        height: 12px;
      }
    }

    .trending-arrow {
      text-align: right;
      font-size: calc-rem(14);
      font-weight: 500;
      color: #a5a5a5;
      display: none;

      @include media('<=small') {
        display: block;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    @include media('<=small') {
      width: auto;
    }
  }

  &__years-section {
    width: 100%;
    margin: 0 0 20px;
    display: flex;
    align-items: center;

    .year-title {
      font-size: calc-rem(70);
      line-height: 1.4;
      margin: 0;
      font-weight: 500;
      color: $dodger-blue;
      width: 70%;

      @include media('<=small') {
        width: 50%;
      }
    }
  }

  &__year-information {
    width: 30%;
    font-size: calc-rem(18);
    line-height: 1.4;
    font-weight: 500;
    color: $text-color;

    @include media('<=small') {
      width: 50%;
    }

    .result-delta-text {
      //margin: 0 0 5px;
      margin: 0;
      &.good {
        color: $good-text-color;
      }

      &.normal {
        color: $avg-text-color;
      }

      &.bad {
        color: $bad-text-color;
      }
    }

    .calendar-age {
      margin: 0 0 5px;

      span {
        color: $dodger-blue;
        opacity: 0.7;
      }
    }

    @include media('<=small') {
      font-size: calc-rem(14);
    }
  }

  &__reference-line-graph {
    width: 100%;
    margin: $mobile-container-padding 0 0;

    .line-bar-container {
      width: 100%;
      height: 6px;
      border-radius: 5px;
      background-color: #f3f3f3;
      position: relative;
    }

    .single-bar {
      height: 6px;
      border-radius: 5px;
      background-color: transparent;
      position: absolute;
      top: 0;

      &.active {
        background-color: $dodger-blue;
        opacity: 0.5;
      }
    }

    .you-point {
      width: 6px;
      height: 6px;
      position: absolute;
      border-radius: 50%;
      background-color: $dodger-blue;
    }

    .reference-line {
      width: 6px;
      height: 16px;
      border-radius: 5px;
      background-color: $dodger-blue;
      opacity: 0.4;
      position: absolute;
      top: -5px;
    }

    .reference-line-labels {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 0 0;

      span {
        font-size: calc-rem(14);
        color: #d5d5d5;

        @include media('<=small') {
          font-size: calc-rem(10);
        }
      }
    }
  }

  &__gauge-chart {
    width: 50%;
    height: 140px;
    position: relative;

    .gauge-image {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 40px;
        max-height: 40px;
      }

      @include media('<=small') {
        img {
          max-width: 30px;
          max-height: 30px;
        }
      }
    }

    svg {
      cursor: pointer;
    }

    @include media('<=small') {
      height: 100px;
      width: 100%;
    }
  }
}

.test-tooltip-container {
  min-width: 120px;
  min-height: 50px;
  padding: 5px 7px;
  background-color: #f3f3f3;
  border-radius: 7px;
  position: absolute;
  top: 6px;

  @include media('<=small') {
    top: 15px;
  }

  p {
    text-align: left;
    letter-spacing: 0;
    margin: 0;
    line-height: 1.4;
    font-size: calc-rem(12);
    color: #8d8d8d;
    display: inline-block;
  }

  .tooltip-section {
    text-align: left;
    line-height: 1;
  }

  .test-title {
    &.current {
      font-size: calc-rem(16);
      color: $text-color;
    }

    &.down {
      font-size: calc-rem(16);
      color: $good-text-color;
    }

    &.up {
      font-size: calc-rem(16);
      color: $bad-text-color;
    }

    &.same {
      font-size: calc-rem(16);
      color: $avg-text-color;
    }
  }

  .test-keys {
    text-align: left;
    letter-spacing: 0;
    line-height: 1.4;
    font-size: calc-rem(12);
    color: #8d8d8d;
    text-transform: capitalize;
    display: inline-block;
    margin: 0 10px 0 0;
    position: relative;
    padding: 0 22px 0 0;

    span {
      display: inline-block;
      min-width: 40px;
    }

    .factor-image {
      position: absolute;
      right: 0;
      top: 0;

      svg {
        max-width: 16px;
        max-height: 16px;
      }
    }
  }

  .tooltip-arrow {
    @extend %new-arrow;
    border-top-color: #d5d5d5;
    left: calc(100% - 14px);
    top: 3px;

    &--current {
      border-top-color: $text-color;
    }
  }
}
