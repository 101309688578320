// -----------------------------------------------------------------------------
// This file contains all styles related to the sub menu bar component.
// -----------------------------------------------------------------------------


.sub-menu-bar {
    &__container {
        width: 100%;
        background: #F6F6F5;
        box-shadow: 0 2px 8px 0 rgba(184,184,184,0.50);
        margin: 0;
        position: relative;
        overflow: hidden;
        z-index: 3;
        transform-origin: top;
        @include animation(show-sub-menu 0.5s ease-in-out forwards);

        transition: top 0.5s;
        //&.menu-scroll-up {
        //    top: -60px;
        //}

        &.menu-stick-top {
            top: 0;
        }

        &.previous-test-banner {
            background: #e2e2e2;
            box-shadow: none;

            .sub-menu-bar__section {
                align-items: center;

                .icon-info-circled-alt {
                    display: inline-block;
                    margin: 0 10px 0 0;
                }
            }

            .test-banner-title {
                font-size: calc-rem(17);
                margin: 0;
                font-weight: 500;
            }
        }
    }

    &__titles {
        margin: 0 auto;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        max-width: 1100px;
        height: 60px;
    }

    &__section {
        display: flex;
        margin: 0 10px 0 0;
    }

    &__item {
        @extend %link-item;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 10px;
        position: relative;
        text-transform:capitalize;
        padding: 0 10px;


        a::before {
            background-color: unset;
        }

        @include media("<=tinysmall") {
            margin: 0;
            padding: 0 5px;

            &.internal-link {
                padding: 0 10px;
            }
        }
    }

    &__item-link {
        font-size: calc-rem(17);
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 500;

        &.locked {
            cursor: default;
            &:hover {
                font-weight: normal;
            }
        }

        @include media ("<=tinysmall") {
            > span {
                display: none;
            }
        }
    }

    &__internal-link {
        @extend .sub-menu-bar__item-link;

        &:hover, &.active, &:focus {
            border-bottom: 2px solid #5B7BFF;
        }
    }
}
