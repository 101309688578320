// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

%mobile-menu-link {
  font-size: calc-rem(20);
  color: $text-color;
  width: 100%;
  text-decoration: none;
  height: 50px;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 $container-inside-padding;
}

.header {
  position: relative;
  height: 85px;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  //&.header-scroll-up {
  //    top: -150px;
  //    transition: top 0.5s ease-out;
  //}

  @include media('<=small') {
    height: 56px;
    position: fixed;
  }
}

.header__container {
  max-width: 1100px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;

  @include media('<=small') {
    padding: 0 $mobile-container-padding;
  }

  &--logo {
    display: flex;
    align-items: center;
    min-height: 32px;

    @include media('<=medium') {
      transform: none;
    }
  }

  .menu-covid {
    margin: 0 30px 0;

    text-decoration: none;
    font-size: calc-rem(18);
    opacity: 1;

    &.active {
      border-bottom: 2px solid $dark-blue;
    }

    &:hover,
    &:active,
    &:focus {
      border-bottom: 2px solid $dark-blue;
    }

  }

  &--menu {
    flex: 1;
    text-align: right;
  }

  &.partner-header {
    .header__container--logo {
      position: inherit;
      left: inherit;
      transform: inherit;

      img {
        width: auto;
        max-height: 80px;
        max-width: 222px;
        padding: 20px;

        @include media('<=small') {
          max-height: 50px;
          padding: 0.5em;
        }
      }
    }
  }

  &.affiliate-header {
    max-width: 1000px;
    padding: 0;

    .header__container--logo {
      position: inherit;
      left: inherit;
      transform: inherit;

      img {
        width: auto;
        max-height: 80px;
        padding: 20px;

        @include media('<=small') {
          max-height: 50px;
          padding: 0.5em;
        }
      }
    }
  }
}

.logo {
  width: 220px;
  height: 24px;

  @include media('<=small') {
    height: 20px;
    width: auto;
  }
}

.header--dashboard {
  .header__container--logo {
    a {
      display: flex;
    }
  }
}

//Hide desktop menu
.menu-container--desktop {
  @include media('<=large') {
    display: none;
  }
}

/* Dashboard menu */
.header--dashboard .menu {
  >.item {
    float: left;
    margin: 0;
    padding: 0 50px 0 0;
    position: relative;

    &:nth-child(2) {
      padding-left: 20px !important;
    }

    &:last-child {
      padding: 0;
    }

    a {
      text-decoration: none;
      font-size: calc-rem(18);
      opacity: 1;

      @include media('<=small') {
        font-size: calc-rem(24);
      }

      @include media('>small') {
        &.active {
          border-bottom: 2px solid $dark-blue;
        }

        &:hover,
        &:active,
        &:focus {
          border-bottom: 2px solid $dark-blue;
        }
      }
    }

    @include media('<=small') {
      padding: 0 10px 0 0;

      a {
        border: none;

        &.active {
          border: none;
        }

        @include on-event {
          border: none;
          outline: none;
        }
      }
    }
  }

  >.item--divider {
    border-right: 1px solid $dashboard-link-colour;
  }

  >.item--account {
    a {
      display: block;
      text-transform: uppercase;
    }
  }
}

.mobile-menu {
  &__container {
    position: fixed;
    bottom: 0;
    background-color: $white;
    border-top: 1px solid #979797;
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px 12px 25px;

    .item-link {
      font-size: calc-rem(10);
      color: #7f7f7f;
      text-align: center;

      svg {
        max-width: 26px;
        max-height: 24px;
      }

      &.active {
        color: $text-color;

        svg {
          fill: $text-color;
          stroke: $text-color;
        }
      }

      &.transparent {
        svg {
          fill: transparent;
          stroke: $text-color;
        }
      }
    }

    .link-title {
      margin: 0;
      line-height: 1.2;
      font-size: calc-rem(13);
      font-weight: 500;
    }
  }

  @include media('<=small') {
    padding: 10px $mobile-container-padding;
  }
}

.menu {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  >.item {
    float: left;
    margin-right: 28px;

    i {
      text-align: center;
      font-size: calc-rem(18);

      &.header-search-icon {
        font-size: calc-rem(20);

        @include media('<=small') {
          font-size: calc-rem(23);
        }
      }
    }

    a {
      opacity: 0.5;
      font-size: 17px;

      &.active {
        border-bottom: 2px solid $brand-pink-colour;
        padding-bottom: 7px;
        text-decoration: none;
        opacity: 1;
      }

      @include on-event {
        border-bottom: 2px solid $brand-pink-colour;
        padding-bottom: 7px;
        text-decoration: none;
      }
    }

    .dropdown-wrapper {
      cursor: pointer;

      svg {
        max-width: 20px;
        max-height: 20px;

        // NOTE: this is to keep the person logo + initials + dropdown icon
        // centrally aligned when using the desktop breakpoint.
        @include media('>medium') {
          vertical-align: text-top;
          margin-right: 5px;
        }
      }

      i {
        display: inline-block;
      }

      @include media('<=small') {
        .icon-down-open-big {
          display: none;
        }
      }

      .menu-icon {
        width: 18px;
        height: 12px;
        position: relative;
        margin: 16px auto;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }

      .menu-icon span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $brand-color;
        border-radius: 1px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
      }

      .menu-icon span:first-child {
        top: 0;
      }

      .menu-icon span:nth-child(2) {
        top: 6px;
      }

      .menu-icon span:last-child {
        top: 12px;
      }

      .menu-icon.open span:nth-child(2) {
        opacity: 0;
      }

      .menu-icon.open span:first-child,
      .menu-icon.open span:last-child {
        top: 6px;
        background-color: #1B1D26;
      }

      .menu-icon.open span:first-child {
        transform: rotate(45deg);
      }

      .menu-icon.open span:last-child {
        transform: rotate(-45deg);
      }
    }

    a.nav-search {
      i {
        display: inline-block;
      }
    }
  }

  >.item--separator {
    border-right: 1px solid $border-color;
    line-height: 1.65;
    margin-right: 48px;
    min-height: 1.65px;
    padding-left: 20px;
  }

  >.item--divider {
    border-right: 1px solid $border-color;
    padding-right: 28px;
  }

  >.item--buy a {
    text-transform: uppercase;
    font-weight: 500;
  }

  >.item--login a {
    text-transform: uppercase;
    color: $secondary-text-color;
  }

  &__close {
    color: $form-header-text-color;
    font-size: 40px;
  }
}

.header--dashboard .menu-container--mobile {
  span {
    background: $background-color;
  }

  span.open {
    background: $background-color;
  }
}

.menu-container--mobile {
  display: block;
  position: fixed;
  top: 32px;
  right: 20px;
  z-index: 20;

  span {
    display: block;
    width: 33px;
    height: 3px;
    margin-bottom: 7px;
    position: relative;
    background: $brand-color;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 40px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /*
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  span.open {
    opacity: 1;
    transform: rotate(45deg) translate(5px, 4px);
    background: #232323;
  }

  /*
  * But let's hide the middle one.
  */
  span.open:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
  * Ohyeah and the last one should go the other direction
  */
  span.open:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, 16px);
  }

  /*
  * And let's slide it in from the left
  */
  input:checked~ul {
    //transform: none;
  }

  @include media('>=large') {
    display: none;
  }

  //General close button used on checkout etc
  .menu__close {
    position: relative;
    top: -18px;
  }

  a {
    display: inline-block;

    i.header-search-icon:before {
      font-size: calc-rem(30);
    }
  }

  .menu-icons {
    position: relative;
    top: 0;
    transition: all 0.5s;

    &.menu-icons-scroll-up {
      top: -150px;
      transition: top 0.5s ease-out;
    }
  }
}

/*
.header--dashboard .menu-mobile {
    background: $tertiary-text-colour;

    > .item {
        margin-bottom: 20px;

        a {
            color: $dashboard-link-colour;
        }
    }
}
*/

.mobile-menu-wrapper {
  transition: opacity 0.5s ease-out;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);

  &.open {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    opacity: 1;
  }
}

.menu-mobile {
  position: fixed;
  width: 80%; //260px;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 50;
  margin: 0;

  //margin: -85px 0 0 -340px;
  padding: 0 0 40px 0;
  text-align: left;
  background: $white;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow: hidden auto;

  &--open {
    transform: none;
  }

  &--padding {
    padding: 40px 0;
  }

  >.item {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &__divider {
      padding: 0 0 15px;
      margin: 0 20px 15px;
      border-bottom: 2px solid #dddddd;

      a {
        padding: 0 9px;
      }
    }

    a {
      @extend %mobile-menu-link;

      &.active {
        color: $indigo;
      }

      @include on-event {
        color: $indigo;
      }

      >i {
        position: absolute;
        right: $container-inside-padding;

        &.icon {
          left: 15px;
          // TODO: Need to display once the icon get fixed
          display: none;
        }
      }
    }

    &.account-item {
      a {
        position: absolute;
        top: 0;
        height: 85px;
        font-size: calc-rem(22);
        background-color: $dodger-blue;
        border-bottom: none;
        font-weight: 500;
        padding-left: 18px;
        margin: 0;

        &.active {
          color: $link-colour;
        }
      }
    }

    &.name-section {
      background-color: #f8f8f8;
      padding: $container-inside-padding;
      margin: 0 0 $container-inside-padding;

      a {
        height: auto;
      }

      .naming {
        width: 90px;
        height: 90px;
        background-color: transparent;
        border: 3px solid #707070;
        border-radius: 50%;
        font-size: calc-rem(35);
        color: #707070;
        letter-spacing: 1.17px;
        text-align: center;
        line-height: 90px;
        margin: 0 auto;
        text-transform: capitalize;
      }
    }
  }

  >.item--divider {
    border-bottom: 1px solid $border-color;
    padding-bottom: 28px;
  }

  >.item--buy a {
    text-transform: uppercase;
  }

  >.item--login a {
    text-transform: uppercase;
    color: $secondary-text-color;
  }
}

// Below CSS are for dropdown and search box of nav component
.dropdown-menu,
.search-box {
  &__container {
    position: relative;

    .menu-container--mobile & {
      display: inline-block;
      vertical-align: top;
      margin-right: 15px;

      @include media('<=tinysmall') {
        margin-right: 10px;
      }

      a {
        color: #ffffff;
      }
    }
  }
}

.search-box {
  &__form {
    position: relative;

    .autosuggest-container .react-autosuggest__container {
      margin-right: 0;
    }

    input {
      padding: 10px 30px 10px 15px;
      outline: none;
      width: 100%;
      border-radius: 5px;
      border: 1px solid $gainsboro;
    }
  }

  &__button {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    outline: none;

    i#search-box-icon {
      display: inline-block;
      color: $melrose;
    }
  }

  &__panel {
    position: fixed;
    background: transparent;
    width: 100vw;
    left: 0;
    top: 85px;
    text-align: left;
    transition: all 0.5s;

    &.sticky-search {
      top: 0;
      transition: all 0.5s ease-out;
    }

    &__container {
      max-width: 1140px;
      margin: auto;
      background: white;
      box-shadow: 0 6px 17px rgba(138, 138, 138, 0.5);
      padding: 15px;
      border-radius: 5px;
    }

    @include media('<=small') {
      top: 56px;
    }
  }
}

.dropdown-menu {
  &__panel {
    position: absolute;
    background: white;
    padding: 15px;
    border-radius: 5px;
    top: 57px;
    text-align: left;
    right: 0;
    transition: all 0.5s;

    @include media('<=small') {
      top: 45px;
      background: #EEEEEB;
      width: 100%;
      height: 100%;
      position: fixed;
    }

    @include media('>medium') {
      box-shadow: 0 6px 17px rgba(138, 138, 138, 0.5);
    }
  }

  &__content {
    width: 100%;

    hr {
      background-color: #A1A1A1;
    }
  }

  &__item {
    width: 100%;
    margin-bottom: 10px;

    a {
      color: $nav-link-color;
    }

    .test-user {
      margin-left: 10px;

      //Prevents really long names breaking onto 2 lines
      display: block;
      white-space: nowrap;
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;

      &--current {
        font-weight: bold;
        cursor: default; //dont want to look like link
      }
    }
  }
}

#dropdown-menu a {
  &:hover {
    font-weight: 500;
  }

  div {
    width: 100%;
  }
}
