#create-label-form > .form-group {
    margin-top: 10px;
}

#generate-label-btn {
    margin-top: 20px;
    margin-bottom: 30px;
}

#generate-more-labels-btn {
    margin-top: 30px;
    margin-bottom: 40px;
}
