.order-edit {
    &__top {
        max-width: 95%;
        margin: auto;
        position: relative;

        .arrow {
            margin-right: 10px;
        }
    }

    &__flash-message {
        text-align: center;
        //position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        //z-index: -10;

        &--success {
            color: $good-text-color;
        }
        &--fail {
            color: $bad-text-color;
        }
    }

    &__container {
        padding: 20px 29px 50px;
        max-width: 910px;
        margin: auto;

        .checkout__form-col.order-summary {
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
        }

        .checkout-form .btn.checkout__btn {
            width: 275px;
            max-width: 100%;
        }
    }
}
