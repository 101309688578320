.healthy-range {
    &__container {
        padding: $container-inside-padding 36px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 3px 15px 0 rgba(0,0,0,0.10);
        max-width: 852px;
        margin: 0 auto;

        &.no-shadow {
            box-shadow: none;
            padding: 0;
            border-radius: 0;
        }

        @include media ("<=medium") {
            padding: $container-inside-padding 22px;

            &.no-shadow {
                padding: 0;
            }
        }
    }

    &__ranges-graph {
        position: relative;
        padding: 0 0 $container-inside-padding;

        .reference-line {
            position: absolute;
            border-right: 2px dashed #D8D8D8;
            height: 27px;
            bottom: 30px;
            z-index: 1;
        }

        .reference-label {
            position: absolute;
            font-size: calc-rem(14);
            color: #A5A5A5;
            letter-spacing: 0.15px;
            text-align: center;
        }
    }

    &__legends {
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 12px $container-inside-padding;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: $container-inside-padding 0 0;

        &.epigenetic-age {
            justify-content: center;
            flex-direction: unset;

            margin: $container-padding 0 0;
            a {
                margin: 0 8px;
            }
        }
        
        &.comparison-legends {
            flex-direction: column;
            width: calc(50% - 20px);
            display: flex;
            text-align: left;
            justify-content: center;
            //height: 170px;
            margin: $container-inside-padding 0 50px 20px;

            .single-legend {
                display: block;
            }

            .legend-title {
                width: calc(100% - 100px);
            }

            @include media ("<=medium") {
                width: 100%;
                margin: $container-inside-padding 0;
                flex-direction: row;
                justify-content: flex-start;
                height: auto;

                .legend-title {
                    width: auto;
                }

                .single-legend {
                    margin: 20px 0 0;

                    &:first-child {
                        margin: 0;
                    }
                }
            }

            @include media ("<=small") {
                .legend-title {
                    width: calc(100% - 65px);
                }
            }
        }

        font-size: calc-rem(18);
        color: $text-color;
        text-align: center;
        line-height: calc-rem(28);

        .single-legend {
            display: inline-block;
            text-align: left;

            svg {
                width: 80px;
                height: 6px;

                &.you_past {
                    stroke: $dodger-blue;
                }

                &.you {
                    stroke: $dodger-blue;
                }

                &.super_centenarians {
                    stroke: #A577FF;
                }

                @include media ("<=small") {
                    width: 50px;
                }
            }
        }

        .legend-point {
            width: 85px;
            height: 10px;
            border-radius: 5px;
            background-color: $new-good-color;
            display: inline-block;
            vertical-align: middle;

            &.bad {
                background-color: $bad-text-color;
            }

            &.normal {
                background-color: $avg-text-color;
            }

            &.good {
                background-color: $good-text-color;
            }

            &.you {
                background-color: $dodger-blue;
            }

            &.super_centenarians {
                background-color: #A577FF;
            }

            @include media ("<=medium") {
                height: 6px;
            }

            @include media ("<=small") {
                width: 50px;
            }
        }

        .legend-title {
            font-size: calc-rem(16);
            line-height: calc-rem(26);
            display: inline-block;
            margin: 0 0 0 15px;
            vertical-align: middle;
        }

        @include media ("<=medium") {
            flex-direction: column;
            justify-content: center;
        }
    }
}