/**
 * Basic typography style for copy text
 */
body {
    color: $text-color;
    font-weight: 400;
    font: normal  112.5% / 1.65 $text-font-stack; //18px, 28 line height
    font-family: $text-font-stack;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
}

.bold {
    font-weight: 700;
}

.light {
    font-weight: 300;
}

.center {
    text-align: center;
}

.small {
    font-size: calc-rem(12);
}

h1,h2,h3,h4,h5 {
    font-family: $heading-font-stack;
    font-weight: 400;
    font-style: normal;
}

h1 {
    font-size: calc-rem(65);
    margin: 50px 0;
}

h2 {
    font-size: calc-rem(50);
    margin: 50px 0;
}

h3 {
    font-size: calc-rem(33);
    margin: $container-padding/2 0;
}

h4 {
    font-size: calc-rem(24);
    margin: $container-padding/2 0;
}

h5 {
    font-size: calc-rem(20);
    margin: $container-padding/2 0;
}

h6 {
    font-family: $text-font-stack;
    font-size: calc-rem(14);
    margin: $container-padding/4 0;
    font-weight: 400;
}