.chat {
    &__section {
        position: fixed;
        right: 50px;
        bottom: 75px;
        z-index: 1111;
    }

    &__chat-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;

        @include media ("<=medium") {
            display: none;
        }
    }

    &__message-container {
        width: 400px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 9px -2px $text-color;
        position: inherit;
        bottom: 150px;
        right: 50px;
        height: 60vh;
        overflow: scroll;

        @include media ("<=medium") {
            width: calc(100% - 26px);
            right: 13px;
            height: auto;
        }
    }

    &__message-container::-webkit-scrollbar{
        width: 2px;
    }
    

    &__message-header {
        padding: 20px $container-inside-padding;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        border-bottom: 2px solid #eaeaea;
        justify-content: space-between;

        @include media ("<=medium") {
            padding: 20px 16px;
        }

        .header-detail-section{
            display: flex;
            align-items: center;
        }

        .close-chat-icon{
            height: 20px;
            width: 20px;
            cursor: pointer;
            filter: brightness(1.5);
            
            
        }

        .close-chat-icon:hover{
            filter: drop-shadow(2px 2px 2px #444444) brightness(1.5);
        }

        .header-image-section {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 $mobile-container-padding 0 0;
            background-color: #eaeaea;

            img {
                max-width: 100%;
                background-color: $border-color;
            }
        }

        span {
            color: $white;
            font-size: calc-rem(20);
            font-weight: 500;
        }
    }

    &__message-section {
        padding: 20px 20px 0 20px;

        @include media ("<=medium") {
            padding: 20px 16px;
        }

        .submit-button {
            width: 100%;
            text-align: right;
        }

        .user-message {
            width: 80%;
            padding: 10px $mobile-container-padding;
            border-radius: 8px;
            font-size: calc-rem(18);
            margin: 0 0 $mobile-container-padding auto;
            box-shadow: 0 2px 9px 0px #d5d5d5;

            &.reply {
                margin: 0 auto $mobile-container-padding 0;
            }

            p {
                margin: 0;
            }
        }

        .no-shadow{
            box-shadow: none;
        }
    }

    &__textarea {
        font-size: calc-rem(16);
        color: #4e4e4e;

        .title{
            font-size: calc-rem(20);
        }

        textarea {
            margin: 15px 0;
            outline: none;
            width: 100%;
            padding: 10px;
            line-height: 1.2;
            resize: none;
            border-radius: 5px;
        }
    }
}