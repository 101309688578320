// -----------------------------------------------------------------------------
// This file contains styles for ShippingWarning component
// -----------------------------------------------------------------------------

.shipping-warning {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -32px;

    border: 1px solid $brand-pink-colour;

    &__icon {
        padding: 8px;
    }

    &__text {
        font-size: 12px;
        line-height: 15px;
        padding-top: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
        color: $brand-pink-colour;
    }
}