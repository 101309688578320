.question {
  color: $melrose;
  text-align: center;
  max-width: 450px;
  margin: $container-inside-padding auto 0;

  > label {
    display: inline-block;
    font-size: calc-rem(18);
    cursor: pointer;
    text-decoration: underline;
  }

  &--large {
    > label {
      font-size: calc-rem(24);
    }
  }

  i {
    margin-right: 5px;
  }

  .question-options {
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;

    &.active {
      max-height: 70px;
      transition: max-height 0.3s ease-in;
    }

    .form__select-wrapper {
      select {
        width: 100%;
        padding: 10px 15px;

        &:active,
        &:focus {
          outline: none;
        }
      }

      i {
        margin-right: 0;
        right: 10px;
        color: #444444;
      }
    }
  }

  > div {
    div {
      display: inline-block;
      margin-right: $container-inside-padding;

      &:last-child {
        margin-right: 0;
      }

      label {
        color: $dodger-blue;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
