// -----------------------------------------------------------------------------
// This file contains styles that are specific to the transposons page
// -----------------------------------------------------------------------------

%curved-box {
    box-shadow: 0 3px 15px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
    padding: 40px 50px;
    max-width: 910px;
    margin: 0 auto;

    @include media ("<=medium") {
        padding: $container-inside-padding 22px;
    }
}

.transposons {
    &__hero-section {
        max-width: 770px;
        padding: 0 $container-inside-padding;
        background-color: white;
        margin: 0 auto;
        text-align: center;

        .hero-description {
            margin: 60px auto 0;
            width: 100%;
            font-size: calc-rem(20);
            text-align: center;
            line-height: calc-rem(28);

            @include media ("<=tinysmall") {
                font-size: calc-rem(18);
                line-height: calc-rem(30);
                text-align: left;
                margin: 20px 0 0;
            }
        }
    }

    &__container {
        padding: 0 0 50px;
    }

    &__evolutionary-protector {
        @extend %curved-box;

        .summary-methylation-graph {
            padding: $container-inside-padding 0 $mobile-container-padding;
        }

        .indicator-section-title {
            text-align: center;
        }

        .image-container {
            max-width: 120px;
            max-height: 120px;
            margin: 0 auto;

            img {
                max-width: 120px;
                max-height: 120px;
                width: 100%;
            }
        }

        .summary-text {
            font-size: calc-rem(20);
            text-align: center;
            margin: 0;
        }
    }

    &__section-without-shadow {
        max-width: 910px;
        margin: 0 auto;

        .accordion-title {
            font-size: calc-rem(24);
            text-align: left;
            margin: 0;
            line-height: 1.4;
            font-weight: 500;
            color: $text-color;

            @include media ("<=medium") {
                font-size: calc-rem(22);
            }
        }
    }

    &__history-section {
        list-style-type: none;
        margin: 0;
        padding: 0 $container-inside-padding;

        @include media ("<=medium") {
            padding: 0 16px 0 43px;
        }
    }

    &__gauge-container {
        @extend %curved-box;
        margin: 40px auto 0;
        position: relative;

        .indicator-section-title {
            margin: 0;
        }
    }

    &__history-content {
        @extend %curved-box;
        margin: 40px auto 0;
        position: relative;

        .indicator-section-title {
            margin: 0;
        }

        .history-time {
            text-align: left;
            margin: 0 0 $mobile-container-padding;
            font-size: calc-rem(18);
            color: $gainsboro;
        }

        .methylation-level {
            &__title {
                text-align: left;
                line-height: 1;
                font-weight: 500;

                .first {
                    font-size: calc-rem(36);
                }
            }

            &__text {
                font-size: calc-rem(16);
                color: #A5A5A5;
                margin: 0;
            }
        }

        &:before {
            content: "";
            left: -29px;
            position: absolute;
            border-left: 3px solid #D5D5D5;
            top: 50px;
            height: calc(100% + 40px);
        }

        &:after {
            content: "";
            width: 11px;
            height: 11px;
            position: absolute;
            background-color: $dodger-blue;
            left: -33px;
            border-radius: 50%;
            top: 50px;
        }

        &:first-child {
            &.line-item {
                margin: 0 auto;
            }
        }

        &:last-child {
            &:before {
                display: none;
            }
        }

        @media (max-width: 1000px) {
            &:before {
                left: -23px;
            }

            &:after {
                left: -27px;
            }
        }

        @include media ("<=medium") {
            &:before, &:after {
                top: 39px;
            }
        }
    }

    &__accordion-section {
        @extend %curved-box;
        border: none;
        padding: 8px 50px $container-inside-padding;
        margin: $container-inside-padding 0 0;

        .description {
            width: 100%;
            text-align: center;
        }

        i {
            margin: 0;
        }

        .accordion-children {
            text-align: center;
            margin: $container-inside-padding 0 0;

            img {
                max-width: 108px;
                max-height: 108px;
                width: 100%;
            }

            p {
                font-size: calc-rem(14);
                text-align: center;
                line-height: 30px;
                margin: 0;
                color: $text-color;
            }
        }

        &:first-child {
            margin: 0;
        }

        @include media ("<=medium") {
            padding: 8px 22px $container-inside-padding;
        }
    }

    &__gauge-chart {
        width: 100%;
        height: 200px;
        margin: 20px 0 $container-inside-padding;
    }

    &__title-information {
        @extend .result-hero__title-information;
        font-size: calc-rem(20);
        text-align: center;
        line-height: calc-rem(26);

        @include media ("<=tinysmall") {
            font-size: calc-rem(18);
            line-height: calc-rem(30);
        }
    }

    &__tracker-accordion {
        @extend %curved-box;
        border: none;
        padding: 0;
        margin: $container-inside-padding 0 0;

        .description {
            width: 100%;
            padding: 0 50px;
            color: $text-color;

            .section-description {
                font-size: calc-rem(18);
                line-height: calc-rem(30);
                margin: 18px 0 0;
            }
        }

        header {
            padding: 0 50px;
            background-color: #F2F2F2;
            border-radius: 10px 10px 0 0;

            .title {
                font-size: calc-rem(18);
                font-weight: 500;
            }

            i {
                margin: 0;
            }
        }

        .accordion-children {
            margin: $mobile-container-padding 0 0;
        }

        .top-tip {
            border-radius: 10px;
            background-color: #F8F8F8;
            font-size: calc-rem(18);
            color: #444444;
            text-align: center;
            line-height: 26px;
            margin: $container-inside-padding 0;
            padding: $mobile-container-padding 50px;

            .tip-title {
                text-transform: uppercase;
                font-weight: 500;
                margin: 0;
            }

            .tip-description {
                margin: 0;
            }
        }

        @include media ("<=medium") {
            header, .description {
                padding: 0 22px;
            }

            .top-tip {
                padding: $mobile-container-padding 22px;
            }
        }

        @include media ("<=small") {
            header i {
                margin: 0;
            }
        }

        &:first-child {
            margin: 0;
        }
    }
}

.methylation-graph {
    &__container {
        width: 100%;
        padding: $mobile-container-padding 0 0;
        position: relative;
        text-align: left;
    }

    &__tooltip-text {
        font-size: calc-rem(14);
        color: $text-color;
        text-align: center;
        font-weight: 500;
    }

    &__title {
        span {
            font-size: calc-rem(36);
            font-weight: 500;

            &:last-child {
                font-size: calc-rem(16);
            }
        }
    }

    &__point {
        position: absolute;

        .tooltip-child {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: transparent;
        }
    }

    &__bars {
        display: flex;
        margin: 40px 0 0;
        position: relative;

        .result-arrow {
            border: 5px solid transparent;
            border-top: 9px solid black;
            position: absolute;
            top: -10px;
        }
    }

    &__bar {
        height: 6px;
        border-radius: 5px;
        display: inline-block;
        vertical-align: top;
        background-color: $new-good-color;

        &.evolutionary {
            background-color: $new-good-color;
        }

        &.protector {
            background-color: $new-bad-color;
        }

        &.bad {
            background-color: $bad-text-color;
        }

        &.normal {
            background-color: $avg-text-color;
        }

        &.good {
            background-color: $good-text-color;
        }

        &.gradient {
            background-image: linear-gradient(-90deg, $bad-text-color 0%, $good-text-color 100%);
        }

        &.invert {
            background-image: linear-gradient(-90deg, $good-text-color 0%, $bad-text-color 100%);
        }

        &.inactive {
            opacity: 0.2;
        }
    }

    &__labels {
        margin: 5px 0 0;
        display: flex;
        justify-content: space-between;
        p {
            margin: 0;
            font-size: calc-rem(14);
        }
    }

    &__result-point {
        height: 6px;
        width: 6px;
        position: absolute;
        border-radius: 50%;
        background-color: $white;
    }
}
